import { Component, OnInit } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { AdvertiseService } from 'src/app/services/advertise.service';

@Component({
  selector: 'app-domestic-ad',
  templateUrl: './domestic-ad.component.html',
  styleUrls: ['./domestic-ad.component.scss']
})
export class DomesticAdComponent implements OnInit {

  ads: any;
  show: boolean = false;
  responsiveOptions: any;
  constructor(private advertiseService: AdvertiseService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];


    Carousel.prototype.onTouchMove = () => { };

  }

  ngOnInit(): void {

    if (localStorage.getItem('adDomestic')) {
      this.ads = localStorage.getItem('adDomestic');
      this.ads = JSON.parse(this.ads);
      if (this.ads != '') {
        this.show = true;
      }
    }

    this.advertiseService.getAdDomestic().subscribe((data: any) => {
      this.ads = data.Data;
      if (this.ads != '') {
        this.show = true;
      }
      localStorage.setItem('adDomestic', JSON.stringify(data.Data));
    });
  }

}
