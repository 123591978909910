import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-image-section',
  templateUrl: './image-section.component.html',
  styleUrls: ['./image-section.component.scss']
})
export class ImageSectionComponent implements OnInit {

  images: any;
  studentImages: any;
  id: any;
  collegeImage: any;
  collegeStudentImage: any;

  constructor(private route: ActivatedRoute, private mediaService: MediaService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });


    this.mediaService.getCollegeImages(this.id).subscribe((data: any) => {
      this.images = data.Data;
      this.collegeImage = this.images.length;
    });

    this.mediaService.getStudentImages(this.id).subscribe((data: any) => {
      this.studentImages = data.Data;
      this.collegeStudentImage = this.studentImages.length;

    });

  }
}
