import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-domestic-banner',
  templateUrl: './domestic-banner.component.html',
  styleUrls: ['./domestic-banner.component.scss']
})
export class DomesticBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
