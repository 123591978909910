import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdmissionService } from '../services/admission.service';
import { ExamService } from '../services/exam.service';

declare var $: any;

@Component({
  selector: 'app-scholarship',
  templateUrl: './scholarship.component.html',
  styleUrls: ['./scholarship.component.scss']
})
export class ScholarshipComponent implements OnInit {

  scholarships: any;
  applyForm: any;
  submitted: boolean = false;
  error: any;
  show: boolean = true;
  apply: any;
  scholarId: any;

  constructor(private fb: FormBuilder, private admissionService: AdmissionService, private examService: ExamService, private toastr: ToastrService,) { }

  ngOnInit(): void {

    if (localStorage.getItem('scholarships')) {
      this.scholarships = localStorage.getItem('scholarships');
      this.scholarships = JSON.parse(this.scholarships);
    }

    this.examService.getScholarships().subscribe((data: any) => {
      this.scholarships = data.Data;
      localStorage.setItem('scholarships', JSON.stringify(data.Data));
    });
    this.buildForm();
  }

  showSuccess() {
    this.toastr.show('Applied Successfully!');
  }

  onClose() {
    this.applyForm.reset();
    this.submitted = false;
    $("#applyNow").modal("hide");
  }

  passId(id: any) {
    this.scholarId = id;
  }

  onSubmit() {
    this.submitted = true;
    this.apply = this.applyForm.value;
    this.show = false;
    this.admissionService.applyScholar(this.apply, this.scholarId).subscribe(
      (error: any) => this.handleError(error),
      (data: any) => data
    );

    setTimeout(
      () => {

        if (this.error == 'Success!') {
          this.show = true;
          this.showSuccess();
          $("#applyNow").modal("hide");
          this.applyForm.reset();
        }
      }, 3000);

  }

  handleError(error: any) {
    this.show = true;
    this.error = error.message;
  }

  buildForm() {
    this.applyForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }


}
