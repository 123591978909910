import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends ApiService {
  userData: any;
  img: any;
  constructor(private http: HttpClient) {
    super()
  }


  editAccount(edit: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/updateUser";
    const body = {
      name: edit.name,
      state: edit.state.id,
      country: edit.country,
      city: edit.city,
      mobile: edit.mobile,
      address: edit.address,
      image: edit.image

    }

    const headers = this.getHeaders({});


    return this.http.post(url, body, { headers: headers });

  }



  sendUser(user: any) {
    this.userData = user;
  }

  addImage(formData: any) {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/updateUser";

    const headers = this.getHeaders({});
    return this.http.post(url, formData, { headers });


  }

  refer(x: any) {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/updateUser";
    const body = {
      referel_id: x
    }
    const headers = this.getHeaders({});
    return this.http.post(url, body, { headers });


  }

}
