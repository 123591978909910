import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from './app.config';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, delay, map, startWith } from 'rxjs/operators';


const CACHE_KEY = 'dp';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  collegeData: any;
  courseData: any;
  image: any;
  image1: any;
  public notificationURL = 'http://localhost:3000/subscribe';
  eve: any;

  constructor(private http: HttpClient) {

  }

  private subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  private add = new Subject<any>();
  sendAdd() {
    this.add.next();
  }
  getAdd(): Observable<any> {
    return this.add.asObservable();
  }

  private pwaHome = new Subject<any>();
  sendPwaHome() {
    this.pwaHome.next();
  }
  getPwaHome(): Observable<any> {
    return this.pwaHome.asObservable();
  }

  private pwaHome1 = new Subject<any>();
  sendPwaHome1() {
    this.pwaHome1.next();
  }
  getPwaHome1(): Observable<any> {
    return this.pwaHome1.asObservable();
  }

  private pwaHome2 = new Subject<any>();
  sendPwaHome2() {
    this.pwaHome2.next();
  }
  getPwaHome2(): Observable<any> {
    return this.pwaHome2.asObservable();
  }

  upload(e: Event) {
    this.eve = e;
  }

  addCollege(data: any) {
    this.collegeData = data;
  }

  addCourse(data: any) {
    this.courseData = data;
  }

  addImage(data: any) {
    this.image = data;
  }

  addImage1(data: any) {
    this.image1 = data;
  }

  postSubscription(sub: PushSubscription) {
    return this.http.post(this.notificationURL, sub).pipe(catchError(this.handlError));
  }
  handlError(error: any) {
    return throwError(error.error.message);
  }

}
