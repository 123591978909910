import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'app-find-college-city',
  templateUrl: './find-college-city.component.html',
  styleUrls: ['./find-college-city.component.scss']
})
export class FindCollegeCityComponent implements OnInit {
  id: any;
  colleges: any;
  cities: any;
  city: any;
  loading: boolean = true;
  name: any;
  show: boolean = false;
  active: any;
  cities1: any;

  constructor(private route: ActivatedRoute, private cityService: CityService) {
    route.params.subscribe(val => {

      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');

      });

      this.cityService.getAllCity().subscribe((data: any) => {
        this.cities = data;
        this.cities1 = data;
        this.find(this.id);
      });


      this.cityService.getAllCollegesCity(this.id).subscribe((data: any) => {
        this.colleges = data;
        this.loading = false;
        this.city = null;
        this.city = this.colleges[0].city
      });
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');

    });

    this.cityService.getAllCity().subscribe((data: any) => {
      this.cities = data;
      this.cities1 = data;
      this.find(this.id);
    });


    this.cityService.getAllCollegesCity(this.id).subscribe((data: any) => {
      this.colleges = data;
      this.loading = false;
      this.city = null;
      this.city = this.colleges[0].city
    });

  }

  change() {
    this.name = ""
    this.show = false;
    this.city = "";
    this.loading = true;
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  search() {
    if (this.name == "") {
      this.cities = this.cities1;
      this.show = false;
    }
    else {
      this.show = true;
      this.cities = this.cities1;
      this.cities = this.cities.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase());
      })
    }
  }

  find(id: any) {
    for (let i = 0; i < this.cities.length; i++) {
      if (this.cities[i].id == id) {
        this.active = this.cities[i].description;
        break;
      }
      else {
        continue;
      }

    }
  }

}
