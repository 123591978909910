import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-achievements',
  templateUrl: './about-achievements.component.html',
  styleUrls: ['./about-achievements.component.css']
})
export class AboutAchievementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
