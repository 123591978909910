import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scholarship-banner',
  templateUrl: './scholarship-banner.component.html',
  styleUrls: ['./scholarship-banner.component.scss']
})
export class ScholarshipBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
