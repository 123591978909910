import { Component, OnInit } from '@angular/core';
import * as Aos from 'aos';
import { AppService } from 'src/app/app.service';
import { CoursesService } from 'src/app/services/courses.service';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  colleges: any;
  searchInput: any;
  name: any;
  show: boolean = false;
  active: boolean = false;
  states: any = [];
  collegesLocal: any;
  colleges1: any;
  dis: boolean = true;
  option: any;
  selectedOption: any;
  coursesLocal: any;
  catCourses: any;
  catCourses1: any;



  constructor(private featuredCollegeService: FeaturedCollegeService, private courseService: CoursesService) {
    this.option = [
      { name: 'Search by Institutions', value: 1 },
      { name: 'Search by Courses', value: 2 }
    ];

  }
  ngOnInit(): void {

    if (localStorage.getItem('colleges')) {
      this.collegesLocal = localStorage.getItem('colleges');
      this.colleges = JSON.parse(this.collegesLocal);
      this.colleges1 = JSON.parse(this.collegesLocal);
    }

    this.featuredCollegeService.getAllCollege().subscribe((data) => {
      this.colleges = data;
      this.colleges1 = data;
      localStorage.setItem('colleges', JSON.stringify(data));

    });

    if (localStorage.getItem('courses')) {
      this.coursesLocal = localStorage.getItem('courses');
      this.catCourses = JSON.parse(this.coursesLocal);

      this.catCourses1 = JSON.parse(this.coursesLocal);
    }

    this.courseService.getCourseCategory().subscribe((data: any) => {
      this.catCourses = data;
      localStorage.setItem('courses', JSON.stringify(data));
      this.catCourses1 = data;
    });


  }

  search1() {
    this.name = '';
    if (this.selectedOption.value == 2) {
      this.dis = false;
    }
    else if (this.selectedOption.value == 1) {
      this.dis = true;
    }
  }

  onClose() {
    this.show = false;
    this.name = "";
    this.active = false;
  }

  search() {
    if (this.name == "") {
      this.show = false;
      this.active = false;
      this.colleges = this.colleges1;
    }
    else {
      this.colleges = this.colleges1;
      this.show = true;
      this.active = false;
      this.colleges = this.colleges.filter((res: { name: string; state: any; city: string; place: string; }) => {
        return (res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase()) ||
          /* res.city.toLocaleLowerCase().match(this.name.toLocaleLowerCase()) || */
          /*  res.state.toLocaleLowerCase().match(this.name.toLocaleLowerCase()) || */
          res.place.toLocaleLowerCase().match(this.name.toLocaleLowerCase())
        );
      });

      if (this.colleges == '') {
        this.active = true;
      }
    }

  }

  searchCourse() {
    if (this.name == "") {
      this.show = false;
      this.active = false;
      this.catCourses = this.catCourses1;
    }
    else {
      this.catCourses = this.catCourses1;
      this.show = true;
      this.active = false;
      this.catCourses = this.catCourses.filter((res: { name: string; }) => {
        return (res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase())
        );
      });

      if (this.catCourses == '') {
        this.active = true;
      }
    }
  }

}
