import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.css']
})
export class AccountEditComponent implements OnInit {


  editUser: any;
  editForm: any;
  user: any;
  states: any;

  constructor(private fb: FormBuilder, private router: Router, private featuredCollegeService: FeaturedCollegeService, private loginService: LoginService, private toastr: ToastrService, private accountService: AccountService) { }

  ngOnInit(): void {

    if (localStorage.getItem('myProfile')) {
      this.editUser = localStorage.getItem('myProfile');
      this.editUser = JSON.parse(this.editUser);

    }

    this.loginService.getUser().subscribe((data: any) => {
      this.editUser = data;
      localStorage.setItem('myProfile', JSON.stringify(data));
    });
    this.featuredCollegeService.getStates().subscribe((data: any) => {
      this.states = data.Data;

    });
    this.buildForm();

  }


  edit() {
    this.user = this.editForm.value;
    this.accountService.editAccount(this.user).subscribe((data: any) => {
      this.router.navigateByUrl('/account');
    });

  }

  buildForm() {
    this.editForm = this.fb.group({
      name: ['', Validators.required],
      city: ['', Validators.required],
      mobile: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      address: ['', Validators.required]
    });
  }


  handleErrorLogin(error: any) {
    console.log("err", error)
  }


}
