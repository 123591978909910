<section class="browseBy-city-section">
  <div  class="container">
      <h1 data-aos="fade-up"  class="main-heading text-center">Browse Institutions By Top City</h1>

      <div class="crse">
        <p-carousel [value]="cities" styleClass="custom-carousel" [numVisible]="4" [numScroll]="4" [circular]="true" [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">

          <ng-template let-city pTemplate="item">
            <div class="col-12 " >
              <a [routerLink]="['/findinstitutions-city', city.id]">
                <div class="iconWrapper">
                    <img src="{{city.icon}}" alt="city_image">
                </div>
                <p>{{city.name|titlecase}}</p>
            </a>
          </div>
          </ng-template>
      </p-carousel>
    </div>

      <ul class="crse1" id="city" data-aos="fade-up">
        <li *ngFor="let city of cities">
          <a [routerLink]="['/findinstitutions-city', city.id]">
              <div class="iconWrapper">
                  <img src="{{city.icon}}" alt="city_image">
              </div>
              <p>{{city.name|titlecase}}</p>
          </a>
      </li>
      </ul>

  </div>
</section>
