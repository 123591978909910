import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CityService } from 'src/app/services/city.service';
import { CoursesService } from 'src/app/services/courses.service';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';
@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent implements OnInit {

  colleges: any;
  field: any;
  searchColleges: any;
  searchColleges1: any;
  totalRecords: any;
  stateSearch: any;
  citySearch: any;
  courseSearch: any;
  name: boolean = true;
  show: boolean = false;
  state: boolean = false;
  city: boolean = false;
  course: boolean = false;
  categoryid: Array<string> = [];
  courseid: any = [];
  stateBox: any;
  states: any;
  states1: any;
  cities: any;
  cities1: any;
  types: any;
  catCourses: any;
  catCourses1: any;
  selectedState: any = [];
  selectedCity: any = [];
  selectedCourse: any = [];
  selectedType: any = [];
  selectedCategory: any = [];
  selectedCollege: any = [];
  private subscriptions: Subscription[] = [];
  pid: any;
  x: any;
  active: boolean = false;
  activeCourse: boolean = false;
  activeCity: boolean = false;
  activeState: boolean = false;
  activeName: boolean = false;
  collegesLocal: any;
  coursesLocal: any;

  constructor(
    private featuredCollegeService: FeaturedCollegeService,
    private cityService: CityService,
    private courseService: CoursesService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.field = '';
    this.stateSearch = '';
    this.citySearch = '';
    this.courseSearch = '';
    this.active = false;
    this.activeName = false;
    this.activeCourse = false;
    this.activeCity = false;
    this.activeState = false;
    this.selectedState = [];
    this.selectedCity = [];
    this.selectedCourse = [];
    this.selectedCategory = [];
    this.selectedCollege = [];

    if (localStorage.getItem('colleges')) {
      this.collegesLocal = localStorage.getItem('colleges');
      this.colleges = JSON.parse(this.collegesLocal);
      this.spinner.hide();
      this.totalRecords = this.colleges.length;
      this.searchColleges = this.colleges;
      this.searchColleges1 = JSON.parse(this.collegesLocal);
    }

    const loadSubscriptionCollege = this.featuredCollegeService.getAllCollege().subscribe((data) => {
      this.colleges = data;
      this.searchColleges = this.colleges;
      this.spinner.hide();
      localStorage.setItem('colleges', JSON.stringify(data));
      this.totalRecords = this.colleges.length;
      this.searchColleges1 = data;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(loadSubscriptionCollege);

    if (localStorage.getItem('courses')) {
      this.coursesLocal = localStorage.getItem('courses');
      this.catCourses = JSON.parse(this.coursesLocal);
      this.spinner.hide();
      this.catCourses1 = JSON.parse(this.coursesLocal);
    }


    const loadSubscriptionCourse = this.courseService.getCourseCategory().subscribe((data: any) => {
      this.catCourses = data;
      this.spinner.hide();
      localStorage.setItem('courses', JSON.stringify(data));
      this.catCourses1 = data;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(loadSubscriptionCourse);


    if (localStorage.getItem('types')) {
      this.types = localStorage.getItem('types');
      this.types = JSON.parse(this.types);
    }

    const loadSubscription = this.courseService.getAllType().subscribe((data: any) => {
      this.types = data;
      this.cdr.detectChanges();
      localStorage.setItem('types', JSON.stringify(data));
    });
    this.subscriptions.push(loadSubscription);

    if (localStorage.getItem('states')) {
      this.states = localStorage.getItem('states');
      this.states = JSON.parse(this.states);
      this.states1 = localStorage.getItem('states');
      this.states1 = JSON.parse(this.states1);
    }

    const loadSubscriptionState = this.featuredCollegeService.getStates().subscribe((data: any) => {
      this.states = data;
      this.states1 = data;
      localStorage.setItem('states', JSON.stringify(data));
      this.cdr.detectChanges();
    });
    this.subscriptions.push(loadSubscriptionState);

    if (localStorage.getItem('city1')) {
      this.cities = localStorage.getItem('city1');
      this.cities = JSON.parse(this.cities);
      this.cities1 = localStorage.getItem('city1');
      this.cities1 = JSON.parse(this.cities1);
    }

    const loadSubscriptionCity = this.cityService.getAllCity().subscribe((data: any) => {
      this.cities = data;
      this.cities1 = data;
      localStorage.setItem('city1', JSON.stringify(data));
      this.cdr.detectChanges();
    });
    this.subscriptions.push(loadSubscriptionCity);

  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  scroll() {
    this.x = document.getElementById('target');
    this.x.scrollIntoView();
  }

  scroll1() {
    this.x = document.getElementById('type');
    this.x.scrollIntoView();
  }

  searchType(id: any) {
    if (this.pid == id) {
      this.pid = '';
      this.active = false;
      this.colleges = this.searchColleges1;

    }
    else {
      this.pid = id;
      this.active = false;
      this.courseService.getCollegeTypeSearch(id, null).subscribe((data: any) => {
        this.colleges = data;
        if (this.colleges == '') {
          this.active = true;
        }
      });
    }
  }

  filter() {
    this.categoryid = [];
    this.courseid = [];
    for (let i = 0; i < this.selectedCourse.length; i++) {
      this.categoryid[i] = this.selectedCourse[i].category_id;
      this.courseid[i] = this.selectedCourse[i].course_id;
    }
    this.active = false;
    this.featuredCollegeService.search(this.categoryid, this.courseid, this.selectedCollege, this.selectedState, this.selectedCity, 2).subscribe((data: any) => {
      this.colleges = data.Data;
      if (this.colleges == '') {
        this.active = true;
      }
    });
  }


  search() {
    if (this.field == "") {
      this.searchColleges = this.searchColleges1;
    }
    else {
      this.activeName = false;
      this.searchColleges = this.searchColleges1;
      this.searchColleges = this.searchColleges.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.field.toLocaleLowerCase());
      })
    }
    if (this.searchColleges == '') {
      this.activeName = true;
    }
  }

  searchStateList() {
    if (this.stateSearch == "") {
      this.states = this.states1;
    }
    else {
      this.activeState = false;
      this.states = this.states1;
      this.states = this.states.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.stateSearch.toLocaleLowerCase());
      })
    }
    if (this.states == '') {
      this.activeState = true;
    }
  }

  searchCityList() {
    if (this.citySearch == "") {
      this.cities = this.cities1;
    }
    else {
      this.cities = this.cities1;
      this.activeCity = false;
      this.cities = this.cities.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.citySearch.toLocaleLowerCase());
      })
    }
    if (this.cities == '') {
      this.activeCity = true;
    }
  }

  searchCourseList() {
    if (this.courseSearch == "") {
      this.catCourses = this.catCourses1;
    }
    else {
      this.activeCourse = false;
      this.catCourses = this.catCourses1;
      this.catCourses = this.catCourses.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.courseSearch.toLocaleLowerCase());
      })
    }
    if (this.catCourses == '') {
      this.activeCourse = true;
    }
  }
}
