import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})
export class AdvertiseService {
  constructor(private http: HttpClient) {

  }

  getAdTop() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAdvertisement/top";
    return this.http.get(url);
  }

  getAdDomestic() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAdvertisement/domestic";
    return this.http.get(url);
  }

  getAdInternational() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAdvertisement/international";
    return this.http.get(url);
  }

  getAdInstitution() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAdvertisement/institution";
    return this.http.get(url);
  }

  getAdBottom() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAdvertisement/bottom";
    return this.http.get(url);
  }

  subscribe(mail: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAdvertisement/bottom";

    const body = {
      email: mail
    }
    return this.http.post(url, body);
  }
}
