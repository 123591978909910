<section class="earnPage-section">
  <div class="container">
      <h1>Earn through MyZeed</h1>
      <div class="row">
          <div class="col-lg-6 order-1 order-lg-0">
           <p class="text-justify"> Doesn't matter if you are a student or not, we have an excellent opportunity for you to make some extra cash. Make the optimum use of it. </p>
           <h3 *ngIf="referal" style="font-size: 20px;">Your Referral Code : <span style="font-weight: bold;
            font-size: 22px;">{{referal}}</span></h3>
           <h3>The Process</h3>
           <ol>
             <li>For every customer, a usercode will be generated. </li>
             <li>Once the customer shares this link with a prospective student, looking forward to study abroad, a code will be shared to that student. </li>
             <li>When that student enters the given code, he/she will be in reference to the customer. </li>
             <li>And, if the student takes admission via MyZeed, the customer will be rewarded (this will be handled manually).</li>

           </ol>
          </div>
          <div class="col-lg-6 order-0 order-lg-1">
            <!-- <div *ngIf="referal" class="share dropdown">
              <div class="innerWrapper">
                  <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="nav-link account-link"><i
                          class="fas fa-share-alt"></i><span>Share</span></a>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <ul>
                          <li><a href="whatsapp://send?text=Share this referral code with your family and friends" data-action="share/whatsapp/share" target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                      class="fab fa-whatsapp"></i></a></li>
                          <li><a href="http://twitter.com/share?text=Share this referral code {{referal}} with your family and friends" target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                      class="fab fa-twitter"></i></a></li>
                                      <li><a href="http://www.facebook.com/share.php?u=myzeed.com" target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                        class="fab fa-facebook-f"></i></a></li>
                          <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=myzeed.com&title=Referral code for myzeed&source=myzeed.com" target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                      class="fab fa-linkedin-in"></i></a></li>
                      </ul>
                  </div>
              </div>
          </div> -->
              <div class="img-wrapper text-center">
                  <img class="img-fluid" src="assets/images/earn.jpg" alt="">
              </div>
          </div>
      </div>
  </div>
</section>
