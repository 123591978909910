import { Component, OnInit } from '@angular/core';
import * as Aos from 'aos';
import { FeaturedCollegeService } from '../../services/featuredCollege.service';

@Component({
  selector: 'app-featured-college',
  templateUrl: './featured-college.component.html',
  styleUrls: ['./featured-college.component.scss']
})
export class FeaturedCollegeComponent implements OnInit {

  colleges: any;
  college: any;
  responsiveOptions: any;

  constructor(private featuredCollegeService: FeaturedCollegeService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {

    if (localStorage.getItem('featured_home_college')) {
      this.colleges = localStorage.getItem('featured_home_college');
      this.colleges = JSON.parse(this.colleges);
    }
    this.featuredCollegeService.getCollege().subscribe((data: any) => {
      this.colleges = data.data.featured_colleges;
      localStorage.setItem('featured_home_college', JSON.stringify(data.data.featured_colleges));
    });

    /*   var a = 0;
      $(window).on('scroll', function () {
        var oTop1 = $('#featured').offset()!.top - window.innerHeight;
        if (a == 0 && $(window).scrollTop()! > oTop1) {
          Aos.init();

        }
      }); */
  }


  view(college: { id: any; }) {
    this.featuredCollegeService.addCollege(college);
    this.college = college;
  }




}
