import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { TokenService } from 'src/app/services/token.service';


@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss']
})
export class AccountSidebarComponent implements OnInit {

  filedata: any;
  user: any;
  form!: FormGroup;
  img: any;

  constructor(private fb: FormBuilder, private toastr: ToastrService, private appService: AppService, private auth: AuthService, private accountService: AccountService, private loginService: LoginService, private token: TokenService, private router: Router) {
  }


  ngOnInit(): void {
    if (localStorage.getItem('myProfile')) {
      this.user = localStorage.getItem('myProfile');
      this.user = JSON.parse(this.user);
      this.img = this.user.image;
      this.appService.addImage1(this.img);
    }

    this.loginService.getUser().subscribe((data: any) => {
      this.user = data;
      this.img = this.user.image;
      this.appService.addImage1(this.img);
      localStorage.setItem('myProfile', JSON.stringify(data));


    });

    this.form = this.fb.group({
      profile_pic: [null]

    })
  }

  upload(e: any) {
    this.filedata = e.target.files[0];
    this.form.patchValue({
      profile_pic: this.filedata
    });
    this.form.get('profile_pic')?.updateValueAndValidity();

    let formdata = new FormData();
    formdata.append("profile_pic", this.form.get('profile_pic')?.value);
    this.accountService.addImage(formdata).subscribe((data: any) => {
      this.user = data.Data;
      this.img = this.user.image;
      this.appService.addImage1(this.img);
    });




  }

  showLogout() {
    this.toastr.show('Signed Out! See you soon');
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe(
      error => error,
      data => data
    );
    this.token.remove();
    localStorage.removeItem('myProfile');
    this.showLogout();
    this.auth.changeAuthStatus(false);
    this.router.navigateByUrl('/home');
  }




}
