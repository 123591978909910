import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-earn-page',
  templateUrl: './earn-page.component.html',
  styleUrls: ['./earn-page.component.scss']
})
export class EarnPageComponent implements OnInit {

  user: any;
  referal: any;
  loggedIn!: boolean;

  constructor(
    private loginService: LoginService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.auth.authStatus.subscribe(value => this.loggedIn = value);

    if (this.loggedIn) {

      if (localStorage.getItem('myProfile')) {
        this.user = localStorage.getItem('myProfile');
        this.user = JSON.parse(this.user);
        this.referal = this.user.referel_id;
      }

      this.loginService.getUser().subscribe((data: any) => {
        this.user = data;
        this.referal = this.user.referel_id;
        localStorage.setItem('myProfile', JSON.stringify(data));


      });

    }
  }

}
