<section class="india-fastest-section">
  <!-- <img class="lady" src="assets/images/bg-1.jpg" alt=""> -->
  <div [hidden]="!show" class="india-fastest-wrapper">
      <div class="swiper-container indiasFastest-swiper-container">
          <div class="swiper-wrapper">


            <p-carousel [value]="ads"  [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">

              <ng-template let-ad pTemplate="item">
                  <div class=" p-mb-6">
                          <a *ngIf="ad.collegeid != null && ad.external_url == null" target="_blank" [routerLink]="['/about-institutions' , ad?.collegeid ]">
                            <div class="imgWrapper">
                                <img class="img-fluid" src="{{ad.url}}" alt="advertisement">
                            </div>
                        </a>

                        <a *ngIf="ad.external_url != null && ad.collegeid == null" target="_blank" rel="noopener" href="{{ad.external_url}}">
                          <div class="imgWrapper">
                              <img class="img-fluid" src="{{ad.url}}" alt="advertisement">
                          </div>
                      </a>

                  </div>
              </ng-template>
          </p-carousel>



          </div>

      </div>
  </div>

</section>





