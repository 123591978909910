<app-college-header></app-college-header>



<section class="inner-link">
    <div class="container">
        <div class="linkWrapper">

            <ul>
              <li ><a [routerLink]="['/about-institutions' , id ]">About</a></li>
              <li ><a [routerLink]="['/admission' , id ]">Admission</a></li>
              <!-- <li ><a routerLink="/placement">Placement</a></li>
              <li ><a routerLink="/rate-review">Rate & Review</a></li> -->
              <li><a [routerLink]="['/images' , id ]">Gallery</a></li>
              <li class="active"><a [routerLink]="['/videos' , id ]">Video</a></li>
            </ul>
        </div>
    </div>
</section>
