import { Component, OnInit } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { AdvertiseService } from 'src/app/services/advertise.service';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  ads: any;
  show: boolean = false;
  responsiveOptions: any;
  constructor(private advertiseService: AdvertiseService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];


    Carousel.prototype.onTouchMove = () => { };

  }

  ngOnInit(): void {


    if (localStorage.getItem('adBottomHome')) {
      this.ads = localStorage.getItem('adBottomHome');
      this.ads = JSON.parse(this.ads);
      if (this.ads != '') {
        this.show = true;
      }
    }

    this.advertiseService.getAdBottom().subscribe((data: any) => {
      this.ads = data.Data;
      if (this.ads != '') {
        this.show = true;
      }
      localStorage.setItem('adBottomHome', JSON.stringify(data.Data));

    });
  }

}
