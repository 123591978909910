<app-account-banner></app-account-banner>

<div class="accountPage">
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#0cb100" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > WE ARE LOGGING YOU IN ! </p></ngx-spinner>
    <div class="container accoun-container">
        <app-account-sidebar></app-account-sidebar>
        <div class="main-content">
            <h1>Account</h1>
            <div class="sideBar-menu">
                <span></span>
                    <span></span>
                    <span ></span>
            </div>
            <div class="acc-box">
                <div class="acc-list">
                    <div class="label-row">
                        <div class="imgWrapper">
                            <img src="assets/images/user-2.png" alt="">
                        </div>

                        <span>Name</span>
                    </div>
                    <p>{{user?.name}}</p>
                </div>
                <div *ngIf="user?.address" class="acc-list">
                    <div class="label-row">
                        <div class="imgWrapper">
                            <img src="assets/images/adrs.png" alt="">
                        </div>

                        <span>Address</span>
                    </div>
                    <p>{{user?.address}}</p>
                </div>
                <div class="acc-list">
                    <div class="label-row">
                        <div class="imgWrapper">
                            <img src="assets/images/mail-1.png" alt="">
                        </div>

                        <span>E-mail</span>
                    </div>
                    <p>{{user?.email}}</p>
                </div>
                <div class="acc-list">
                    <div class="label-row">
                        <div class="imgWrapper">
                            <img src="assets/images/phone-1.png" alt="">
                        </div>

                        <span>Phone</span>
                    </div>
                    <p>{{user?.mobile}}</p>
                </div>
                <div *ngIf="user?.city" class="acc-list">
                    <div class="label-row">
                        <div class="imgWrapper">
                            <img src="assets/images/adrs.png" alt="">
                        </div>

                        <span>City</span>
                    </div>
                    <p>{{user?.city}}</p>
                </div>
                <div  *ngIf="user?.state" class="acc-list">
                    <div class="label-row">
                        <div class="imgWrapper">
                            <img src="assets/images/adrs.png" alt="">
                        </div>

                        <span>State</span>
                    </div>
                    <p>{{user?.state}}</p>
                </div>
                <!-- <div class="change-password">
                  <a routerLink="/change-password">Change Password</a>
              </div> -->
           <a class="edit" (click)="edit()" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit Profile" routerLink="/editAccount"><img src="assets/images/edit.png" alt=""></a>
            </div>
        </div>
    </div>

</div>


<app-footer></app-footer>
