<section class="find-job-section">
  <img data-aos="fade-up" class="find-jobImg" src="assets/images/find-job.png" alt="">
  <div class="container">
      <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
              <div class="find-job">
                  <h1 id="job" data-aos="fade-up"  class=" main-heading text-left">Find-Jobs</h1>
                  <p data-aos="fade-up" >Not only we help you sow your seed, but grow as well. MyZeed aids you to enter the next level of your            career by helping you in finding and easily applying for jobs in your preferred location. Get notifications on  job opportunities available in different countries, the country’s government and various job updates in just a click. We strive to give you better than the best.  </p>
                  <a *ngIf="loggedIn" routerLink="/job">Find Job</a>
                  <a *ngIf="!loggedIn" [routerLink]="['/login' , 'job' ]">Find Job</a>
              <!--     <a [routerLink]="'/about'" [fragment]="'login'">Find Job</a> -->
              </div>
          </div>
      </div>
  </div>
</section>



