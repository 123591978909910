
<app-banner></app-banner>
<app-explore></app-explore>
<div *ngIf="showButton" class="card-container pwaPhone"><div class="image-container"><img src="assets/images/phoneinhandBlue.png" width="48" height="72"></div><div class="text-container"><p class="card-heading">Enjoy App-Like Experience</p><p class="card-text">Get our Tiny 0.4MB Web-App. It's smaller than a Selfie! <span class="add"><div class="ripple-container "><button (click)="addToHomeScreen()" class="btn primary flex center card-button" id="">ADD TO HOMESCREEN</button><div class="ripple"></div></div></span></p></div></div>
<app-india-fastest></app-india-fastest>
<app-learn-earn></app-learn-earn>
<app-find-job></app-find-job>
<app-achievements></app-achievements>
<app-featured-college></app-featured-college>
<app-top-cources></app-top-cources>
<app-browse-category></app-browse-category>
<app-advertisement></app-advertisement>
<app-who-we-are></app-who-we-are>
<app-browse-city></app-browse-city>
<app-news-updates></app-news-updates>
<app-request-appointment></app-request-appointment>
<app-footer></app-footer>

