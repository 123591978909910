<section class="placement-section faq-section">
  <div class="container">
      <div class="accordian-wrapper">
          <div class="card active">
              <div class="card-header">
                  <h3>Q: Why studying abroad has so much appeal among students?
                  </h3>

              </div>
              <div class="card-body">
                  <p>Studying abroad helps us to gain self-reliance, ameliorate our language skills, learn with latest curriculum, high quality of education and meet miscellaneous range of people.
                  </p>
              </div>
          </div>
          <div class="card">
              <div class="card-header">
                  <h3>Q: Can I study abroad with scholarship?</h3>

              </div>
              <div class="card-body">
                  <p>
Yes...
                  </p>
              </div>
          </div>
          <div class="card">
              <div class="card-header">
                  <h3>Q:  Which countries provide free education?</h3>

              </div>
              <div class="card-body">
                  <p>All Scandinavian countries like Germany, Sweden etc. provide free education. As these countries provide free education, their selection process is also very rigorous.
                  </p>
              </div>
          </div>
          <div class="card">
              <div class="card-header">
                  <h3>Q: What is ITR?
                  </h3>

              </div>
              <div class="card-body">
                  <p>
Income tax return.
                  </p>
              </div>
          </div>
          <div class="card">
              <div class="card-header">
                  <h3>Q: What are the documents required for visa?
                  </h3>

              </div>
              <div class="card-body">
                  <p>A valid passport (original), recent passport size colour photograph, proof of residence, proof of profession and proof of financial soundness.
                  </p>
              </div>
          </div>
          <div class="card">
              <div class="card-header">
                  <h3>Q: How can I get an appointment with Myzeed?</h3>

              </div>
              <div class="card-body">
                  <p>You can click on "request appointment" and can select the options that you need whether it is institute information, scholarship, NRI quota or other.
                  </p>
              </div>
          </div>
          <div class="card">
            <div class="card-header">
                <h3>Q: How would Myzeed helps me to study abroad?</h3>

            </div>
            <div class="card-body">
                <p> We provide foremost counselling for scholars to find their future and also, we support you through the visa process to airport pick-up service.
                </p>
            </div>
        </div>
        <div class="card">
          <div class="card-header">
              <h3>Q: What is Myzeed site?</h3>

          </div>
          <div class="card-body">
              <p>Myzeed.com is a website which helps a scholar to find their pathway easier and to nourish themselves into a strong plant.
              </p>
          </div>
      </div>
      <div class="card">
        <div class="card-header">
            <h3>Q: What are the services Myzeed provides?</h3>

        </div>
        <div class="card-body">
            <p> We offer our full support and help to students who wish to go for overseas and domestic studies, provide domestic and overseas job notifications, various exam notifications and complacency customer services.
            </p>
        </div>
    </div>
    <div class="card">
      <div class="card-header">
          <h3>Q: How can Myzeed help with admission?</h3>

      </div>
      <div class="card-body">
          <p> Our website allows you to search for your options, select the best institute, confirm your admission.
          </p>
      </div>
  </div>
  <div class="card">
    <div class="card-header">
        <h3>Q: Can I earn from your site? How?</h3>

    </div>
    <div class="card-body">
        <p> Yes, you can. When you subscribe to our site, a user code will be generated. By sharing our link with your friends who are study-abroad aspirants, this code will be shared to them. Once they enter with the code, you will be their reference point. And when they achieve their dreams via my zeed you will be rewarded.
        </p>
    </div>
</div>
<div class="card">
  <div class="card-header">
      <h3>Q: Is this site easy to handle?</h3>

  </div>
  <div class="card-body">
      <p> Yes. In case you need help, support is available. </p>
  </div>
</div>
      </div>
  </div>
</section>
