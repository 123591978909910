<div class="side-bar">
  <div class="dpWrapper">
      <div class="circle">
          <!-- User Profile Image -->
          <img *ngIf="!user?.image" class="profile-pic" src="assets/images/dp1.jpg">
          <img *ngIf="user?.image" class="profile-pic" src="{{user?.image}}">

          <!-- Default Image -->
          <!-- <i class="fa fa-user fa-5x"></i> -->
      </div>
      <div class="p-image">
          <i class="fa fa-camera upload-button"></i>
          <form [formGroup]="form">
            <div class="form-group">
          <input class="file-upload" name="profile_pic" type="file" accept="image/*"  (change)="upload($event)"/>
        </div>

        </form>
      </div>
  </div>
  <h2 class="name text-center">{{user?.name}}</h2>
  <!-- <p class="regNo text-center">Register No:5</p> -->
  <ul>
      <li class="active"><a routerLink="/account"> <div class="icon"><img class="grey" src="assets/images/account-grey.png" alt=""><img class="green"
          src="assets/images/account-green.png" alt=""></div><span>Account</span></a></li>
      <li><a routerLink="/refernearn"><div class="icon"><img class="grey" src="assets/images/refer-earn-grey.png" alt=""><img class="green"
          src="assets/images/refer-earn-green.png" alt=""></div><span>Refer & Earn</span></a></li>
      <li><a routerLink="/course"><div class="icon"><img class="grey" src="assets/images/open-book-grey.png" alt=""><img class="green"
          src="assets/images/open-book-green.png" alt=""></div><span>Course</span></a></li>
      <li><a  href="#" (click)="logout($event)"><div class="icon"><img class="grey" src="assets/images/logout-1-grey.png" alt=""><img class="green"
          src="assets/images/logout-1-green.png" alt=""></div><span>Logout</span></a></li>
  </ul>

</div>
