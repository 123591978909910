<section class="whoWeAre-section">
  <div class="container">
      <div class="whoWeAre-wrapper">
          <h1  data-aos="fade-up"  class="main-heading text-center">Who We Are & What We Do</h1>
          <div data-aos="fade-up"  class="row">
              <div class="col-md-6">
                <p>MyZeed - Plant your future and we'll help it grow, organically. Thus, strengthening your career roots and guiding you to grow in the right direction and attain success in your future career endeavors.
                  An extensive search engine for students, parents, and institutions to well- equip oneself with information related to higher education sectors in India and Overseas. MyZeed, has the repository of 20,000 plus colleges and 6000 courses, well categorized in different streams like Management, Engineering, Medical, Arts, and much more.
                </p>
              </div>
              <div class="col-md-6">
                  <ul>
                    <li> Search for your options.</li>
                    <li> Select the best Institute.</li>
                    <li> Find Jobs that best suits you.</li>
                    <li> Apply for the desired course and receive discounts.</li>
                    <li> Confirm your admission.</li>
                    <li> And, Earn while you learn.</li>
                  </ul>
              </div>
          </div>
          <!-- <h3 class="text-center">Subscribe newsletter & get<br>
              our latest updates</h3>
          <form class="subribe-wrapper" action="">
              <img src="assets/images/mail.png" alt="">
              <input type="text" name="mail" [(ngModel)]="mail" placeholder="hello@gmail.com">
              <button (click)="send()" class="subcribeBtn">Subscribe</button>
          </form> -->
      </div>
  </div>
</section>
