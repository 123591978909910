<section class="admission-section">
    <div class="container">
        <div *ngIf="colleges?.admission_provide != ''" class="row">
            <div class="col-lg-8">
                <div class="admission-wrapper">
                    <h1 class="secondaryHeading">{{colleges?.admission_provide}}</h1>
                    <p>{{colleges?.content_a}}</p>
                    <p>{{colleges?.content_b}}</p>
                </div>
            </div>
          <!--   <div class="col-lg-4">
                <div class="imgWrapper">
                    <img class="img-fluid" src="assets/images/discount-1.png" alt="">
                </div>
            </div> -->
        </div>




        <div class="courseSelect">
            <h1 class="secondaryHeading"> {{colleges?.college_name}}  Courses</h1>

                <div class="swiper-container courseSelect-swiper-container">
                  <div class="swiper-wrapper">
                      <div *ngFor="let course of courses" class="swiper-slide ">
                          <div class="course"
                              aria-selected="true">
                              <div class="iconWraper">
                                  <img class="greenIcon" src="{{course.logo}}" alt="">
                                  <img class="whiteIcon" src="{{course.logo}}" alt="">
                              </div>
                              <p style=" text-align: center;">{{course.course_name}}</p>
                          </div>
                      </div>

                  </div>
              </div>

<div id="target">
  <p-table #dt2 [value]="courses"
      [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
      [paginator]="true" (onPage)="scroll()" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} courses"
      [globalFilterFields]="['course_name']">
      <ng-template pTemplate="caption">
          <div class="p-d-flex ">
              <span class="p-input-icon-right table-search">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" class="place" (input)="dt2.filterGlobal($event.target.value, 'contains')" placeholder="Search Course" />
              </span>
          </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
              <th>Courses</th>
              <th>Seats</th>
              <th>Curriculum</th>
          </tr>

      </ng-template>
      <ng-template pTemplate="body" let-course>
        <tr>
          <td>{{course.course_name}}</td>
          <td> {{course.seats}}</td>
          <td><a href="{{course.curriculum_link}}" target="_blank" rel="noopener">View Curriculum</a></td>
      </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="5">Oops! No Course found.</td>
          </tr>
      </ng-template>
  </p-table>
</div>

        </div>

         <a class="applyNow" data-toggle="modal" data-target="#applyNow">Apply Now</a>

        <div class="contactTo-college">
            <p>IF YOU HAVE ANY QUERY ABOUT THE COLLEGE/ COURSES FEEL FREE TO CONTACT US</p>
            <a routerLink="/contact">CONTACT US</a>
        </div>

    </div>
</section>

<!-- apply now modal -->
<div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
<p-progressSpinner class="spinner" [hidden]="show" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

            <div class="modal-body">
                <button type="button" (click)="onClose()" class="close"  aria-label="Close">
                    <img src="assets/images/close.png" alt="">
                </button>

                <h1 class="text-center">Apply Now</h1>
                <form  [formGroup]="applyForm">
                <div class="formWrapper">
                    <div class="form-group">
                        <img src="assets/images/user-2.png" alt="">
                        <input type="text" formControlName="name"  class="form-control" placeholder="Enter the Name" required>

                        <small class="text-danger" *ngIf="error?.name && submitted">{{error.name}}</small>



                    </div>
                    <div class="form-group">
                        <img src="assets/images/phone-1.png" alt="">
                        <input type="number" formControlName="phone"  class="form-control" placeholder="Phone Number" required>
                        <small class="text-danger" *ngIf="error?.phone && submitted">{{error.phone}}</small>

                    </div>
                    <div class="form-group">
                        <img src="assets/images/mail-1.png" alt="">
                        <input type="email" formControlName="email"  class="form-control" placeholder="Email Address" required>
           <small class="text-danger" *ngIf="error?.email && submitted">{{error.email}}</small>


                    </div>
                    <div class="form-group">

                        <p-dropdown
                        formControlName="stream"
                        [options]="newCategory"
                        placeholder="Select a Category"
                        optionLabel="category_name" [filter]="true" filterBy="category_name"
                        [style]="{ width: '70%' }"
              [autofocusFilter]="false"

                        required

                      ></p-dropdown>
                      <br>

           <small class="text-danger" *ngIf="error?.stream && submitted">{{error.stream}}</small>


                    </div>


                    <div class="form-group">

                    <p-dropdown
                    formControlName="program"
                    [options]="courseApply"
                    placeholder="Select a Course"
                    optionLabel="course_name" [filter]="true" filterBy="course_name"
                    [style]="{ width: '70%' }"
              [autofocusFilter]="false"

                    required

                  ></p-dropdown>
                      <br>
           <small class="text-danger" *ngIf="error?.program && submitted">{{error.program}}</small>


                    </div>
                    <button class="main-btn" (click)="onSubmit()" type="submit" data-toggle="modal"
                     >Apply</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>


