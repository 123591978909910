import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'app-latest-job',
  templateUrl: './latest-job.component.html',
  styleUrls: ['./latest-job.component.scss']
})
export class LatestJobComponent implements OnInit {

  jobs: any;
  jobsMobile: any;
  value: any;
  loading: boolean = true;
  positions = [
    {
      position: "full time", "value1": 1
    },
    {
      position: "part time", "value1": 2

    }
  ];
  x: any;


  constructor(private jobsService: JobsService) { }

  ngOnInit(): void {

    if (localStorage.getItem('jobWeb')) {
      this.jobs = localStorage.getItem('jobWeb');
      this.jobs = JSON.parse(this.jobs);
      this.loading = false;
    }
    this.jobsService.getJobs().subscribe((data: any) => {
      this.jobs = data.Data;
      this.loading = false;
      localStorage.setItem('jobWeb', JSON.stringify(data.Data));

    });

    if (localStorage.getItem('jobMobile')) {
      this.jobsMobile = localStorage.getItem('jobMobile');
      this.jobsMobile = JSON.parse(this.jobsMobile);
      this.loading = false;
    }

    this.jobsService.getJobsMobile().subscribe((data: any) => {
      this.jobsMobile = data.Data;
      this.loading = false;
      localStorage.setItem('jobMobile', JSON.stringify(data.Data));
    });
  }

  scroll() {
    this.x = document.getElementById('target');
    this.x.scrollIntoView();
  }

  scroll1() {
    this.x = document.getElementById('type');
    this.x.scrollIntoView();
  }


}
