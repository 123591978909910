import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { CategoryService } from '../../services/category.service'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],

})
export class HomepageComponent implements OnInit {


  categorys: any;
  deferredPrompt: any;
  showButton: boolean = false;

  constructor(private categoryService: CategoryService, private appService: AppService) {
    setTimeout(
      () => {
        this.deferredPrompt = this.appService.eve;
      }, 3500);
  }

  ngOnInit() {
    this.categoryService.getCategory().subscribe((data) => {
      this.categorys = data;
    });

    this.appService.getPwaHome().subscribe(() => {
      this.showButton = true;
    });

    this.appService.getPwaHome1().subscribe(() => {
      this.showButton = false;
    });

    if (this.appService.eve) {
      this.showButton = true;
    }
  }




  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    this.deferredPrompt = this.appService.eve;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          this.appService.sendPwaHome2();
        }
        this.deferredPrompt = '';
      });
  }


}
