<section class="inner-link">
  <div class="container">
      <div class="linkWrapper">

          <ul>
              <li class="active"><a [routerLink]="['/about-institutions' , id ]">About</a></li>
              <li ><a [routerLink]="['/admission' , id ]">Admission</a></li>
           <!--   <li><a [routerLink]="['/placement' , id ]">Placement</a></li>
               <li><a routerLink="/rate-review">Rate & Review</a></li>-->
              <li><a [routerLink]="['/images' , id ]">Gallery</a></li>
              <li><a [routerLink]="['/videos' , id ]">Video</a></li>

          </ul>
      </div>
  </div>
</section>
