<section class="banner-section">
  <img src="assets/images/bannerMain1.jpg" alt="myzeed">
</section>


<section class="search-college-section">
  <div  class="container">
      <h1 class="animate__animated animate__fadeInDown">Find Colleges & Courses<br>
          That Are Best For You</h1>
      <ul>
          <li>1000 + Colleges</li>
          <li>5000 + Courses</li>
          <li>20 + Countries</li>
      </ul>
      <form action="">
          <div *ngIf="dis" class="search-wrapper">
              <input type="text" name="name" [(ngModel)]="name"  (ngModelChange)="search()" autocomplete="off" placeholder="Search Institutions">
              <img class="searchIcon" src="assets/images/search.png" alt="">
                 <a style="cursor: pointer;" (click)="onClose()"> <img class="close1" src="assets/images/close.png"   alt=""></a>
          </div>

          <div *ngIf="!dis" class="search-wrapper">
            <input type="text" name="name" [(ngModel)]="name"  (ngModelChange)="searchCourse()" autocomplete="off" placeholder="Search Courses">
            <img class="searchIcon" src="assets/images/search.png" alt="">
               <a style="cursor: pointer;" (click)="onClose()"> <img class="close1" src="assets/images/close.png"   alt=""></a>
        </div>
          <p-dropdown [options]="option" name="selectedOption" [(ngModel)]="selectedOption" (ngModelChange)="search1()" optionLabel="name"></p-dropdown>

           <ul *ngIf="show && dis" class="search-result result">
              <li [hidden]="" *ngFor="let college of colleges"><a [routerLink]="['/about-institutions' , college.id ]"><img src="{{college.logo}}" alt=""><span>{{college.name}}</span></a></li>
              <div class="empty" [hidden]="!active">
              <h6 style="color: black;">Oops... No Institutions Found</h6>
            <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
            </div>
          </ul>

          <div *ngIf="show && !dis" class="search-result result">
          <cdk-virtual-scroll-viewport itemSize="40" class="example-viewport">
            <div *cdkVirtualFor="let catCourse of catCourses" >
              <div class="list">
              <a [routerLink]="['/findinstitutions-course' , catCourse.course_id ]">
                <span>{{catCourse.name}}</span>
              </a>
            </div>
              </div>
              <div class="empty" [hidden]="!active">
                <h6 style="color: black;">Oops... No Courses Found</h6>
              <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
              </div>
          </cdk-virtual-scroll-viewport>

        </div>


        </form>
  </div>
</section>
