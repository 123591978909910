<div class="notificationWrapper top-xamWrapper">
  <div class="container">
      <ul >
          <li *ngFor="let exam of exams">
              <div class="date">
                  <h3 class="text-center">{{exam.date_of_post | date: 'MMM'}}</h3>
                  <h2 class="text-center">{{exam.date_of_post | date: 'd'}}</h2>
              </div>
              <div class="detail">
                <p-tag *ngIf="exam.day_status == 1" class="new" value="New"></p-tag>

                <h3>{{exam.exam_name}}</h3>
                  <p>{{exam.description}}</p>
                      <div class="date-download">
                          <p><img src="assets/images/calendar.png" alt=""><span>{{exam.exam_date | date:'dd/MM/YYYY' }}</span></p>
                          <a class="readmore" href="{{exam.article}}" target="_blank" rel="noopener" >Know More</a>
                      </div>
              </div>
          </li>

      </ul>
  </div>
</div>
