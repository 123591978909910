import { Component } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { PrimeNGConfig } from 'primeng/api';
import { AppService } from './app.service';
import { AuthService } from './services/auth.service';
import { CoursesService } from './services/courses.service';
import { LoginService } from './services/login.service';
import { ConnectionService } from 'ng-connection-service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { OnesignalService } from './onesignal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'myzeed';
  status = 'ONLINE';
  isConnected = true;
  colleges: any;
  courses: any;
  loggedIn!: boolean;
  user: any;
  img: any;
  update: boolean = false;
  show: boolean = true;
  show1: boolean = true;
  url: any;

  constructor(private primengConfig: PrimeNGConfig, public router: Router, private courseService: CoursesService,
    private swPush: SwPush,
    private os: OnesignalService,
    private connectionService: ConnectionService,
    private updates: SwUpdate, private auth: AuthService, private loginService: LoginService, private appService: AppService) {



    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        this.show = true;
        this.router.navigateByUrl('/home');
        localStorage.setItem('connection', JSON.stringify(this.status));
      }
      else {
        this.status = "OFFLINE";
        this.router.navigateByUrl('/offline');
        this.show = false;
        localStorage.setItem('connection', JSON.stringify(this.status));
      }
    });
    localStorage.setItem('connection', JSON.stringify(this.status));


    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects;
      }
    });


  }

  ngOnInit() {
    this.os.onInit();

    this.updates.available.subscribe(event => {
      window.location.reload();
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    this.updates.activated.subscribe(event => {
      window.location.reload();
      console.log('Activation old version was', event.previous);
      console.log('Activation new version is', event.current);
    });

    if (this.updates.isEnabled) {

      this.updates.available.subscribe(() => {

        if (confirm("New version available. Load New Version?")) {

          window.location.reload();
        }
      });
    }

    this.auth.authStatus.subscribe(value => this.loggedIn = value);
    if (this.loggedIn == true) {
      this.loginService.getUser().subscribe((data: any) => {
        this.user = data;
        this.img = this.user.image;
        this.appService.addImage(this.img);
      });
    }
    /* this.subscribeToNotifications(); */

    this.primengConfig.ripple = true;
  }
}


