import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css']
})
export class ReferComponent implements OnInit {
  user: any;
  referal: any;
  displayBasic2!: boolean;

  constructor(
    private loginService: LoginService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('myProfile')) {
      this.user = localStorage.getItem('myProfile');
      this.user = JSON.parse(this.user);
      this.referal = this.user.referel_id;
    }

    this.loginService.getUser().subscribe((data: any) => {
      this.user = data;
      localStorage.setItem('myProfile', JSON.stringify(data));
      this.referal = this.user.referel_id;

    });
  }

  showBasicDialog2() {
    this.displayBasic2 = true;
  }

  refer() {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 6; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    var x = result.join('');

    this.referal = x;
    this.accountService.refer(x).subscribe();

  }

}
