<section class="education-section newsUpdatePage-section">
  <div class="container">
      <!-- <div class="advanceFilterWrapper">

          <img class="advanceFilter" src="assets/images/filter.png" alt="">
      </div> -->

      <div class="">

          <div class="col-lg-12 big-col">
              <ul class="newsUpdate-detail">
                  <li *ngFor="let blog of blogs">
                      <div class="date">
                          <h3 class="text-center">{{blog.date_of_post | date: 'MMM'}}</h3>
                          <h2 class="text-center">{{blog.date_of_post | date: 'dd'}}</h2>
                      </div>
                      <div class="detail">
                       <!--  <div class="imgWrapper">
                          <img src="{{blog.image}}" alt="">


                      </div> -->
                   <!--  <p-tag class="new" value="New"></p-tag> -->


                          <div class="detailWrapper">
                              <h3 class="head">{{blog.title}}</h3>
                              <p class="para">{{blog.description}}</p>
                                  <ul>
                                      <li><a>{{blog.date_of_post | date: 'MMM dd yyyy'}}</a></li>
                             <li class="readmore"><a [routerLink]="['/blog-detail' , blog.slug ]"> Read More </a></li>

                                  </ul>
                          </div>
                      </div>
                  </li>

              </ul>
          </div>
      </div>

  </div>
</section>
