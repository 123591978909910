import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/services/appointment.service';

@Component({
  selector: 'app-contactpage',
  templateUrl: './contactpage.component.html',
  styleUrls: ['./contactpage.component.scss']
})
export class ContactpageComponent implements OnInit {
  messageForm: any;
  message: any;
  error: any;
  show: boolean = true;
  displayBasic!: boolean;


  constructor(private fb: FormBuilder, private toastr: ToastrService, private appointmentService: AppointmentService) { }

  ngOnInit(): void {

    this.buildForm();

  }

  buildForm() {
    this.messageForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSend() {
    this.message = this.messageForm.value;
    this.show = false;
    this.appointmentService.contact(this.message).subscribe(
      error => this.handleError(error),
      data => data
    );

    setTimeout(
      () => {
        if (this.error == 'success') {
          this.show = true;
          this.showSuccess();
          this.displayBasic = true;
          this.messageForm.reset();
        }
      }, 3000);

  }

  showSuccess() {
    this.toastr.success('Sent Successfully!');
  }

  handleError(error: any) {
    this.show = true;
    this.error = error.message;
  }

}
