<section class="learn-earn-section">
  <div class="container-fluid ">
      <div class="row">
          <div class="col-md-6 pl-md-0">
              <div class="learn-earn">
                  <p>Refer & Earn</p>
                  <a routerLink="/earn">Read more</a>
              </div>
          </div>
          <div class="col-md-6  pr-md-0">
              <div class="what-we-do">
                  <p>What we do</p>
                  <a routerLink="/about">Read more</a>
              </div>
          </div>
      </div>
  </div>
  </section>
