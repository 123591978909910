<section class="appointment-section">
  <div class="container">
      <h2>Appointment</h2>

<p-progressSpinner class="spinner" [hidden]="show1" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>



      <form   [formGroup]="appointmentForm" >

      <div class="row">


          <div class="col-lg-6">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="name" placeholder="Enter the Name" required>
                <img class="black" src="assets/images/user-2.png" alt="">
                <img class="green" src="assets/images/user.png" alt="">
                <small class="text-danger" *ngIf="error?.name">{{error.name}}</small>
              </div>
              <div class="form-group">
                <input type="number" formControlName="phone"  class="form-control" placeholder="Phone Number" pattern="[0-9]{10}" required>
                <img class="black" src="assets/images/phone-1.png" alt="">
                <img class="green" src="assets/images/phone-1-green.png" alt="">
              <small class="text-danger" *ngIf="error?.phone">{{error.phone}}</small>
              </div>
              <div class="form-group">
                <input type="email" formControlName="email" class="form-control" placeholder="Email Address" required>
                <img class="black" src="assets/images/mail-1.png" alt="">
                <img class="green" src="assets/images/mail-1-green.png" alt="">
 <small class="text-danger" *ngIf="error?.email">{{error.email}}</small>
            </div>
              <div class="form-group">
                <textarea formControlName="message" class="form-control" placeholder="Messages Here" required></textarea>
                <img class="black" src="assets/images/mail-2.png" alt="">
                <img class="green" src="assets/images/mail-2-green.png" alt="">
 <small class="text-danger" *ngIf="error?.message">{{error.message}}</small>
              </div>
          </div>
          <div class="col-lg-6">
              <div class="select-below">
                  <h3>Select the below options that you need</h3>
                  <div class="p-field-checkbox">
                    <p-checkbox value="NRI Quota" formControlName="option" inputId="nq"></p-checkbox>
                    <label for="nq">NRI Quota</label>
                </div>
                <div class="p-field-checkbox">
                    <p-checkbox formControlName="option" value="Scholarship" inputId="ss"></p-checkbox>
                    <label for="ss">Scholarship</label>
                </div>
                <div class="p-field-checkbox">
                    <p-checkbox formControlName="option" value="Institute Information" inputId="ii"></p-checkbox>
                    <label for="ii">Institute Information</label>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox formControlName="option" value="Other" inputId="ot"></p-checkbox>
                  <label for="ot">Other</label>
              </div>
              </div>
 <small class="text-danger" *ngIf="error?.option">{{error.option}}</small>

          </div>

          <div class="col-12">
            <div class="standard">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" (click)="show()" formControlName="tnc" class="custom-control-input" id="tnc" >
                    <label class="custom-control-label" for="tnc"> <span>By confirming, I allow your executive to visit my home</span><br><a style="color:#212529; font-size: 15px;" routerLink="/terms-condition"> Terms & Conditions</a>  </label>
                </div>
            </div>
        </div>
      </div>
      <div class="col-12 text-right">
        <button *ngIf="tnc" type="submit"  (click)="onSubmit()" class="main-btn">Get Appointment</button>
        </div>

        </form>

<p-dialog header="MyZeed.com" [modal]="true" [blockScroll]="true" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <h4 style="margin-left: 178px;">Our executive will contact you shortly !</h4>
    <ng-template pTemplate="footer">
        <p-button (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>






  </div>
</section>
