import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from 'src/app/models/course.model';
import { AboutService } from 'src/app/services/about.service';
import { SelectItem, FilterService, FilterMatchMode } from 'primeng/api';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';
import { Enquiry } from 'src/app/models/enquiry.model';
import { ToastrService } from 'ngx-toastr';
import { AdmissionService } from 'src/app/services/admission.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Category } from 'src/app/models/category.model';

declare var $: any;

@Component({
  selector: 'app-college-details',
  templateUrl: './college-details.component.html',
  styleUrls: ['./college-details.component.scss']
})
export class CollegeDetailsComponent implements OnInit {
  colleges: any;
  courses: any;
  error: any;
  applyForm: any;
  categoryApply: Category[] = [];
  courseApply: any;
  facilities: any;
  id: any;
  show: boolean = true;
  loading: boolean = true;
  matchModeOptions!: SelectItem[];
  user: any;
  apply!: Enquiry;
  submitted: boolean = false;
  newCategory: String[] = [];

  constructor(private fb: FormBuilder, private aboutService: AboutService, private admissionService: AdmissionService, private toastr: ToastrService, private featuredCollegeService: FeaturedCollegeService, private route: ActivatedRoute, private filterService: FilterService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.buildForm();


    this.aboutService.getCollegebyId(this.id).subscribe((data: any) => {
      this.colleges = data.data;
      this.courses = data.data.course;
      this.facilities = data.data.fecility;
    });

    this.featuredCollegeService.getDropdown(this.id).subscribe((data: any) => {
      this.categoryApply = data.data.category;
      this.courseApply = data.data.courses;
      this.sample();

    });

    const customFilterName = 'custom-equals';

    this.filterService.register(customFilterName, (value: any, filter: any): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString() === filter.toString();
    });

    this.matchModeOptions = [
      { label: 'Custom Equals', value: customFilterName },
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ];

  }

  showSuccess() {
    this.toastr.show('Applied Successfully!');
  }

  onClose() {
    this.applyForm.reset();
    this.submitted = false;
    $("#applyNow").modal("hide");
  }

  sample() {

    let uniqueObject: any = {};

    for (let i in this.categoryApply) {
      const objName = this.categoryApply[i]['category_name'];

      uniqueObject[objName] = this.categoryApply[i];
    }

    for (let i in uniqueObject) {
      this.newCategory.push(uniqueObject[i]);
    }
  }



  onSubmit() {
    this.submitted = true;
    this.apply = this.applyForm.value;
    this.show = false;
    this.admissionService.applyPost(this.apply, this.id).subscribe(
      error => this.handleError(error),
      data => data
    );

    setTimeout(
      () => {

        if (this.error == 'Success!') {
          this.show = true;
          this.showSuccess();
          $("#applyNow").modal("hide");
          this.applyForm.reset();
        }
      }, 3000);

  }

  handleError(error: any) {
    this.show = true;
    this.error = error.message;
  }

  buildForm() {
    this.applyForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      stream: ['', Validators.required],
      program: ['', Validators.required]
    });
  }

}
