import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdmissionService } from 'src/app/services/admission.service';

@Component({
  selector: 'app-about-college-banner',
  templateUrl: './about-college-banner.component.html',
  styleUrls: ['./about-college-banner.component.scss']
})
export class AboutCollegeBannerComponent implements OnInit {

  id: any;
  colleges: any;

  constructor(private route: ActivatedRoute, private admissionService: AdmissionService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });


    this.admissionService.getCollegeadmissionbyId(this.id).subscribe((data: any) => {
      this.colleges = data.data;
    });
  }
}
