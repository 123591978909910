import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import * as Aos from 'aos';

declare var job: () => void;

@Component({
  selector: 'app-find-job',
  templateUrl: './find-job.component.html',
  styleUrls: ['./find-job.component.css']
})
export class FindJobComponent implements OnInit {
  loggedIn!: boolean;

  constructor(private auth: AuthService, private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {
    var a = 0;
    $(window).on('scroll', function () {
      var oTop1 = $('#job').offset()!.top - window.innerHeight;
      if (a == 0 && $(window).scrollTop()! > oTop1) {
        Aos.init();

      }
    });
    this.auth.authStatus.subscribe(value => this.loggedIn = value);

  }

  login() {
    this.loginService.provoke();
  }

}
