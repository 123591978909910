<section class="ads-section">
  <div [hidden]="!show" class="container">
      <div class="adsWrapper">
          <div class="swiper-container adsWrapper-container">
              <div class="swiper-wrapper">


                <p-carousel [value]="ads"  [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">

                  <ng-template let-ad pTemplate="item">
                    <div *ngIf="ad.collegeid" class=" p-mb-6">

                      <a target="_blank" [routerLink]="['/about-institutions' , ad?.collegeid ]">
                        <div class="imgWrapper">
                            <img class="img-fluid" src="{{ad.url}}" alt="advertisement">
                        </div>
                    </a>

                    </div>

                    <div *ngIf="ad.external_url" class=" p-mb-6">

                      <a href="{{ad.external_url}}" target="_blank" rel="noopener">
                        <div class="imgWrapper">
                            <img class="img-fluid" src="{{ad.url}}" alt="advertisement">
                        </div>
                    </a>

                    </div>
                  </ng-template>
              </p-carousel>

              </div>

          </div>
  </div>
  </div>
</section>
