import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../services/courses.service';
import { Course } from '../../models/course.model';
import * as Aos from 'aos';



@Component({
  selector: 'app-top-cources',
  templateUrl: './top-cources.component.html',
  styleUrls: ['./top-cources.component.scss']
})
export class TopCourcesComponent implements OnInit {

  courses: any;
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];

  constructor(private courseService: CoursesService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {

    if (localStorage.getItem('coursesHome')) {
      this.courses = localStorage.getItem('coursesHome');
      this.courses = JSON.parse(this.courses);
    }

    this.courseService.getCourses().subscribe((data: any) => {
      this.courses = data.data.courses;
      localStorage.setItem('coursesHome', JSON.stringify(data.data.courses));

    });

    /*  var a = 0;
     $(window).on('scroll', function () {
       var oTop1 = $('#course').offset()!.top - window.innerHeight;
       if (a == 0 && $(window).scrollTop()! > oTop1) {
         Aos.init();

       }
     }); */
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }


}
