import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service'
import { Category } from '../../models/category.model';
import * as Aos from 'aos';



@Component({
  selector: 'app-browse-category',
  templateUrl: './browse-category.component.html',
  styleUrls: ['./browse-category.component.css']
})
export class BrowseCategoryComponent implements OnInit {
  category: any;
  responsiveOptions: any;

  constructor(private categoryService: CategoryService) {

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {

    if (localStorage.getItem('categoryHome')) {
      this.category = localStorage.getItem('categoryHome');
      this.category = JSON.parse(this.category);
    }

    this.categoryService.getCategory().subscribe((data: any) => {
      this.category = data.data.categories;
      localStorage.setItem('categoryHome', JSON.stringify(data.data.categories));

    });
    /*
           var a = 0;
           $(window).on('scroll', function () {
             var oTop1 = $('#category').offset()!.top - window.innerHeight;
             if (a == 0 && $(window).scrollTop()! > oTop1) {
               Aos.init();

             }
           }); */
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}
