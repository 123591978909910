import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as Aos from 'aos';

declare var ExternalJsFile: () => void;

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss'],
})
export class AchievementsComponent implements OnInit {

  loading: boolean = false;

  constructor() {


  }

  ngOnInit(): void {
    ExternalJsFile();



  }



}
