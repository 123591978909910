
<div class="inner-banner">
  <img src="{{blog?.image}}" alt="">
</div>


<section class="college-detaile-header">
  <div class="container">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
              <li class="breadcrumb-item"><a routerLink="/blogs">Blogs</a></li>
          </ol>
      </nav>
      <div class="header-box">
          <div class="colege-name">

              <div class="name-location">
                  <h4 class="animate__animated animate__fadeInUp animate__delay-1s">{{blog.title}}</h4>
                  <h6 class="blog-detail animate__animated animate__fadeInUp animate__delay-1s" innerHTML="{{blog.description}}">


                  </h6>


                  </div>
          </div>

      </div>

  </div>
</section>



<app-footer></app-footer>



