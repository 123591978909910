import { Injectable } from '@angular/core';
declare let window: any;
@Injectable({
  providedIn: 'root'
})
export class OnesignalService {

  constructor() { }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function () {
        resolve(window.OneSignal);
      });
    });
  }

  onInit(): void {
    this.onLoad().then((OneSignal) => {
      OneSignal.init({
        appId: "b9197504-6219-4a5b-bc95-fc81704b5708",
      });
    });
  }
}
