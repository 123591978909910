import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { AppService } from '../app.service';
import { AuthService } from '../services/auth.service';
import { FeaturedCollegeService } from '../services/featuredCollege.service';
import { LoginService } from '../services/login.service';
import { TokenService } from '../services/token.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loggedIn!: boolean;
  dismiss: any;
  userregister1: any;
  userLogin: any;
  login1: any;
  show: boolean = false;
  register1: any;
  pwdType: string = "password";
  pwdType1: string = "password";
  validate: boolean = false;
  submitted: boolean = false;
  input: boolean = false;
  errorLogin: any = null;
  errorRegister: any = null;
  states: any;
  showLogin: boolean = true;
  showReg: boolean = true;
  log: boolean = false;
  user: any;
  image: any;
  id: any;
  id1: any;
  deferredPrompt: any;
  showButton: boolean = false;
  email: any = null;
  otp: any;

  form = {
    email: null,
    password: null
  };

  password = {
    oPassword: null,
    cPassword: null
  }
  reg: any;
  code: any;
  errorFP: any;
  otp6: any = null;
  errorOTP: any;
  userId: any;
  errorCP: any;
  submitted1: boolean = false;


  constructor(private fb: FormBuilder,
    private messageService: MessageService
    , private spinner: NgxSpinnerService, private featuredCollegeService: FeaturedCollegeService, private route: ActivatedRoute, private auth: AuthService, private token: TokenService, private loginService: LoginService, private toastr: ToastrService, private appService: AppService, private router: Router) {


    this.route.paramMap.subscribe(params => {
      if (this.route.snapshot.url[0]) {
        this.id = this.route.snapshot.url[1].path;
      }
    });

    this.featuredCollegeService.getStates().subscribe((data: any) => {
      this.states = data;
    });
  }

  ngOnInit(): void {
    this.auth.authStatus.subscribe(value => this.loggedIn = value);
    this.buildRegisterForm();
    $("#login1").modal("show");
  }


  buildRegisterForm() {
    this.userregister1 = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      address: ['', Validators.required],
      state: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      refer: ['', Validators.required]
    });
  }

  showSuccessReg() {
    this.toastr.show('User Registered successfully!');
  }

  showSuccessLogin() {
    this.toastr.show('Welcome to MyZeed!');
  }

  showCp() {
    this.toastr.success('Password Changed Successfully!');
  }

  onShow() {
    if (this.pwdType === 'password') {
      this.pwdType = 'text';
    } else {
      this.pwdType = 'password';
    }
  }

  onShowConfirm() {
    if (this.pwdType1 === 'password') {
      this.pwdType1 = 'text';
    } else {
      this.pwdType1 = 'password';
    }
  }

  onCloseLogin() {
    $("#login1").modal("hide");
    this.router.navigateByUrl('/home');
    this.submitted = false;
  }

  onCloseRegister() {
    $("#register1").modal("hide");
    this.router.navigateByUrl('/home');
    this.submitted = false;
  }

  onSubmitLogin() {
    this.errorLogin = '';
    this.login1 = this.form;
    this.showLogin = false;
    this.loginService.userLogin(this.login1).subscribe(
      data => this.handleResponse(data),
      error => this.handleErrorLogin(error),
    );

  }

  onSubmitRegister() {
    this.submitted = true;
    this.register1 = this.userregister1.value;
    this.showReg = false;
    this.loginService.userRegister(this.register1).subscribe(
      data => this.handleRegResponse(data),
      error => this.handleErrorReg(error),
    );
  }

  handleRegResponse(data: any) {
    this.reg = data;
    if (this.reg.message == "Success!") {
      this.showSuccessReg();
      $("#register1").modal("hide");
      this.showReg = true;
      $("#login1").modal("show");
    }
    else {
      this.handleErrorReg(data);
    }

  }


  handleResponse(data: any) {
    if (data.message == 'invalid credentials') {
      this.handleErrorLogin(data);
    }
    else {
      this.auth.changeAuthStatus(true);
      this.token.handle(data.accessToken);
      $("#login1").modal("hide");
      this.showLogin = true;

      this.showSuccessLogin();
      this.loggedIn = true;
      if (this.id == 'account') {
        this.router.navigateByUrl('/account');
      }
      else {
        this.router.navigateByUrl('/job');
      }

      if (this.loggedIn) {
        this.loginService.getUser().subscribe((data: any) => {
          this.user = data;
          localStorage.setItem('myProfile', JSON.stringify(data));
        });
      }
      setTimeout(() => {
        this.appService.sendClickEvent();
      }, 25);

    }

  }

  handleErrorReg(error: any) {
    if (error.name = "HttpErrorResponse" && error.errorcode != 1) {
      this.showReg = false;
      setTimeout(() => {
        if (error.name = "HttpErrorResponse") {
          this.onSubmitRegister();
        }
      }, 6000);
      return;
    }
    this.showReg = true;
    this.errorRegister = error.message;
  }

  handleErrorLogin(error: any) {
    if (error.name = "HttpErrorResponse" && error.errorcode != 2) {
      this.showLogin = false;
      setTimeout(() => {
        if (error.name = "HttpErrorResponse") {
          this.onSubmitLogin();
        }
      }, 6000);
      return;
    }
    this.showLogin = true;
    this.errorLogin = error.message;
  }

  forgotPassword() {
    this.spinner.show();
    this.loginService.forgotPassword(this.email).subscribe((data: any) => {
      this.spinner.hide();
      if (data.message == "User not exist!") {
        this.errorFP = data.message;
      }
      else if (data.message == "Mail Send Sucessfully.") {
        $("#forgot").modal("hide");
        $("#otp").modal("show");
        setTimeout(() => {
          this.messageService.add({ key: 'tl', severity: 'success', summary: 'OTP sent to', detail: this.email, life: 3000 });
        }, 500);

      }
    });
  }

  verifyOTP() {
    this.spinner.show();
    this.loginService.verifyOtp(this.email, this.otp6).subscribe((data: any) => {
      this.spinner.hide();
      if (data.message == "Invalid Otp") {
        this.errorOTP = data.message;
      }
      else if (data.message == "Verified Sucessfully.") {
        this.userId = data.data.user_id;
        this.token = data.data.token;
        $("#otp").modal("hide");
        $("#passwordChange").modal("show");
      }
    });

  }

  changePassword() {
    this.submitted1 = true;
    this.spinner.show();
    this.loginService.changePassword(this.userId, this.token, this.password.oPassword, this.password.cPassword).subscribe((data: any) => {
      this.spinner.hide();
      this.errorCP = data.message;
      if (data.message.password == "The password confirmation does not match.") {
        this.errorCP = data.message.password;
      }
      else if (data.message == "Password Changed Sucessfully.") {
        this.showCp();
        $("#passwordChange").modal("hide");
        this.router.navigateByUrl('/home');
      }
    });
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
