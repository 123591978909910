import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import { FeaturedCollege } from '../models/featuredCollege.model';
import { ApiService } from '../api.service';
import { API_ENDPOINTS } from '../app.config';
import { data } from 'jquery';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class FeaturedCollegeService extends ApiService {

  colleges: any;
  collegeData: any;
  states: any;

  constructor(private http: HttpClient) {
    super();
  }

  addCollege(data: any) {
    this.collegeData = data;
  }

  getCollege() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/dashboard";
    return this.http.get(url);
  }

  search(categoryid: any, courseid: any, collegeid: any, stateid: any, cityid: any, intrl: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/search";

    const body = {
      categoryid: categoryid,
      courseid: courseid,
      collegeid: collegeid,
      stateid: stateid,
      cityid: cityid,
      intrl: intrl
    }
    return this.http.post(url, body);
  }

  searchIntrl(categoryid: any, courseid: any, collegeid: any, countryid: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/search";

    const body = {
      categoryid: categoryid,
      courseid: courseid,
      collegeid: collegeid,
      countryid: countryid,
      intrl: 1
    }
    return this.http.post(url, body);
  }

  getCollegebyState(id: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getColleges";
    return this.http.get(url + '/' + id);
  }

  getCountry() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCountries";
    return this.http.get(url);

  }

  getAllCollege(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listAllColleges";
    return this.http.get<any>(url).pipe(
      map(data => data.data.colleges)
    );
  }

  getDomesticCollege() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listDomesticColleges";
    return this.http.get(url);
  }

  getInternationalCollege() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/listIntrlColleges";
    return this.http.get(url);
  }

  getDropdown(id: string) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getCollegeCourseCategory";
    return this.http.get(url + '/' + id);
  }

  getStates(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getStates";
    return this.http.get<any>(url + '/101').pipe(
      map(data => data.Data)
    );
  }



}
