import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/services/appointment.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  appointment: any;
  appointmentForm: any;
  customCheck5: any;
  error: any;
  show1: boolean = true;
  tnc: boolean = false;
  displayBasic!: boolean;

  form = {
    name: null,
    phone: null,
    email: null,
    message: null,
    option: null,
    tnc: null
  };

  constructor(private fb: FormBuilder, private toastr: ToastrService, private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.buildAppointmentForm();
  }

  buildAppointmentForm() {
    this.appointmentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required],
      option: [''],
      tnc: ['']
    });
  }


  onSubmit() {
    this.appointment = this.appointmentForm.value;
    this.show1 = false;
    this.appointmentService.appointment(this.appointment).subscribe(
      error => this.handleError(error),
      data => data
    );

    setTimeout(
      () => {
        if (this.error == 'success') {
          this.show1 = true;

          this.showSuccess();
          this.displayBasic = true;
          this.appointmentForm.reset();
          this.tnc = false;

        }
      }, 3000);
  }

  showSuccess() {
    this.toastr.success('Sent Successfully!');
  }

  show() {
    if (this.tnc == false) {
      this.tnc = true;
    }
    else if (this.tnc == true) {
      this.tnc = false;

    }
  }

  handleError(error: any) {
    this.show1 = true;
    this.error = error.message;
  }
}
