<section class="footer-section">
  <div class="container">

      <div class="row">
          <div class="col-md-12 col-lg-4">
             <a style="cursor: pointer;"> <img class="footerLogo" routerLink="/home" src="assets/images/myzeed-logo-color.png" alt=""></a>
              <p style="text-align: justify;">MyZeed.com is an EdTech startup.
                Search and sort out top institutions and courses from our web portal and we'll do the rest.
                Global search option for part/full time job seekers.
                Competitive exams portal..</p>
          </div>
          <div class="col-md-4 col-lg-3 col-6">
              <h1>About</h1>
              <ul>
                  <li><a routerLink="/about">About US</a></li>
                  <li><a routerLink="/about">Management Team</a></li>
                  <li *ngIf="loggedIn"><a routerLink="/account">My Account</a></li>
                  <li *ngIf="!loggedIn"><a [routerLink]="['/login' , 'account' ]">My Account</a></li>
                 <!--  <li><a routerLink="/about">Advertise With Us</a></li> -->
                  <li><a routerLink="/contact">Contact US</a></li>
                  <li><a routerLink="/faq">FAQ</a></li>
              </ul>
          </div>
          <div class="col-md-4 col-lg-2 col-6 others">
              <h1>Others</h1>
              <ul>
                  <li><a routerLink="/careers">Careers</a></li>
                  <li><a routerLink="/blogs">Blogs</a></li>
                  <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                  <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                  <!-- <li><a routerLink="/home">Sitemap</a></li> -->
              </ul>
          </div>
          <div class="col-md-4 col-lg-3">
              <h1>Follow Us</h1>
              <ul class="s-media">
                  <li><a href="https://www.facebook.com/myzeedcom" target="_blank" rel="noopener" rel="noopener noreferrer"><i class="fab fa-facebook-f"></i></a></li>
                  <!-- <li><a href="https://twitter.com/" target="_blank" rel="noopener" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a></li> -->
                  <li><a href="https://www.instagram.com/myzeedcom" target="_blank" rel="noopener" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a></li>
                  <!-- <li><a href="https://www.linkedin.com/" target="_blank" rel="noopener" rel="noopener noreferrer"><i class="fab fa-linkedin-in"></i></a></li> -->
              </ul>

  <h2><a href="mailto:myzeed.com@gmail.com"><img src="assets/images/mail.png" alt=""></a> <a style="color:#212529" href="mailto:myzeed.com@gmail.com"><span class="text">myzeed.com@gmail.com</span></a></h2>
  <h2><a  style="color:#212529" href="https://wa.me/+918089624554" target="_blank" rel="noopener"><img src="assets/images/whatsapp.png" alt=""><span class="text">8089624554</span></a></h2>

              <!-- <h2><a  style="color:#212529" href="tel:04844860481"><img src="assets/images/phone.png" alt=""><span class="text">04844860481</span></a></h2> -->
              <h2><a  style="color:#212529" href="tel:9746523738"><img src="assets/images/phone.png" alt=""><span class="text">9746523738</span></a></h2>
          </div>

      </div>
      <div class="footer-bottom">
          <span>Copyright</span><img src="assets/images/copy.png" alt=""><span>2021 MyZeed, All rights reserved</span>
      </div>
  </div>
  </section>
