 <section class="privacy-policy-section">
 <div class="container">
      <h1 class="main-heading">Careers</h1>
      <h4>Widen your career branches across the globe.</h4>
      <p>Which degree should I opt for?
        Should I study in my home country or abroad?
        Which university/college should I enroll for?
        What all are the Financial commitments I’ll need to make?
        What are the future career prospects after completion of my degree in that country?

        Have you ever asked the aforementioned questions to yourself?
        If yes, then you have arrived at the right place.
        Why study abroad?  Let’s have a look,</p>

        <h4>Education.</h4>
        <p>Indeed, education remains the centerpiece of any study abroad trip and selecting the right college/institutions remains one of the most crucial decisions to be made. When you opt for studying abroad, there are various areas you’ll be exposed to such as, experiencing different styles of education, learning different languages, meeting people with different culture, develop your personality and thus prepare yourself for the future. In a globalized, well-connected world, employers increasingly value candidates with international education and experience.</p>
        <h4>Exposed to new culture.</h4>
        <p>By taking in a new culture, you are exposed to a new experience, leaving your home country. An opportunity indeed, to acknowledge, appreciate, and understand the host country's people and history. Along with studying abroad you will find amazing food, new traditions and social atmospheres thus enhancing your growth overall.</p>

        <h4>Learn new languages.</h4>
        <p>One of the best draws of studying abroad is one can hone their language skills. An opportunity to learn a foreign language is an added advantage. Imagine yourself attending an interview at a later stage and making the interviewer aware of you knowing a foreign language apart from your academics? An advantage indeed, to add an extra feather to your cap and thus boost your career growth.</p>

        <h4>Explore new interests. </h4>
        <p>Studying in a different country allows you to be exposed to various activities and interest, which you may not have learned if you would have stayed in your home country. Discover new talents hidden in you by learning new sports or activities such as hiking, golf, diving etc. Don’t you miss a chance to find the new you.</p>

        <h4>Personal Development.</h4>
        <p>A benefit of studying abroad is that you’ll be on your way, discovering yourself amidst different culture. There is nothing quite like being on a foreign country, that helps your bring out your independent nature. Know who you are, how easily you can adapt to change, your problem solving skills, make a self-analysis and thus grow yourself not just academically but personally as well.</p>

        <h4>Life Experience</h4>
        <p>How does it feel to be on a vacation, yet not on a vacation? Studying abroad is the key. This time might be the only opportunity for you to travel abroad for a long period of time, wherein you learn, earn and grow. An experience that will be cherished for a lifetime.
          Hence, if you are interested in benefiting from studying abroad, get in touch with us, where we help you grow your dreams and prepare you for the future.</p>
<h3>MyZeed -  Study in India.</h3>
<p>When we talk about a land rich in its culture, religious diversity and multi-lingual states with more than 800 plus universities spread across the land, India is the name that is top notch</p>

<p>Indian education system has played a leading role in the field of business management, healthcare, finance and information technology to name a few.</p>
<p>Let’s have a look at what makes India one of the best destinations to imprint your career.
  1.High quality education.
  2.Top ranking universities.
  3.The cost of living is the last thing you need to worry about.
  4.Providing financial assistance to students in the form of scholarships.
  5.Array of courses available to choose from.
  6.Get to interact and socialize in campus life.</p>
<p>Come and join MyZeed team, where we constantly strive to deliver better than the best. Join hands with us. Let's learn and grow together. Let's make a difference and build a student's career to prepare them for the future.
      </p>

      <p class="careermail">Drop us a mail to <a class="hvr" href="mailto:myzeed.com@gmail.com"><b>myzeed.com@gmail.com</b></a> with your CV.</p>

  </div>



<!-- <section class="sendUs-section">
  <div class="container">
      <h1 class="main-heading">Send us a message</h1>
      <div class="row">
          <div class="col-lg-6">
              <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter the Name">
                  <img class="black" src="assets/images/user-2.png" alt="">
                  <img class="green" src="assets/images/user.png" alt="">
              </div>
          </div>
          <div class="col-lg-6">
              <div class="form-group">
                  <input type="number" class="form-control" placeholder="Phone Number">
                  <img class="black" src="assets/images/phone-1.png" alt="">
                  <img class="green" src="assets/images/phone-1-green.png" alt="">
              </div>
          </div>
          <div class="col-12">
              <div class="form-group">
                  <textarea class="form-control" placeholder="Messages Here"></textarea>
                  <img class="black" src="assets/images/mail-2.png" alt="">
                  <img class="green" src="assets/images/mail-2-green.png" alt="">
              </div>
          </div>
          <div class="col-12 text-right">
              <button class="main-btn">Submit</button>
          </div>
      </div>
  </div>
</section> -->


<!-- <div class="notificationWrapper top-xamWrapper">
  <div class="container">
      <ul >
          <li>

              <div class="detail">
                <p-tag class="new" value="New"></p-tag>

                <h3 style="margin-bottom: 22px;font-weight: 600;">Hiring Education Consultant</h3>
                <p>Requirements</p>
                <ul class="list">
                  <li>Female Candidates preferred.</li>
                  <li>Should be a good communicator.</li>
                  <li>Should interact with candidates and give them necessary assistance.</li>
                </ul>
                <p>Experience:<span>&nbsp;6 months - 1 year</span></p>

                <p style="margin-top: 5px;">Job Location: <span>Kochi (Currently working remotely)
                </span> </p>
                      <div class="date-download">
                          <a class="readmore" routerLink="/contact" >Apply Now</a>
      <p style="margin-left: 0 !important;">&nbsp;or Email your resume to <a class="hvr" href="mailto:myzeed.com@gmail.com"><b>myzeed.com@gmail.com</b></a></p>

                      </div>
              </div>
          </li>

      </ul>
  </div>
</div> -->

