<p-progressSpinner class="spinner" [hidden]="show" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
    strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

<section class="contactPage-section">

    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="imgWrapper">
                    <img src="assets/images/left-img.png" alt="">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="contactWrapper">
                    <h1>Reach Out To Us</h1>
                    <br>
                    <div class="address">
                        <div class="icon mt-0">
                            <img src="assets/images/address.png" alt="">
                        </div>
                        <div class="detail col-lg-6">
                            <div class="mb-3">
                                <h4 class="mb-3">Address</h4>
                                <h6>Cochin</h6>
                                <p>
                                    Myzeed.com Pvt. Ltd. </p>
                                <p>39/4967 - D1, Uznaz Tower,</p>
                                <p> Pallimukku, Cochin </p>
                                <p> Pincode: 682016</p>
                            </div>
                            <div class="mb-3">
                                <h6>Poland</h6>
                                <p>
                                    UI. Pulawska 617/621 a</p>
                                <p>Warszawa, mazowieckie,02-885</p>
                                <p> Poland</p>
                            </div>
                        </div>

                    </div>


                    <div class="address">
                        <div class="icon">
                            <img src="assets/images/telephone-call.png" alt="">
                        </div>
                        <div class="detail">
                            <h4>Phone number</h4>
                            <!-- <a href="tel:04844860481">0484 4860481</a> <br> -->

                            <a href="https://wa.me/+918089624554" target="_blank" rel="noopener">+91 8089624554</a> <br>
                            <!-- <a href="tel:+919447866626">+91 9447866626</a> -->
                            <a href="tel:+919746523738">+91 9746523738</a>
                        </div>
                    </div>
                    <div class="address">
                        <div class="icon">
                            <a href="https://www.facebook.com/myzeedcom" target="_blank" rel="noopener"> <img
                                    src="assets/images/fb.png" alt=""> </a>

                        </div>
                        <div class="detail">
                            <a href="https://www.facebook.com/myzeedcom" target="_blank" rel="noopener">
                                <h4>Facebook</h4>
                            </a>
                            <a href="https://www.facebook.com/myzeedcom" target="_blank" rel="noopener">Like our
                                facebook page</a>
                        </div>
                    </div>
                    <!--   <div class="address">
                      <div class="icon">
                        <a href="https://twitter.com/" target="_blank" rel="noopener"><img src="assets/images/twitter.png" alt=""></a>
                      </div>
                      <div class="detail">
                        <a href="https://twitter.com/" target="_blank" rel="noopener"><h4>Twitter</h4></a>
                          <a href="https://twitter.com/" target="_blank" rel="noopener">Follow us on twitter page</a>
                      </div>
                  </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="map">
          <h1>Find us In Google map</h1>
          <div class="imgWrapper">
              <img src="assets/images/map.jpg" alt="">
          </div>
      </div> -->

        <div class="getIn-touch">
            <div class="row">
                <div class="col-lg-6">
                    <h1>Get In Touch With Us</h1>
                    <p>To know more about us or for any enquiries, drop in your details and we will get in touch with
                        you shortly.</p>

                </div>
                <div class="col-lg-6">
                    <div class="sendUs-msg">
                        <h2>Send us a message</h2>
                        <form [formGroup]="messageForm">

                            <div class="form-group">

                                <input type="text" class="form-control" formControlName="name"
                                    placeholder="Enter the Name" required>
                                <img class="black" src="assets/images/user-2.png" alt="">
                                <img class="green" src="assets/images/user.png" alt="">
                                <small class="text-danger" *ngIf="error?.name">{{error.name}}</small>

                            </div>
                            <div class="form-group">

                                <input type="number" formControlName="phone" class="form-control"
                                    placeholder="Phone Number" pattern="[0-9]{10}" required>
                                <img class="black" src="assets/images/phone-1.png" alt="">
                                <img class="green" src="assets/images/phone-1-green.png" alt="">
                                <small class="text-danger" *ngIf="error?.phone">{{error.phone}}</small>

                            </div>

                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control"
                                    placeholder="Email Address" required>
                                <img class="black" src="assets/images/mail-1.png" alt="">
                                <img class="green" src="assets/images/mail-1-green.png" alt="">
                                <small class="text-danger" *ngIf="error?.email">{{error.email}}</small>

                            </div>


                            <div class="form-group msg-group">
                                <textarea formControlName="message" class="form-control" placeholder="Messages Here"
                                    required></textarea>
                                <img class="black" src="assets/images/mail-2.png" alt="">
                                <img class="green" src="assets/images/mail-2-green.png" alt="">
                                <small class="text-danger" *ngIf="error?.message">{{error.message}}</small>

                            </div>


                            <button class="main-btn" type="submit" (click)="onSend()">Send</button>
                        </form>
                    </div>
                </div>
            </div>
            <p-dialog header="MyZeed.com" [blockScroll]="true" [modal]="true" [(visible)]="displayBasic"
                [style]="{width: '50vw'}" [baseZIndex]="10000">
                <h4 style="margin-left: 178px;">Our executive will contact you shortly !</h4>
                <ng-template pTemplate="footer">
                    <button pButton pRipple type="button" (click)="displayBasic=false" label="Ok"
                        class="p-button-raised p-button-success"></button>
                </ng-template>
            </p-dialog>

        </div>
    </div>
</section>