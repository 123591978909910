<p-progressSpinner class="spinner" [hidden]="show1" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>



<div class="inner-banner">
  <img src="assets/images/inner-banner-9.png" alt="">
</div>
<div class="pageHeading">
  <div class="container">
      <h1>Find Institutions</h1>
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Find Institutions</li>
          </ol>
      </nav>
  </div>
</div>


<section class="search-college-section find-jobPage-section findColleges-section">
  <div class="container">
      <form action="">
          <div class="search-wrapper findJob-wrapper">
              <input type="text"  name="name" [(ngModel)]="name" (ngModelChange)="search()" autocomplete="off" placeholder="Courses you are searching for....">
              <img src="assets/images/search.png" alt="">
          </div>

       <!--    <button class="main-btn">Courses</button> -->
          <ul *ngIf="show" class="search-result">
            <cdk-virtual-scroll-viewport itemSize="40" class="example-viewport">
              <li *cdkVirtualFor="let course of courses" >
                <a [routerLink]="['/findinstitutions-course' , course.course_id ]" (click)="change()"><span>{{course.name|titlecase}}</span></a>
              </li>
            </cdk-virtual-scroll-viewport>
        </ul>
      </form>
  </div>
</section>



<section class="admission-section top-collegeTab-section">
  <div class="container">
      <div class="courseSelect">

          <h3 *ngIf="course" class="small">Institutions best for <span>{{course|titlecase}}</span> </h3>

          <div class="card">

            <p-table #dt1 [value]="colleges" dataKey="id" [loading]="loading"
                [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"  styleClass="p-datatable-customers p-datatable-gridlines"
                [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['name','place']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
          <h3 [hidden]="!course" class="large">Institutions best for <span>{{course|titlecase}}</span> </h3>

                        <span class="holder p-input-icon-right table-search">
                            <i class="pi pi-search search"></i>
                            <input pInputText type="text" class="place" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search Institutions ..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="collegename">
                            <div class="centerHeader">
                              Institution Name

                            </div>
                        </th>
                        <th class="colgplace">
                            <div class="centerHeader ">
                                Place
                            </div>
                        </th>
                       <!--  <th>
                          <div class="centerHeader">
                            Position
                        </div>
                        </th>
                        <th>
                            <div class="centerHeader">
                                Last Date to Apply
                            </div>
                        </th>
                        <th>
                            <div class="centerHeader">
                                Type of Job
                            </div>
                        </th> -->

                        <th class="large apply"></th>


                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-college>
                    <tr>
                        <td>

                            <img class="large" [src]="college.logo" alt="college_logo" width="30">

<a [routerLink]="['/about-institutions' , college.id ]" >
                        <span class="text texthead">{{college.name}}</span>

                          </a>
                        </td>
                        <td *ngIf="college.state" class="center text">
                          {{college.state}}

                        </td>

                        <td *ngIf="!college.state" class="center text">
                          {{college.country}}

                        </td>
                      <!--   <td class="center">
                          {{job.position}}

                        </td>
                        <td class="center">
                            {{job.deadline | date : 'dd-MM-YYYY'}}
                        </td>
                        <td class="center">
                          {{job.type_of_job}}

                        </td> -->

                        <td class="center large">
      <a [routerLink]="['/about-institutions' , college.id ]" target="_blank" rel="noopener" > <button type="button"  class="main-btn">Apply Now</button></a>

                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No Institutions found.</td>
                    </tr>
                </ng-template>
            </p-table>
          </div>




      </div>

  </div>
</section>




<app-footer></app-footer>