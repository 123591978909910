import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Course } from '../models/course.model';
import { API_ENDPOINTS } from '../app.config';



@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(private http: HttpClient) {

  }

  getCourses() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/dashboard";
    return this.http.get(url);

  }

  getAllCourseCategory(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getCategoryCourse";
    return this.http.get<any>(url).pipe(
      map(data => data.Data)
    );

  }


  getCourseCategory(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCourseCategory";
    return this.http.get<any>(url).pipe(
      map(data => data.Data)
    );

  }


  getAllType(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getCollegeTypes";
    return this.http.get<any>(url).pipe(
      map(data => data.Data)
    );
  }

  getCollegeTypeSearch(id: any, num: any): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCollegesTypes";
    const body = {
      typeid: id,
      intrl: num
    }
    return this.http.post<any>(url, body).pipe(
      map(data => data.Data)
    );

  }

  getAllCourse(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCourses";
    return this.http.get<any>(url).pipe(
      map(data => data.Data)
    );

  }

  getAllColleges(id: any): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllColleges";
    return this.http.get<any>(url + '/' + id).pipe(
      map(data => data.Data)
    );

  }
}
