import { Component, OnInit } from '@angular/core';
import { AdvertiseService } from 'src/app/services/advertise.service';

@Component({
  selector: 'app-india-fastest',
  templateUrl: './india-fastest.component.html',
  styleUrls: ['./india-fastest.component.scss'],
})
export class IndiaFastestComponent implements OnInit {

  ads: any;
  show: boolean = false;
  responsiveOptions: any;
  constructor(private advertiseService: AdvertiseService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    if (localStorage.getItem('adTopHome')) {
      this.ads = localStorage.getItem('adTopHome');
      this.ads = JSON.parse(this.ads);
      if (this.ads != '') {
        this.show = true;
      }
    }


    this.advertiseService.getAdTop().subscribe((data: any) => {
      this.ads = data.Data;
      if (this.ads != '') {
        this.show = true;
      }
      localStorage.setItem('adTopHome', JSON.stringify(data.Data));

    });
  }
}
