import { Component, OnInit } from '@angular/core';
import { AdvertiseService } from 'src/app/services/advertise.service';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss']
})
export class WhoWeAreComponent implements OnInit {

  mail: any;
  constructor(private advertiseService: AdvertiseService) { }

  ngOnInit(): void {
  }

  send() {
    this.advertiseService.subscribe(this.mail).subscribe(
      data => data,
      error => error
    );
  }

}
