<section class="education-section newsUpdatePage-section">
  <div class="container">


      <div class="">

          <div class="col-lg-12 big-col">
              <ul class="newsUpdate-detail">
                  <li *ngFor="let new of news">
                      <div class="date">
                          <h3 class="text-center">{{new.date | date: 'MMM'}}</h3>
                          <h2 class="text-center">{{new.date | date: 'dd'}}</h2>
                      </div>
                      <div class="detail">
                          <div class="imgWrapper">
                              <img src="{{new.image}}" alt="">
                    <p-tag *ngIf="new.day_status == 1" class="new" value="New"></p-tag>

                          </div>
                          <div class="detailWrapper">
                              <h3 class="head">{{new.title}}</h3>
                              <p class="para">{{new.description}}</p>
                                  <ul>
                                      <li><a>{{new.date | date: 'MMM dd yyyy'}}</a></li>
                                      <li class="readmore"><a [href]="new.url" target="_blank" rel="noopener"> Read More </a></li>

                                  </ul>
                          </div>
                      </div>
                  </li>

              </ul>
          </div>
      </div>

  </div>
</section>
