<app-refer-banner></app-refer-banner>

<div class="accountPage">
    <div class="container accoun-container">
       <app-refer-sidebar></app-refer-sidebar>
        <div class="main-content">
            <h1>Refer & Earn</h1>
            <div class="sideBar-menu">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div *ngIf="referal" class="share dropdown">
                <div class="innerWrapper">
                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="nav-link account-link"><i
                            class="fas fa-share-alt"></i><span>Share</span></a>

                    <div  class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <ul>
                          <li><a href="https://api.whatsapp.com/send?text=Hey, Start your career through MyZeed.com now. Use my Referral Code *{{referal}}* to join and get rewarded." data-action="share/whatsapp/share" target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                      class="fab fa-whatsapp"></i></a></li>
                          <li><a href="http://twitter.com/share?text=Hey, Start your career through MyZeed.com now. Use my Referral Code {{referal}} to join and get rewarded." target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                      class="fab fa-twitter"></i></a></li>
                                      <li><a href="http://www.facebook.com/share.php?u=myzeed.com&quote=Hey, Start your career through MyZeed.com now. Use my Referral Code {{referal}} to join and get rewarded." target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                        class="fab fa-facebook-f"></i></a></li>
                          <!-- <li><a href="https://www.linkedin.com/shareArticle?mini=true&u=myzeed.com&quote=Hey, Start your career through MyZeed.com now.Use my Referral Code {{referal}} to join and get rewarded.&title=Referral code for myzeed&source=myzeed.com" target="_blank" rel="noopener" rel="noopener noreferrer"><i
                                      class="fab fa-linkedin-in"></i></a></li> -->
                      </ul>
                  </div>
                </div>
            </div>
            <div class="ref-earn-box yesRefer">
              <p class="text-justify"> Doesn't matter if you are a student or not, we have an excellent opportunity for you to make some extra cash. Make the optimum use of it. </p>
              <h3 *ngIf="referal" class="proc1" style="font-size: 20px;">Your Referral Code : <span style="font-weight: bold;
    font-size: 22px;">{{referal}}</span></h3>
    <p *ngIf="referal == null" (click)="refer()" class="refer" >Didn't find your Referral code here? Get your Referral Code now.</p>
              <h3 class="proc">The Process</h3>
              <ol>
                <li>For every customer, a referral code will be generated. </li>
                <li>Once the customer shares this link with a prospective student, looking forward to study abroad, the code will be shared to that student. </li>
                <li>When that student enters the given code, he/she will be in reference to the customer. </li>
                <li>And, if the student takes admission via MyZeed, the customer will be rewarded (this will be handled manually).</li>

              </ol>
            </div>
<!-- <br>
            <div *ngIf="referal" class="ref-earn-box invite">
              <p class="text-center"> Your invited friends on MyZeed.com </p>

              <p-button (click)="showBasicDialog2()" icon="pi pi-users" label="My Referral"></p-button>
              <p-dialog header="My Referral" blockScroll=true [(visible)]="displayBasic2" [style]="{width: '50vw'}" [baseZIndex]="10000">
                <p-table >
                  <ng-template pTemplate="header">
                      <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Status</th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body">
                      <tr>
                          <td>VSN</td>
                          <td>12/06/2012</td>
                          <td>Completed</td>
                      </tr>
                  </ng-template>
              </p-table>

              </p-dialog>
            </div> -->
        </div>
    </div>

</div>


<app-footer></app-footer>
