import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { Course } from 'src/app/models/course.model';
import { Category } from 'src/app/models/category.model';
import { Enquiry } from 'src/app/models/enquiry.model';
import { AdmissionService } from 'src/app/services/admission.service';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';

declare var $: any;
@Component({
  selector: 'app-college-admission',
  templateUrl: './college-admission.component.html',
  styleUrls: ['./college-admission.component.scss']
})
export class CollegeAdmissionComponent implements OnInit {
  applyForm: any;
  colleges: any;
  courses: any;
  error: any;
  show: boolean = true;
  courseApply: any;
  categoryApply: Category[] = [];
  course!: SelectItem[];
  id: any;
  user: any;
  apply!: Enquiry;
  submitted: boolean = false;
  newCategory: String[] = [];
  responsiveOptions: any;
  x: any;



  constructor(private fb: FormBuilder, private featuredCollegeService: FeaturedCollegeService, private router: Router, private admissionService: AdmissionService, private route: ActivatedRoute, private toastr: ToastrService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });


    this.buildForm();

    this.admissionService.getCollegeadmissionbyId(this.id).subscribe((data: any) => {
      this.colleges = data.data;
      this.courses = data.data.course;
    });

    this.featuredCollegeService.getDropdown(this.id).subscribe((data: any) => {
      this.categoryApply = data.data.category;
      this.courseApply = data.data.courses;
      this.sample();

    });
  }

  scroll() {
    this.x = document.getElementById('target');
    this.x.scrollIntoView();
  }

  showSuccess() {
    this.toastr.show('Applied Successfully!');
  }

  onClose() {
    this.applyForm.reset();
    this.submitted = false;
    $("#applyNow").modal("hide");
  }

  sample() {

    let uniqueObject: any = {};

    for (let i in this.categoryApply) {
      const objName = this.categoryApply[i]['category_name'];

      uniqueObject[objName] = this.categoryApply[i];
    }

    for (let i in uniqueObject) {
      this.newCategory.push(uniqueObject[i]);
    }
  }



  onSubmit() {
    this.submitted = true;
    this.apply = this.applyForm.value;
    this.show = false;
    this.admissionService.applyPost(this.apply, this.id).subscribe(
      error => this.handleError(error),
      data => data
    );

    setTimeout(
      () => {
        if (this.error == 'Success!') {
          this.show = true;
          this.showSuccess();
          $("#applyNow").modal("hide");
          this.applyForm.reset();
        }
      }, 3000);

  }

  handleError(error: any) {
    this.error = error.message;
    this.show = true;
  }

  buildForm() {
    this.applyForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      stream: ['', Validators.required],
      program: ['', Validators.required]
    });
  }
}
