import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CityService } from 'src/app/services/city.service';
import { CoursesService } from 'src/app/services/courses.service';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';

@Component({
  selector: 'app-international',
  templateUrl: './international.component.html',
  styleUrls: ['./international.component.scss']
})
export class InternationalComponent implements OnInit {

  colleges: any;
  field: any;
  searchColleges: any;
  countrySearch: any;
  courseSearch: any;
  active: boolean = false;
  country: boolean = false;
  show: boolean = false;
  city: boolean = false;
  course: boolean = false;
  categoryid: Array<string> = [];
  courseid: any = [];
  stateBox: any;
  countries: any;
  countries1: any;
  cities: any;
  cities1: any;
  catCourses: any;
  catCourses1: any;
  selectedCountry: any = [];
  selectedCity: any = [];
  selectedCourse: any = [];
  selectedCategory: any = [];
  selectedCollege: any = [];
  pid: any;
  types: any;
  collegesLocal: any;
  private subscriptions: Subscription[] = [];
  totalRecords: any;
  x: any;
  activeCity: boolean = false;
  activeCountry: boolean = false;
  activeName: boolean = false;
  searchColleges1: any;
  activeCourse: boolean = false;
  coursesLocal: any;

  constructor(private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private featuredCollegeService: FeaturedCollegeService, private courseService: CoursesService, private cityService: CityService) { }

  ngOnInit(): void {
    this.spinner.show();

    if (localStorage.getItem('courses')) {
      this.coursesLocal = localStorage.getItem('courses');
      this.catCourses = JSON.parse(this.coursesLocal);
      this.catCourses1 = JSON.parse(this.coursesLocal);
    }
    const loadSubscriptionCourse = this.courseService.getCourseCategory().subscribe((data: any) => {
      this.catCourses = data;
      localStorage.setItem('courses', JSON.stringify(data));
      this.catCourses1 = data;
      this.cdr.detectChanges();
    });
    this.subscriptions.push(loadSubscriptionCourse);


    this.countrySearch = '';
    this.field = '';
    this.active = false;
    this.activeName = false;
    this.activeCity = false;
    this.activeCountry = false;
    this.activeCourse = false;
    this.selectedCity = [];
    this.selectedCourse = [];
    this.selectedCategory = [];
    this.selectedCollege = [];

    setTimeout(
      () => {
        this.show = true;
      }, 2000);

    if (localStorage.getItem('types')) {
      this.types = localStorage.getItem('types');
      this.types = JSON.parse(this.types);
    }

    const loadSubscription = this.courseService.getAllType().subscribe((data: any) => {
      this.types = data;
      this.cdr.detectChanges();
      localStorage.setItem('types', JSON.stringify(data));
    });
    this.subscriptions.push(loadSubscription);

    if (localStorage.getItem('countries')) {
      this.countries = localStorage.getItem('countries');
      this.countries = JSON.parse(this.countries);
      this.countries1 = localStorage.getItem('countries');
      this.countries1 = JSON.parse(this.countries1);
    }

    const loadSubscriptionCity = this.featuredCollegeService.getCountry().subscribe((data: any) => {
      this.countries = data.Data;
      this.countries1 = data.Data;
      localStorage.setItem('countries', JSON.stringify(data.Data));
      this.cdr.detectChanges();
    });
    this.subscriptions.push(loadSubscriptionCity);

    if (localStorage.getItem('intrlColleges')) {
      this.collegesLocal = localStorage.getItem('intrlColleges');
      this.colleges = JSON.parse(this.collegesLocal);
      this.searchColleges = this.colleges;
      this.spinner.hide();
      this.totalRecords = this.colleges.length;
      this.searchColleges1 = JSON.parse(this.collegesLocal);
    }

    const loadSubscriptionCollege = this.featuredCollegeService.getInternationalCollege().subscribe((data: any) => {
      this.colleges = data.data.colleges;
      this.searchColleges = this.colleges;
      this.spinner.hide();
      localStorage.setItem('intrlColleges', JSON.stringify(data.data.colleges));
      this.searchColleges1 = this.colleges;
      this.cdr.detectChanges();
      this.totalRecords = this.colleges.length;
    });
    this.subscriptions.push(loadSubscriptionCollege);

    this.active = false;

  }

  scroll() {
    this.x = document.getElementById('target');
    this.x.scrollIntoView();
  }

  scroll1() {
    this.x = document.getElementById('type');
    this.x.scrollIntoView();
  }

  searchType(id: any) {
    if (this.pid == id) {
      this.pid = '';
      this.colleges = this.searchColleges1;
    }
    else {
      this.pid = id;
      this.active = false;
      this.courseService.getCollegeTypeSearch(id, 1).subscribe((data: any) => {
        this.colleges = data;
        if (this.colleges == '') {
          this.active = true;
        }
      });
    }
  }

  filter() {
    this.categoryid = [];
    this.courseid = [];
    for (let i = 0; i < this.selectedCourse.length; i++) {
      this.categoryid[i] = this.selectedCourse[i].category_id;
      this.courseid[i] = this.selectedCourse[i].course_id;
    }
    this.active = false;
    this.featuredCollegeService.searchIntrl(this.categoryid, this.courseid, this.selectedCollege, this.selectedCountry).subscribe((data: any) => {
      this.colleges = data.Data;
      if (this.colleges == '') {
        this.active = true;
      }
    });
  }

  search() {
    if (this.field == "") {
      this.searchColleges = this.searchColleges1;
    }
    else {
      this.activeName = false;
      this.searchColleges = this.searchColleges1;
      this.searchColleges = this.searchColleges.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.field.toLocaleLowerCase());
      })
    }
    if (this.searchColleges == '') {
      this.activeName = true;
    }
  }

  searchCountryList() {
    if (this.countrySearch == "") {
      this.countries = this.countries1;
    }
    else {
      this.activeCountry = false;
      this.countries = this.countries1;
      this.countries = this.countries.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.countrySearch.toLocaleLowerCase());
      })
    }
    if (this.countries == '') {
      this.activeCountry = true;
    }
  }

  searchCourseList() {
    if (this.courseSearch == "") {
      this.catCourses = this.catCourses1;
    }
    else {
      this.activeCourse = false;
      this.catCourses = this.catCourses1;
      this.catCourses = this.catCourses.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.courseSearch.toLocaleLowerCase());
      })
    }
    if (this.catCourses == '') {
      this.activeCourse = true;
    }
  }
}
