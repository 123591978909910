import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountEditComponent } from './account/account-edit/account-edit.component';
import { AccountComponent } from './account/account.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { AboutCollegeComponent } from './college/about-college/about-college.component';
import { AdmissionComponent } from './college/about-college/admission/admission.component';
import { CollegepageComponent } from './college/collegepage/collegepage.component';
import { CoursepageComponent } from './course/coursepage/coursepage.component';
import { ExamComponent } from './exam/exam.component';
import { AboutusComponent } from './footer/aboutus/aboutus.component';
import { CareerComponent } from './footer/career/career.component';
import { ContactComponent } from './footer/contact/contact.component';
import { FaqComponent } from './footer/faq/faq.component';
import { PrivacyComponent } from './footer/privacy/privacy.component';
import { TermsConditionComponent } from './footer/terms-condition/terms-condition.component';
import { CollegeCategoryComponent } from './home/browse-category/college-category/college-category.component';
import { FindCollegeCityComponent } from './home/browse-city/find-college-city/find-college-city.component';
import { HomepageComponent } from './home/homepage/homepage.component';
import { EarnComponent } from './home/learn-earn/earn/earn.component';
import { LearnComponent } from './home/learn-earn/learn/learn.component';
import { NewsComponent } from './home/news-updates/news/news.component';
import { TestimonialsComponent } from './home/testimonial/testimonials/testimonials.component';
import { JobComponent } from './job/job.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ReferComponent } from './refer/refer.component';
import { CollegeImagesComponent } from './college/about-college/college-images/college-images.component';
import { CollegeVideosComponent } from './college/about-college/college-videos/college-videos.component';
import { BlogsComponent } from './blogs/blogs.component';
import { AfterLoginService } from './services/afterLogin.service';
import { DomesticComponent } from './college/domestic/domestic.component';
import { InternationalComponent } from './college/international/international.component';
import { BrowseCourseComponent } from './home/top-cources/browse-course/browse-course.component';
import { HeaderComponent } from './header/header.component';
import { ScholarshipComponent } from './scholarship/scholarship.component';
import { BlogDetailComponent } from './blogs/blog-detail/blog-detail.component';
import { OfflineComponent } from './offline/offline.component';
import { LoginComponent } from './login/login.component';
import { BeforeLoginService } from './services/beforeLogin.service';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: 'login/:page', component: LoginComponent, canActivate: [BeforeLoginService] },
  { path: '', component: HomepageComponent },
  { path: 'institutions', component: CollegepageComponent },
  { path: 'domestic-institutions', component: DomesticComponent },
  { path: 'international-institutions', component: InternationalComponent },
  { path: 'course', component: CoursepageComponent },
  { path: 'account', component: AccountComponent, canActivate: [AfterLoginService] },
  { path: 'refernearn', component: ReferComponent, canActivate: [AfterLoginService] },
  { path: 'editAccount', component: AccountEditComponent, canActivate: [AfterLoginService] },
  { path: 'request', component: AppointmentComponent },
  { path: 'job', component: JobComponent, canActivate: [AfterLoginService] },
  { path: 'exam', component: ExamComponent },
  { path: 'about', component: AboutusComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'learn', component: LearnComponent },
  { path: 'news-updates', component: NewsComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  { path: 'careers', component: CareerComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'terms-condition', component: TermsConditionComponent },
  { path: 'earn', component: EarnComponent },
  { path: 'findinstitutions-city/:id', component: FindCollegeCityComponent },
  { path: 'findinstitutions/:id', component: CollegeCategoryComponent },
  { path: 'findinstitutions-course/:id', component: BrowseCourseComponent },
  { path: 'about-institutions/:id', component: AboutCollegeComponent },
  { path: 'admission/:id', component: AdmissionComponent },
  { path: 'images/:id', component: CollegeImagesComponent },
  { path: 'videos/:id', component: CollegeVideosComponent },
  { path: 'blog-detail/:id', component: BlogDetailComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'offline', component: OfflineComponent },
  { path: 'scholarships', component: ScholarshipComponent },
  { path: 'notFound', component: NotFoundComponent },
  { path: '**', redirectTo: '/notFound', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [

  HomepageComponent,
  CollegepageComponent,
  CoursepageComponent,
  AccountComponent,
  ReferComponent,
  AccountEditComponent,
  AppointmentComponent,
  JobComponent,
  ExamComponent,
  AboutusComponent,
  ContactComponent,
  LearnComponent,
  NewsComponent,
  TestimonialsComponent,
  PrivacyComponent,
  CareerComponent,
  FaqComponent,
  TermsConditionComponent,
  EarnComponent,
  FindCollegeCityComponent,
  CollegeCategoryComponent,
  CollegeVideosComponent,
  CollegeImagesComponent,
  NotFoundComponent,
]

