import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-course-college',
  templateUrl: './course-college.component.html',
  styleUrls: ['./course-college.component.scss']
})
export class CourseCollegeComponent implements OnInit {

  enquiries: any = [];
  constructor(private loginService: LoginService) { }

  ngOnInit(): void {

    if (localStorage.getItem('enquiries')) {
      this.enquiries = localStorage.getItem('enquiries');
      this.enquiries = JSON.parse(this.enquiries);
    }

    this.loginService.getUser().subscribe((data: any) => {
      this.enquiries = data.enquiries;
      localStorage.setItem('enquiries', JSON.stringify(data.enquiries));
    });

  }

}
