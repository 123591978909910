<section class="navbar-section">
  <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light px-0">
          <a class="navbar-brand" routerLink="/home"><img class="logo" src="assets/images/myzeed-logo-color.png" alt="myzeed"></a>
          <div class="my-acc dropdown mob-my-acc ml-auto d-lg-none  form-inline">
            <div *ngIf="!loggedIn" class="wrapper dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="assets/images/user1.png" alt="">
             <!--  <span>Account</span> -->
          </div>

          <div *ngIf="loggedIn && user?.image" class="wrapper circle dropdown-toggle animate__animated animate__fadeInDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="profile-pic" src="{{user?.image}}" alt="user dp"><p style="color: black; background-color: blue;">{{user.image}}</p>
           <!--  <span>Account</span> -->
          </div>

              <div class="acc-menu dropdown-menu">
                  <ul>
                      <li *ngIf="!loggedIn" class="dropdown-item">
                          <a [routerLink]="['/login' , 'account' ]"><img class="grey" src="assets/images/user-1.png" alt=""><img class="green"
                                  src="assets/images/user1.png" alt=""><span>Login</span></a>
                      </li>
                      <li *ngIf="showButton " class="dropdown-item app">
                        <a (click)="addToHomeScreen()"><i class="fas fa-mobile-alt grey"></i><i class="fas fa-mobile-alt green"></i><span style="margin-left: 12px;">Install MyZeed</span></a>
                    </li>
                      <li *ngIf="loggedIn" class="dropdown-item">
                        <a routerLink="/account"><img class="grey" src="assets/images/user-1.png" alt=""><img class="green"
                                src="assets/images/user1.png" alt=""><span>My Profile</span></a>
                    </li>
                      <li *ngIf="loggedIn " class="dropdown-item">
                        <a href="#" (click)="logout($event)"><img class="grey" src="assets/images/logout.png" alt=""><img class="green"
                                src="assets/images/logout-grn.png" alt=""><span>Logout</span></a>
                    </li>

                  </ul>
              </div>
          </div>
          <button id="hide" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
              <span class="navbar-toggler-icon"></span>
              <span class="navbar-toggler-icon"></span>
          </button>
<a  style="color:#212529" href="https://wa.me/+918089624554" target="_blank" rel="noopener"><img class="whatsappMob" src="assets/images/whatsapp_color.png" alt=""></a>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                  <li class="nav-item">
                      <a class="nav-link" (click)="load()" routerLink="/institutions">Institutions</a>
                  </li>
                  <!-- <li class="nav-item">
                      <a class="nav-link" routerLink="/course">Courses</a>
                  </li> -->
                  <li class="nav-item">
                      <a class="nav-link" routerLink="/exam">Exam</a>
                  </li>
                  <li *ngIf="!loggedIn" class="nav-item">
                      <a id="log" (click)="id1 = 'loginJob'" class="nav-link findnav" href="#" [routerLink]="['/login' , 'job' ]">Find Jobs</a>
                  </li>
                  <li *ngIf="loggedIn" class="nav-item">
                    <a class="nav-link" routerLink="/job">Find Jobs</a>
                </li>
                  <!-- <li class="nav-item">
                      <a class="nav-link" routerLink="/request">Request Appointment </a>
                  </li> -->
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/scholarships">Scholarships</a>
                </li>
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/blogs">Blogs</a>
                  </li>
              </ul>
              <div class="my-acc form-inline d-none d-lg-inline" >
                  <div *ngIf="!loggedIn" class="wrapper dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src="assets/images/user1.png" alt="">
                     <!--  <span>Account</span> -->
                  </div>

                  <div *ngIf="loggedIn && user?.image" class="wrapper circle dropdown-toggle animate__animated animate__fadeInDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img class="profile-pic" src="{{user?.image}}" alt="user dp"> 
                   <!--  <span>Account</span> -->
                  </div>

                  <div class="acc-menu dropdown-menu">
                      <ul>
                          <li *ngIf="!loggedIn" class="dropdown-item">
                              <a [routerLink]="['/login' , 'account' ]"><img class="grey" src="assets/images/user-1.png" alt=""><img class="green"
                                      src="assets/images/user1.png" alt=""><span>Login</span></a>
                          </li>
                          <li *ngIf="showButton" class="dropdown-item app">
                            <a (click)="addToHomeScreen()" ><i class="fas fa-mobile-alt grey"></i><i class="fas fa-mobile-alt green"></i><span style="margin-left: 12px;">Install MyZeed</span></a>
                        </li>
                          <li *ngIf="loggedIn"  class="dropdown-item">
                            <a routerLink="/account"><img class="grey" src="assets/images/user-1.png" alt=""><img class="green"
                                    src="assets/images/user1.png" alt=""><span>My Profile</span></a>
                        </li>
                          <li *ngIf="loggedIn " class="dropdown-item">
                              <a href="#" (click)="logout($event)"><img class="grey" src="assets/images/logout.png" alt=""><img class="green"
                                      src="assets/images/logout-grn.png" alt=""><span>Logout</span></a>
                          </li>
                      </ul>
                  </div>
              </div>
<a  style="color:#212529" href="https://wa.me/+918089624554" target="_blank" rel="noopener"><img class="whatsapp" src="assets/images/whatsapp_color.png" alt=""></a>

          </div>
      </nav>
  </div>
</section>

