import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var notFound: () => void;

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {
  x: any;

  constructor(
    private router: Router
  ) {
    this.x = localStorage.getItem('connection');
    this.x = JSON.parse(this.x);
    if (this.x == 'ONLINE') {
      this.router.navigateByUrl("/home");
    }
  }

  ngOnInit(): void {
    notFound();



  }

}
