
<ngx-spinner bdColor = "rgba(35,33,33,0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" ></p></ngx-spinner>

<section class="banner-section">
    <img src="assets/images/banner-1.jpg" alt="myzeed">
  </section>

<!-- login modal -->
<div class="modal fade" id="login1" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
  <p-progressSpinner class="spinner" [hidden]="showLogin" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  
            <div class="modal-body">
                <button type="button" (click)="onCloseLogin()" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/close.png" alt="">
                </button>
  
                <h1 class="text-center">Login</h1>
  
                <form  #userLogin=ngForm (ngSubmit)="onSubmitLogin()">
  
  
  
                <div class="formWrapper">
                  <div [hidden]="!errorLogin" class="alert alert-danger">Username or Password does not match</div>
  
                    <div class="form-group">
                        <img src="assets/images/user-2.png" alt="">
                        <input type="email" name="email" class="form-control" [(ngModel)]="form.email" placeholder="User Name" required>
                    </div>
                    <div class="form-group">
                        <img src="assets/images/key.png" alt="">
                        <input [attr.type]="pwdType" name="password" class="form-control" [(ngModel)]="form.password" placeholder="Password" required>
                        <img *ngIf="pwdType == 'password'" style="left: 96% !important; width: 5% !important;" (click)="onShow()" src="assets/images/show.png" alt="">
                        <img *ngIf="pwdType == 'text'" style="left: 96% !important; width: 5% !important;" (click)="onShow()" src="assets/images/hide.png" alt="">
                    </div>
                    <div class="forgotPswd">
                     
                        <a class="frgt" data-dismiss="modal" data-toggle="modal"
                        data-target="#forgot">Forgot password?</a>
                    </div>
                    <button class="main-btn" type="submit" [disabled]="!userLogin.valid" data-toggle="modal">Login</button>
                    <p class="register">New to Myzeed,<a class="reg" data-dismiss="modal" data-toggle="modal"
                            data-target="#register1"><span class="space">Register</span></a> here</p>
                </div>
                </form>
            </div>
        </div>
    </div>
  </div>
  
  <!-- reg modal -->
  <div class="modal fade" id="register1" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
  <p-progressSpinner class="spinner" [hidden]="showReg" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  
          <div class="modal-body">
              <button type="button" (click)="onCloseRegister()" class="close" data-dismiss="modal" aria-label="Close">
                  <img src="assets/images/close.png" alt="">
              </button>
  
              <h1 class="text-center">Register</h1>
  
  
                <form  [formGroup]="userregister1">
  
              <div class="formWrapper">
                  <div class="form-group">
                      <img src="assets/images/user-2.png" alt="">
                      <input autocomplete="off" type="text" formControlName="name" class="form-control" placeholder="Enter the Name">
                  </div>
             <small class="text-danger" *ngIf="errorRegister?.name && submitted">{{errorRegister.name}}</small>
  
                  <div class="form-group">
                      <img src="assets/images/phone-1.png" alt="">
                      <input autocomplete="off" type="number" formControlName="phone"  class="form-control" placeholder="Phone Number">
                  </div>
             <small class="text-danger" *ngIf="errorRegister?.phone && submitted">{{errorRegister.phone}}</small>
  
  
                  <div class="form-group">
                      <img src="assets/images/mail-1.png" alt="">
                      <input autocomplete="off" type="text" formControlName="email"  class="form-control" placeholder="Email Address">
                  </div>
             <small class="text-danger" *ngIf="errorRegister?.email && submitted">{{errorRegister.email}}</small>
                  <div class="form-group textarea-group">
                      <img src="assets/images/bag.png" alt="">
                      <textarea autocomplete="off" class="form-control" formControlName="address"  placeholder="Address(Optional)"></textarea>
                  </div>
             <small class="text-danger" *ngIf="errorRegister?.address && submitted">{{errorRegister.address}}</small>
  
  
  
             <div class="form-group">
              <div class="space1">
                      <img class="imgl" src="assets/images/adrs.png" alt="">
  
              <p-dropdown
  
              class="dropdown"
              [options]="states"
              name="selectedState"
              placeholder="Select a State"
              optionLabel="name" [filter]="true" filterBy="name"
              resetFilterOnHide="true"
              id="state"
              formControlName="state"
              [autofocusFilter]="false"
  
  
            ></p-dropdown>
  
  
          </div>
          <small class="text-danger" *ngIf="errorRegister?.state && submitted">{{errorRegister.state}}</small>
  
          </div>
  
  
  
                <div class="form-group">
                  <img src="assets/images/key.png" alt="">
                  <input autocomplete="off" [attr.type]="pwdType" formControlName="password"  class="form-control" minlength="6" placeholder="Password">
               <img *ngIf="pwdType == 'password'" style="left: 96% !important; width: 5% !important;" (click)="onShow()" src="assets/images/show.png" alt="">
               <img *ngIf="pwdType == 'text'" style="left: 96% !important; width: 5% !important;" (click)="onShow()" src="assets/images/hide.png" alt="">
              </div>
             <small class="text-danger" *ngIf="errorRegister?.password && submitted && errorRegister?.password[0] == 'The password must be at least 8 characters.' ">{{errorRegister.password[0]}}</small>
             <small class="text-danger" *ngIf="errorRegister?.password && submitted && errorRegister?.password[0] == 'The password field is required.' ">{{errorRegister.password[0]}}</small>
  
  
              <div class="form-group">
                <img src="assets/images/key.png" alt="">
                <input autocomplete="off" [attr.type]="pwdType1" formControlName="confirm_password"  class="form-control" placeholder="Confirm Password">
             <img *ngIf="pwdType1 == 'password'" style="left: 96% !important; width: 5% !important;" (click)="onShowConfirm()" src="assets/images/show.png" alt="">
             <img *ngIf="pwdType1 == 'text'" style="left: 96% !important; width: 5% !important;" (click)="onShowConfirm()" src="assets/images/hide.png" alt="">
            </div>
            <small class="text-danger" *ngIf="errorRegister?.password && submitted && errorRegister?.password == 'The password confirmation does not match.' ">{{errorRegister.password}}</small>
            <small class="text-danger" *ngIf="errorRegister?.password && register1.confirm_password && submitted && errorRegister?.password[1] == 'The password confirmation does not match.' ">{{errorRegister.password[1]}}</small>
            <small class="invalid" *ngIf="submitted && !register1.confirm_password && validate">Enter password again</small>
            <small class="invalid" *ngIf="submitted && register1.password != register1.confirm_password && register1.confirm_password && validate">Password do not match</small>
  
            <div class="form-group">
              <i class="fas fa-users refer"></i>
              <input autocomplete="off" type="text" formControlName="refer"  class="form-control" placeholder="Have a referral code? (Optional)">
          </div>
     <small class="text-danger" *ngIf="errorRegister?.referel_id && submitted">Invalid Referral Code</small>
          </div>
  
  
  
  
  
                  <button class="main-btn" type="submit" (click)="onSubmitRegister()" data-toggle="modal"
                     >Register</button>
  
  
              </form>
          </div>
      </div>
  </div>
  </div>

  <!-- Forgot Password modal -->
<div class="modal fade" id="forgot" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
  <p-progressSpinner class="spinner" [hidden]="showLogin" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
<p-toast class="toast1" position="top-right" key="tl"></p-toast>
  
            <div class="modal-body">
                <button type="button" (click)="onCloseLogin()" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/close.png" alt="">
                </button>
  
                <h1 class="text-center frgtHead">Forgot Password</h1>
  
                <form >
  
  
  
                <div class="formWrapper">
                  <div [hidden]="!errorFP" class="alert alert-danger">Email not found</div>
  
                    <div class="form-group">
                        <img src="assets/images/user-2.png" alt="">
                        <input autofocus type="email" name="email" class="form-control" [(ngModel)]="email" placeholder="Enter Email" required>
                    </div>
    
                    <button (click)="forgotPassword()" class="main-btn" type="submit" [disabled]="email == ''" ><a >Send OTP</a></button>
                    <p class="register">New to Myzeed,<a class="reg" data-dismiss="modal" data-toggle="modal"
                            data-target="#register1"><span class="space">Register</span></a> here</p>
                </div>
                </form>
            </div>
        </div>
    </div>
  </div>

  <!-- Change Password modal -->
<div class="modal fade" id="passwordChange" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
  <p-progressSpinner class="spinner" [hidden]="showLogin" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
  
            <div class="modal-body">
                <button type="button" (click)="onCloseLogin()" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/close.png" alt="">
                </button>
  
                <h1 class="text-center frgtHead">Change Password</h1>
  
                <form  #userPassword=ngForm (ngSubmit)="changePassword()">
  
  
  
                <div class="formWrapper">
  
                  <div class="form-group">
                    <img src="assets/images/key.png" alt="">
                    <input [attr.type]="pwdType" name="password" class="form-control" [(ngModel)]="password.oPassword" placeholder="Password" required>
                    <img *ngIf="pwdType == 'password'" style="left: 96% !important; width: 5% !important;" (click)="onShow()" src="assets/images/show.png" alt="">
                    <img *ngIf="pwdType == 'text'" style="left: 96% !important; width: 5% !important;" (click)="onShow()" src="assets/images/hide.png" alt="">
                    <small class="text-danger" *ngIf="errorCP?.password && submitted1 && errorCP?.password[0] == 'The password must be at least 8 characters.' ">{{errorCP.password[0]}}</small>
                    <small class="text-danger" *ngIf="errorCP?.password && submitted1 && errorCP?.password[0] == 'The password field is required.' ">{{errorCP.password[0]}}</small>
                </div>
    
    
               <div class="form-group">
                <img src="assets/images/key.png" alt="">
                <input [attr.type]="pwdType1" name="password" class="form-control" [(ngModel)]="password.cPassword" placeholder="Confirm Password" required>
                <img *ngIf="pwdType1 == 'password'" style="left: 96% !important; width: 5% !important;" (click)="onShowConfirm()" src="assets/images/show.png" alt="">
                <img *ngIf="pwdType1 == 'text'" style="left: 96% !important; width: 5% !important;" (click)="onShowConfirm()" src="assets/images/hide.png" alt="">
                <small class="text-danger" *ngIf="errorCP?.password && submitted1 && errorCP?.password == 'The password confirmation does not match.' ">{{errorCP.password}}</small>
                <small class="text-danger" *ngIf="errorCP?.password && password.cPassword && submitted1 && errorCP?.password[1] == 'The password confirmation does not match.' ">{{errorCP.password[1]}}</small>
                <small class="invalid" *ngIf="submitted1 && !password.cPassword ">Enter password again</small>
                <small class="invalid" *ngIf="submitted1 && password.oPassword != password.cPassword && password.cPassword">Password do not match</small>
            </div>
             
    
                    <button class="main-btn" type="submit" [disabled]="!userPassword.valid" ><span style="font-size: 14px;">Change Password</span></button>
                
                </div>
                </form>
            </div>
        </div>
    </div>
  </div>

  <!-- otp modal -->
<div class="modal fade" id="otp" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
<p-toast class="toast1" position="top-right" key="tl"></p-toast>

        <div class="modal-content">
            <div class="modal-body">
                <button type="button" (click)="onCloseLogin()" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="assets/images/close.png" alt="">
                </button>
    
                <h1 class="text-center frgtHead">Verify OTP</h1>
    <h4 style="font-size: 12px;text-align: center;">Please enter the OTP received in {{email}}</h4>
                <div class="formWrapper">
                  <div [hidden]="!errorOTP" class="alert alert-danger">Invalid OTP</div>

                    <div class="otpWrapper">
                        <div class="form-group">
                            <input autofocus oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                             (keypress)="numberOnly($event)" maxlength="6"  [(ngModel)]="otp6"  type="number" class="form-control" >


                        </div> 
                       
                    </div>
                    <a (click)="forgotPassword()" style="cursor: pointer;" class="resend">Resend OTP</a>
                    <button (click)="verifyOTP()" [disabled]="otp6 == ''" class="main-btn"><a >Verify OTP</a></button>
                </div>
            </div>
        </div>
    </div>
    </div>
