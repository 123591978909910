import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blogs-content',
  templateUrl: './blogs-content.component.html',
  styleUrls: ['./blogs-content.component.scss']
})
export class BlogsContentComponent implements OnInit {

  blogs: any;
  blog: any;

  constructor(private blogService: BlogService) { }

  ngOnInit(): void {

    if (localStorage.getItem('blogs')) {
      this.blogs = localStorage.getItem('blogs');
      this.blogs = JSON.parse(this.blogs);
      for (let i = 0; i < this.blogs.length; i++) {
        this.blogs[i].description = this.blogs[i].description.replace(/(<([^>]+)>)/gi, "");
      }
    }

    this.blogService.getBlog().subscribe((data: any) => {
      this.blogs = data.Data;
      for (let i = 0; i < this.blogs.length; i++) {
        this.blogs[i].description = this.blogs[i].description.replace(/(<([^>]+)>)/gi, "");
      }
      localStorage.setItem('blogs', JSON.stringify(data.Data));

    });
  }

}
