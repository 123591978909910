import { Component, OnInit } from '@angular/core';
import * as Aos from 'aos';
import { FeaturedCollegeService } from 'src/app/services/featuredCollege.service';

@Component({
  selector: 'app-browse-city',
  templateUrl: './browse-city.component.html',
  styleUrls: ['./browse-city.component.scss']
})
export class BrowseCityComponent implements OnInit {

  cities: any;
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number; }[];
  constructor(private featuredCollegeService: FeaturedCollegeService) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {

    if (localStorage.getItem('citiesHome')) {
      this.cities = localStorage.getItem('citiesHome');
      this.cities = JSON.parse(this.cities);
    }

    this.featuredCollegeService.getCollege().subscribe((data: any) => {
      this.cities = data.data.top_cities;
      localStorage.setItem('citiesHome', JSON.stringify(data.data.top_cities));

    });

    /*   var a = 0;
      $(window).on('scroll', function () {
        var oTop1 = $('#city').offset()!.top - window.innerHeight;
        if (a == 0 && $(window).scrollTop()! > oTop1) {
          Aos.init();

        }
      }); */
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

}
