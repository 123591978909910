<section class="topCources-section">
  <div class="container">
      <div  class="topCources">
          <h1 id="course" data-aos="fade-up"  class="main-heading text-center">Top Courses</h1>
          <p data-aos="fade-up"   class="heading-para text-center"> Browse top courses listed based on importance, and to know more drop in your contact information to   receive further details.</p>
<div class="crse">
          <p-carousel [value]="courses" styleClass="custom-carousel" [numVisible]="4" [numScroll]="4" [circular]="true" [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">

            <ng-template let-course pTemplate="item">
              <div class="col-12" >
                <a [routerLink]="['/findinstitutions-course' , course.id ]">
                  <div class="course">
                      <div class="iconWraper">
                          <img class="whiteIcon" src="{{course.icon}}" alt="">
                      </div>
                      <p>{{course.name|titlecase}}</p>
                  </div>
              </a>
            </div>
            </ng-template>
        </p-carousel>
      </div>
          <ul class="crse1" data-aos="fade-up" data-aos-duration="1500">

           <li *ngFor="let course of courses">
              <a [routerLink]="['/findinstitutions-course' , course.id ]">
                <div class="course">
                    <div class="iconWraper">
                        <img class="greenIcon" src="{{course.icon}}" alt="">
                        <img class="whiteIcon" src="{{course.icon}}" alt="">
                    </div>
                    <p>{{course.name|titlecase}}</p>
                </div>
            </a>

            </li>
          </ul>
      </div>
  </div>
</section>
