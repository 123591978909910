<div class="main-content ">
 <!--  <div class="searchCource">
      <img src="assets/images/search.png" alt="">
      <input autocomplete="false" name="jdjf" type="text" placeholder="Courses that are looking for....">
      <button class="main-btn">Courses</button>
  </div> -->
  <h1 class="head">Courses & Institutions</h1>
  <div class="sideBar-menu">
      <span></span>
      <span></span>
      <span></span>
  </div>

  <div class="cources-collages">
      <table class="table-1">
          <tbody *ngIf="enquiries != ''">
              <tr>
                  <th>Course</th>
                  <th>Institution</th>
                  <th>Status</th>
              </tr>

            <tr *ngFor="let enquiry of enquiries">
                  <td>{{enquiry?.course}}</td>
                  <td>{{enquiry?.college}}</td>

                  <td *ngIf="enquiry?.status == 'Accepted'"><p-tag styleClass="p-mr-2" severity="success" value="{{enquiry?.status}}" [rounded]="true"></p-tag></td>
                  <td *ngIf="enquiry?.status == 'Verified'"><p-tag styleClass="p-mr-2" severity="info" value="{{enquiry?.status}}" [rounded]="true"></p-tag></td>
                  <td *ngIf="enquiry?.status == 'In Progress'"><p-tag class="progressTag" styleClass="p-mr-2" severity="info" value="{{enquiry?.status}}" [rounded]="true"></p-tag></td>
                  <td *ngIf="enquiry?.status == 'Pending'"><p-tag styleClass="p-mr-2" severity="warning" value="{{enquiry?.status}}" [rounded]="true"></p-tag></td>
                  <td *ngIf="enquiry?.status == 'Rejected'"><p-tag severity="danger" value="{{enquiry?.status}}" [rounded]="true"></p-tag></td>

              </tr>


          </tbody>
      </table>
      <div *ngIf="enquiries == ''">

      <img src="assets/images/nodata.jpg" alt="">

      </div>
      <p *ngIf="enquiries == ''" class="emptyCourse">No Courses Registered</p>

  </div>
</div>
