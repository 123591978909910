import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-college',
  templateUrl: './about-college.component.html',
  styleUrls: ['./about-college.component.scss']
})
export class AboutCollegeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
