import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CoursesService } from 'src/app/services/courses.service';

@Component({
  selector: 'app-browse-course',
  templateUrl: './browse-course.component.html',
  styleUrls: ['./browse-course.component.scss']
})
export class BrowseCourseComponent implements OnInit {

  id: any;
  colleges: any;
  courses: any;
  course: any;
  loading: boolean = true;
  name: any;
  show: boolean = false;
  show1: boolean = false;
  private subscriptions: Subscription[] = [];
  colleges1: any;
  courses1: any;
  coursesLocal: any;

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef, private courseService: CoursesService) {
    route.params.subscribe(val => {
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
      });

      setTimeout(
        () => {
          this.show1 = true;

        }, 5000);

      if (localStorage.getItem('courses')) {
        this.coursesLocal = localStorage.getItem('courses');
        this.courses = JSON.parse(this.coursesLocal);
        this.courses1 = JSON.parse(this.coursesLocal);
        this.show1 = true;
      }
      const loadSubscriptionCourse = this.courseService.getCourseCategory().subscribe((data: any) => {
        this.courses = data;
        localStorage.setItem('courses', JSON.stringify(data));
        this.courses1 = data;
        this.cdr.detectChanges();
        this.show1 = true;

      });
      this.subscriptions.push(loadSubscriptionCourse);


      this.courseService.getAllColleges(this.id).subscribe((data: any) => {
        this.colleges = data;
        this.loading = false;
        this.course = null;
        this.course = this.colleges[0].course;
      });
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    setTimeout(
      () => {
        this.show1 = true;

      }, 5000);

    if (localStorage.getItem('courses')) {
      this.coursesLocal = localStorage.getItem('courses');
      this.courses = JSON.parse(this.coursesLocal);
      this.courses1 = JSON.parse(this.coursesLocal);
      this.show1 = true;
    }
    const loadSubscriptionCourse = this.courseService.getCourseCategory().subscribe((data: any) => {
      this.courses = data;
      localStorage.setItem('courses', JSON.stringify(data));
      this.courses1 = data;
      this.cdr.detectChanges();
      this.show1 = true;

    });
    this.subscriptions.push(loadSubscriptionCourse);


    this.courseService.getAllColleges(this.id).subscribe((data: any) => {
      this.colleges = data;
      this.loading = false;
      this.course = null;
      this.course = this.colleges[0].course;
    });
  }

  change() {
    this.name = "";
    this.course = "";
    this.show = false;
    this.loading = true;
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  search() {
    if (this.name == "") {
      this.courses = this.courses1;
      this.show = false;
    }
    else {
      this.courses = this.courses1;
      this.show = true;
      this.courses = this.courses.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase());
      })
    }
  }
}
