<section class="collegeImage-section collegevdos-section">
    <div class="container ">
        <div *ngIf="collegeImage != 0" class="collegeImagewrapper">
            <h1 class="secondaryHeading ">College Videos</h1>
        <!--     <a class="viewAll" href="">View All</a> -->
            <ul class="images">
                <li *ngFor="let video of collegeVideos"><a data-fancybox="gallery-3" [href]="video.video_url"><img [src]="video.image_url"></a></li>
            </ul>
        </div>
        <div *ngIf="collegeStudentImage != 0" class="collegeImagewrapper">
            <h1 class="secondaryHeading ">Students Videos</h1>
           <!--  <a class="viewAll" href="">View All</a> -->
            <ul class="images">
              <li *ngFor="let video of studentVideos"><a data-fancybox="gallery-4" [href]="video.video_url"><img [src]="video.image_url"></a></li>

            </ul>
        </div>

        <div style="text-align: center;" *ngIf="collegeImage == 0 && collegeStudentImage == 0">
      <img class="noImg" src="assets/images/nodata.jpg" alt="">
          <p>No Videos found</p>
        </div>
    </div>
</section>
