
 <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-running-dots" [fullScreen] = "true"></ngx-spinner>
<div class="filterOoverlay"></div>
<section class="education-section">
  <div class="container">
    <app-education-ad></app-education-ad>
      <!-- <h1 class="main-heading"> Institutions</h1> -->
      <div class="menu-list">
          <div id="type" class="swiper-container educationSwiper-container">
              <div  class="swiper-wrapper">
                  <div id="myDIV" *ngFor="let type of types" class=" swiper-slide eduSwiper">
                    <a class="story" (click)="searchType(type.id)" (click)="scroll1()">
                      <div class="contentWrapper">
                          <div class="icon">
                              <img [src]="type.icon" alt="">
                          </div>
                          <div class="detail">
                              <h6>{{type.name}}</h6>
                              <h5>{{type.college_count}} {{type.name}}</h5>
                          </div>
                      </div>
                    </a>
                    <p class="heading-para">{{type.desciption}}</p>
                  </div>
              </div>
          </div>
      </div>


       <div class="advanceFilterWrapper">

          <img class="advanceFilter" src="assets/images/filter.png" alt="">
      </div>
<div >
     <div id="target"  class="row big-row">
          <div class="col-lg-4 big-col">
              <div class="advance-search-wrapper">
                  <p class="searchHeading">Advanced Search</p>
                  <button class="close"><img src="assets/images/white-close.png" alt=""></button>
                  <div class="advanced-oveflow">
                    <div class="advance-search" >
                      <p class="sectioHeading">Select Course</p>
                      <div class="searchWrapper">
                        <span class="p-input-icon-right p-ml-auto">
                          <i class="pi pi-search"></i>

                  </span>
                  <input type="text"  class="pl-3" name="courseSearch" [(ngModel)]="courseSearch"  (keyup)="searchCourseList()" autocomplete="off" placeholder="Search Course ">


                      </div>
                      <div class=" selectCourse">

                        <div class="custom-control custom-checkbox">

                        <cdk-virtual-scroll-viewport [hidden]="activeCourse" itemSize="40" class="example-viewport">
                          <div *cdkVirtualFor="let catCourse of catCourses" >
                            <p-checkbox class="custLabel" name="course1" [value]="catCourse" (click)="scroll()" label="{{catCourse.name|titlecase}}" [(ngModel)]="selectedCourse" (onChange)="filter()" [inputId]="catCourse.course_id" ></p-checkbox>
                          </div>
                        </cdk-virtual-scroll-viewport>

                        <div class="empty" [hidden]="!activeCourse">
                          <h6 style="color: black;">Oops... No Course Found</h6>
                        <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
                        </div>
                      </div>
                        </div>


                      <hr>
                  </div>

                  <div class="advance-search">
                      <p class="sectioHeading">Select Institutions</p>
                      <div class="searchWrapper">
                        <span class="p-input-icon-right p-ml-auto">
                          <i class="pi pi-search"></i>

                  </span>

                    <input type="text"  class="pl-3" name="field" [(ngModel)]="field"  (keyup)="search()" autocomplete="off" placeholder="Search Institutions ">

                      </div>
                      <div class="advanceList">
                        <div class="custom-control custom-checkbox">

                          <cdk-virtual-scroll-viewport [hidden]="activeName" itemSize="40" class="example-viewport">
                            <div *cdkVirtualFor="let college of searchColleges" >
                              <p-checkbox class="custLabel" name="college" [value]="college.id" label="{{college.name|titlecase}}" [(ngModel)]="selectedCollege" (click)="scroll()" (onChange)="filter()" [inputId]="college.id" ></p-checkbox>    
                        </div>
                          </cdk-virtual-scroll-viewport>
                      <div class="empty" [hidden]="!activeName">
                        <h6 style="color: black;">Oops... No Institutions Found</h6>
                      <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
                      </div>
                    </div>


                      </div>
                      <hr>
                  </div>
                  <div class="advance-search">
                      <p class="sectioHeading">Select State</p>
                      <div class="searchWrapper">
                        <span class="p-input-icon-right p-ml-auto">
                          <i class="pi pi-search"></i>

                  </span>
                  <input type="text"  class="pl-3" name="stateSearch" [(ngModel)]="stateSearch"  (keyup)="searchStateList()" autocomplete="off" placeholder="Search State ">

                      </div>
                      <div class="advanceList ">
                          <div class="custom-control custom-checkbox">
                            <div *ngFor="let state of states" class="p-field-checkbox">
                              <p-checkbox name="state" [value]="state.id" [(ngModel)]="selectedState" (click)="scroll()" (onChange)="filter()" [inputId]="state.id" ></p-checkbox>
                              <label class="custLabel" [for]="state.id">{{state.name}}</label>
                          </div>
                          <div class="empty" [hidden]="!activeState">
                            <h6 style="color: black;">Oops... No State Found</h6>
                          <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
                          </div>

                          </div>

                      </div>
                      <hr>
                  </div>
                  <div class="advance-search">
                      <p class="sectioHeading">Select City</p>
                      <div class="searchWrapper">
                        <span class="p-input-icon-right p-ml-auto">
                          <i class="pi pi-search"></i>

                  </span>
                        <input type="text" class="pl-3" name="citySearch"  [(ngModel)]="citySearch"  (keyup)="searchCityList()" autocomplete="off" placeholder="Search City ">

                      </div>
                      <div class="advanceList ">
                  <div class="custom-control custom-checkbox">

                        <div *ngFor="let city of cities" class="p-field-checkbox">
                          <p-checkbox name="city" [value]="city.id" [(ngModel)]="selectedCity" (click)="scroll()" (onChange)="filter()" [inputId]="city.id" ></p-checkbox>
                          <label class="custLabel" [for]="city.id">{{city.name|titlecase}}</label>
                      </div>
                      <div class="empty" [hidden]="!activeCity">
                        <h6 style="color: black;">Oops... City not Found</h6>
                      <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
                      </div>
</div>
                      </div>
                      <hr>
                  </div>
                </div>
              </div>

          </div>


<div class="pos col-lg-8">
  <div id="colg" class="card">
    <div class="empty" [hidden]="!active">
      <h6 style="color: black;">Oops... No Institutions Found</h6>
    <img class="emptyImg animate__animated animate__fadeInUp" src="assets/images/broke.png">
    </div>
    <p-dataView [hidden]="active" #dv [value]="colleges" [paginator]="true" [rows]="8" filterBy="name" (onPage)="scroll()" [totalRecords]="totalRecords"
         layout="grid">
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <span [hidden]="true" class="p-input-icon-right p-mb-2 p-mb-md-0">
                    <i class="pi pi-search"></i>
                    <input [hidden]="true" type="search" class="place" pInputText placeholder="Search by College" (input)="dv.filter($event.target.value)">
                </span>

            </div>
        </ng-template>



        <ng-template let-college pTemplate="gridItem">
          <div  class="p-col-12 p-md-6">
          <div class="row">

            <div class="col-lg-12 col-md-12">
              <div class="college-wrapper">
                <a class="txt" [routerLink]="['/about-institutions' , college.id ]">
                  <div class="img-wrapper">
                      <img loading="lazy" src="{{college.cover_pic}}" alt="">
                  </div>
                  <div class="star-rate">
                      <img src="assets/images/star.png" alt="">
                      <span>{{college.rating}}</span>
                  </div>
                  <div class="details">
                      <div class="colege-name">
                          <div class="iconWrapper">
                              <img loading="lazy" src="{{college.logo}}" alt="">
                          </div>
                          <div *ngIf="college.state" class="name-location">
                              <h4>{{college.name}}</h4>
                              <h3><img src="assets/images/location.png" alt=""><span>{{college.place}}, {{college.state}}</span></h3>

                          </div>
                          <div *ngIf="!college.state" class="name-location">
                            <h4>{{college.name}}</h4>
                            <h3><img src="assets/images/location.png" alt=""><span>{{college.place}}</span></h3>

                        </div>
                      </div>
                      <ul class="feeList">
                          <li>
                              <h6>₹ {{college.min_fee}}</h6>
                              <p> Minimum Fees</p>

                          </li>
                          <li>
                              <h6>₹ {{college.max_fee}}</h6>
                              <p> Maximum Fees</p>


                          </li>
                      </ul>

                      <a  [routerLink]="['/about-institutions' , college.id ]"><button  class="apply-now"><img src="assets/images/green-tik.png" alt=""><span>Apply
                        Now</span></button></a>
                  </div>
                </a>
              </div>

          </div>
          </div>
</div>
</ng-template>

    </p-dataView>
</div>



</div>


</div>
</div>
  </div>
</section>

