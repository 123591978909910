<section class="latestJob-list-section">
  <div class="container">
      <div class="latestJob-list">
        <h1 class="small ">Latest Jobs</h1>


          <div id="target" class="card large">

            <p-table #dt1 [value]="jobs" dataKey="id" [loading]="loading" (onPage)="scroll()"
                [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"  styleClass="p-datatable-customers p-datatable-gridlines"
                [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [globalFilterFields]="['company','place','position','type_of_job']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
          <h1 class="main-heading">Latest Jobs</h1>

                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="place" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search Jobs, Full-Time, Part-Time" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="size">
                            <div>
                                Company Name

                            </div>
                        </th>
                        <th>
                            <div class="centerHeader size">
                                Place
                            </div>
                        </th>
                        <th>
                          <div class="centerHeader size">
                            Position
                        </div>
                        </th>
                        <th>
                            <div class="centerHeader size">
                                Last Date to Apply
                            </div>
                        </th>
                        <th pSortableColumn="type_of_job">
                            <div class="centerHeader size">

                                Type of Job

                                <p-sortIcon field="type_of_job"></p-sortIcon>
                            </div>

                           </th>

                           <th>
                            <div class="centerHeader size">
                                Contact
                            </div>
                        </th>

                        <th></th>


                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-job>
                    <tr>
                        <td>
                          <div class="companytext">

                          <img class="logo" [src]="job.company_logo" alt="company_logo" width="30">
                          <span class="image-text"> {{job.company}}</span>
                           <!--  <img src="{{job.company_logo}}" alt="company_logo"> -->
                          </div>
                        </td>
                        <td class="center">
                          {{job.place}}

                        </td>
                        <td class="center">
                          {{job.position}}

                        </td>
                        <td class="center">
                            {{job.deadline | date : 'dd-MM-YYYY'}}
                        </td>
                        <td class="center">
                          {{job.type_of_job}}

                        </td>

                        <td class="center">
                          <a *ngIf="job.email != 'Not Specified'" pTooltip="{{job.email}}" tooltipPosition="bottom" style="color: #363836;" href="mailto:{{job.email}}"> <i style="margin-right: 10px;" class="fas fa-envelope"></i></a>
                          <a *ngIf="job.phone != 'Not Specified'" pTooltip="{{job.phone}}" tooltipPosition="bottom" style="color: #363836;" href="tel:{{job.phone}}"> <i class="fas fa-mobile"></i></a>
                          <p *ngIf="job.phone == 'Not Specified' && job.email == 'Not Specified' ">Not Specified</p>
                        </td>

                        <td class="center">
      <a href="{{job.url}}" target="_blank" rel="noopener" > <button type="button"  class="main-btn"><span class="appnow">Apply Now </span></button></a>

                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No Jobs found.</td>
                    </tr>
                </ng-template>
            </p-table>
          </div>




          <div id="type" class="card small">


          <p-table #dt2 [value]="jobsMobile" dataKey="id" [loading]="loading" (onPage)="scroll1()"
          [rows]="10"  [rowsPerPageOptions]="[10,25,50]"  styleClass="p-datatable-customers p-datatable-gridlines"
          [paginator]="true"
          [globalFilterFields]="['company','place','position','type_of_job']">
          <ng-template pTemplate="caption">
              <div class="p-d-flex">
    <h1 class="large main-heading">Latest Jobs</h1>

                  <span class="p-input-icon-right">
                      <i class="pi pi-search searchIcon"></i>
                      <input pInputText type="text" class="place" (input)="dt2.filterGlobal($event.target.value, 'contains')" placeholder="Search Jobs, Full-Time, Part-Time" />
                  </span>
              </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr class="bold">
                <th style="width: 1px"></th>
                  <th>
                      <div class="centerHeader">
                          Company Name

                      </div>
                  </th>
                  <th>
                      <div class="centerHeader ">
                          Place
                      </div>
                  </th>
                  <th>
                    <div class="centerHeader">
                      Position
                  </div>
                  </th>

                  <th>
                    <div class="centerHeader">
                        Contact
                    </div>
                </th>



              </tr>
          </ng-template>
  <ng-template pTemplate="body" let-job let-expanded="expanded">
      <tr class="bold" >

          <td [pRowToggler]="job">
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain ex" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        </td>
        <td [pRowToggler]="job" style="text-align: center !important;">

          <img class="large " [src]="job.company_logo" alt="company_logo" width="30">
          <span class="center image-text fontChange"> {{job.company}}</span>
           <!--  <img src="{{job.company_logo}}" alt="company_logo"> -->

        </td>
        <td [pRowToggler]="job" class="center fontChange">
          {{job.place}}

        </td>
        <td [pRowToggler]="job" class="center fontChange">
          {{job.position}}

        </td>

        <td class="center fontChange">
          <a *ngIf="job.email != 'Not Specified'" style="color: #363836;" href="mailto:{{job.email}}"> <i style="margin-right: 10px;" class="fas fa-envelope"></i></a>
          <a *ngIf="job.phone != 'Not Specified'" style="color: #363836;" href="tel:{{job.phone}}"> <i class="fas fa-mobile"></i></a>
          <p [pRowToggler]="job" *ngIf="job.phone == 'Not Specified' && job.email == 'Not Specified' ">Not Specified</p>
        </td>


      </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-job>
      <tr class="bold">
          <td colspan="7">
              <div class="">
                  <p-table [value]="job.details" dataKey="id">
                      <ng-template pTemplate="header">
                          <tr>
                            <th>
                              <div class="centerHeader">
                                 Last Date to Apply
                              </div>
                          </th>
                          <th>
                              <div class="centerHeader">
                                  Type of Job
                              </div>
                          </th>
                              <th ></th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-detail>
                          <tr class="bold">
                            <td class="center fontChange">
                              {{detail.deadline | date : 'dd-MM-YYYY'}}
                          </td>
                          <td class="center fontChange">
                            {{detail.type_of_job}}

                          </td>
                          <td class="center fontChange">
                            <a href="{{detail.url}}" target="_blank" rel="noopener" > <button type="button"  class="main-btn">Apply Now</button></a>

                                              </td>
                              <!-- <td><p-button type="button" icon="pi pi-search"></p-button></td> -->
                          </tr>
                      </ng-template>
                     
                  </p-table>
              </div>
          </td>
      </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
        <td colspan="7">No Jobs found.</td>
    </tr>
</ng-template>
</p-table>
          </div>


      </div>

  </div>
</section>







