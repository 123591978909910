import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  user: any;
  constructor(private auth: AuthService, private loginService: LoginService, private toastr: ToastrService, private token: TokenService, private router: Router) { }


  ngOnInit(): void {


    if (localStorage.getItem('myProfile')) {
      this.user = localStorage.getItem('myProfile');
      this.user = JSON.parse(this.user);

    }

    this.loginService.getUser().subscribe((data: any) => {
      this.user = data;
      localStorage.setItem('myProfile', JSON.stringify(data));
    });
  }

  showLogout() {
    this.toastr.show('Signed Out! See you soon');
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe(
      error => error,
      data => data
    );
    this.token.remove();
    localStorage.removeItem('myProfile');
    this.showLogout();
    this.auth.changeAuthStatus(false);
    this.router.navigateByUrl('/home');
  }

}
