import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../app.service';
import { AccountService } from '../services/account.service';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../services/login.service';
import { TokenService } from '../services/token.service';



@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  token: any;
  user: any;
  show: boolean = false;
  userData: any;
  error: any;
  img: any;

  constructor(private tokenService: TokenService,
    private spinner: NgxSpinnerService,
    private appService: AppService, private auth: AuthService, private router: Router, private accountService: AccountService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.token = this.tokenService.get();

    if (localStorage.getItem('myProfile')) {
      this.user = localStorage.getItem('myProfile');
      this.user = JSON.parse(this.user);
      this.img = this.user.image;
      this.appService.addImage1(this.img);
      this.spinner.hide();

    }

    this.loginService.getUser().subscribe((data: any) => {
      this.user = data;
      this.img = this.user.image;
      this.appService.addImage1(this.img);
      this.show = true;
      localStorage.setItem('myProfile', JSON.stringify(data));
      this.spinner.hide();
    });
    this.tokenExpiry();



  }


  edit() {
    this.accountService.sendUser(this.user);
  }

  tokenExpiry() {
    this.loginService.getUser2().subscribe(
      error => this.handleError(error),
      data => data
    );
  }

  handleError(error: any) {
    this.error = error.message;
    if (this.error == 'token expired') {
      this.logout();
    }
  }

  logout() {
    this.tokenService.remove();
    localStorage.removeItem('myProfile');
    this.auth.changeAuthStatus(false);
    this.router.navigateByUrl('/home');
  }

}
