import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})
export class TokenService {

  private iss = {
    login: API_ENDPOINTS.baseUrl.url + 'api/user/login',
    register: API_ENDPOINTS.baseUrl.url + 'api/user/register'
  }
  constructor() {

  }

  handle(token: any) {
    this.set(token);
  }

  set(token: any) {
    localStorage.setItem('MyzeedToken', token);
  }

  get() {
    return localStorage.getItem('MyzeedToken');
  }

  remove() {
    localStorage.removeItem('MyzeedToken');
  }

  isValid() {
    const token = this.get();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      }
      return true;
    }
    else {
      return false;
    }
  }


  /*   isValid() {
      const token = this.get();
      if (token) {
        return true;
      }
      else {
        return false;
      }
    } */

  payload(token: any) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload: any) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    const x = this.isValid();
    return x;
  }
}
