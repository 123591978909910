import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private http: HttpClient) {

  }


  appointment(request: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/appointment";
    const body = {
      name: request.name,
      email: request.email,
      message: request.message,
      phone: request.phone,
      option: request.option,
      //tnc: request.tnc
    }

    return this.http.post(url, body);

  }

  contact(request: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/contact";
    const body = {
      name: request.name,
      email: request.email,
      message: request.message,
      phone: request.phone
    }

    return this.http.post(url, body);

  }
}
