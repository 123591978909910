<section  class="achievements-section">
  <div class="container">
      <div class="row">
          <div class="head col-md-6">
              <h1 data-aos="fade-up"  class="main-heading text-left">Our Achievements</h1>
              <p data-aos="fade-up"  class="loc">Together, We Achieve the most</p>
          </div>
          <div  class="col-md-6">
              <ul id="counter">
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/1.png" alt="">
                      </div>

                      <div class="counter">
                          <div class="counterWrapper">
                               <p data-count="5000" class="counterNumber">0</p><span>+</span>
                           <!--   <p>100</p><span>+</span> -->

                          </div>
                          <span>Courses We have</span>
                      </div>

                  </li>
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/2.png" alt="">
                      </div>
                      <div class="counter">
                          <div class="counterWrapper">
                              <p data-count="1000" class="counterNumber">0</p><span>+</span>
                        <!--   <p>50</p><span>+</span> -->

                          </div>
                          <span>Colleges</span>
                      </div>

                  </li>
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/3.png" alt="">
                      </div>
                      <div class="counter">
                          <div class="counterWrapper">
                              <p data-count="20" class="counterNumber">0</p><span>+</span>
                          <!--   <p>100</p><span>+</span> -->

                          </div>
                          <span>Countries We have</span>
                      </div>

                  </li>
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/4.png" alt="">
                      </div>
                      <div class="counter">
                          <div class="counterWrapper">
                              <p data-count="5" class="counterNumber">0</p><span>+</span>
                            <!--  <p>10</p><span>+</span> -->
                          </div>
                          <span>Years of Experience</span>
                      </div>

                  </li>
              </ul>
          </div>
      </div>
  </div>
</section>
