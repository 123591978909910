import { Component, OnInit } from '@angular/core';

import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { FeaturedCollegeService } from '../services/featuredCollege.service';
import { AppService } from '../app.service';

declare var ExternalJsFile: () => void;
declare var $: any;

const CACHE_KEY = 'dp';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loggedIn!: boolean;
  dismiss: any;
  userRegister: any;
  userLogin: any;
  login: any;
  show: boolean = false;
  register: any;
  pwdType: string = "password";
  pwdType1: string = "password";
  validate: boolean = false;
  submitted: boolean = false;
  input: boolean = false;
  errorLogin: any = null;
  errorRegister: any = null;
  states: any;
  showLogin: boolean = true;
  showReg: boolean = true;
  user: any;
  image: any;
  id: any;
  id1: any;
  deferredPrompt: any;
  showButton: boolean = false;

  form = {
    email: null,
    password: null
  };
  reg: any;

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          this.appService.sendPwaHome1();
        }
        this.deferredPrompt = '';
      });
  }



  constructor(private fb: FormBuilder, private route: ActivatedRoute, private appService: AppService, private featuredCollegeService: FeaturedCollegeService, private auth: AuthService, private token: TokenService, private loginService: LoginService, private toastr: ToastrService, private router: Router) {

    this.auth.authStatus.subscribe(value => this.loggedIn = value);

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt   
      e.preventDefault();
      // Stash the event so it can be triggered later. 
      this.deferredPrompt = e;
      this.appService.upload(e);
      this.showButton = true;
      this.appService.sendPwaHome();
    });


    this.appService.getClickEvent().subscribe(() => {
      this.ngOnInit();
    });

    this.appService.getPwaHome2().subscribe(() => {
      this.showButton = false;
    });

  }

  ngOnInit(): void {
    this.auth.authStatus.subscribe(value => this.loggedIn = value);
    this.buildLoginForm();
    this.buildRegisterForm();

    this.route.paramMap.subscribe(params => {
      if (this.route.snapshot.url[0]) {
        this.id = this.route.snapshot.url[0].path;
      }
    });

    this.featuredCollegeService.getStates().subscribe((data: any) => {
      this.states = data;
    });

    if (this.loggedIn) {
      if (localStorage.getItem('myProfile')) {
        this.user = localStorage.getItem('myProfile');
        this.user = JSON.parse(this.user);
      }

      this.loginService.getUser().subscribe((data: any) => {
        this.user = data;
        localStorage.setItem('myProfile', JSON.stringify(data));
      });
    }

  }


  buildLoginForm() {
    this.userLogin = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }


  buildRegisterForm() {
    this.userRegister = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      address: [''],
      state: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      refer: ['']
    });
  }

  showSuccessReg() {
    this.toastr.show('User Registered successfully!');
  }

  showSuccessLogin() {
    this.toastr.show('Welcome to MyZeed!');
  }

  load() {
    this.show = true;
    setTimeout(
      () => {
        this.show = false;
      }, 2000);
  }

  onShow() {
    if (this.pwdType === 'password') {
      this.pwdType = 'text';
    } else {
      this.pwdType = 'password';
    }
  }

  onShowConfirm() {
    if (this.pwdType1 === 'password') {
      this.pwdType1 = 'text';
    } else {
      this.pwdType1 = 'password';
    }
  }

  onCloseLogin() {
    if (this.id == 'login') {
      this.router.navigateByUrl('/home');
    }
    this.userLogin.reset();
    this.submitted = false;
    $("#login").modal("hide");
  }

  onCloseRegister() {
    if (this.id == 'login') {
      this.router.navigateByUrl('/home');
    }
    this.userRegister.reset();
    this.submitted = false;
    $("#register").modal("hide");
  }

  onSubmitLogin() {
    this.errorLogin = '';
    this.showLogin = false;
    this.login = this.form;
    this.loginService.userLogin(this.login).subscribe(
      data => this.handleResponse(data),
      error => this.handleErrorLogin(error),
    );

  }

  onSubmitRegister() {
    this.showReg = false;
    this.submitted = true;
    this.register = this.userRegister.value;
    this.loginService.userRegister(this.register).subscribe((data: any) => {
      this.reg = data;
      if (this.reg.message == "Success!") {
        this.showSuccessReg();
        $("#register").modal("hide");
        this.showReg = true;
        $("#login").modal("show");
      }
      else {
        console.log("errory")
        this.handleErrorReg(data);
      }
    });

  }

  showLogout() {
    this.toastr.show('Signed Out! See you soon');
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout().subscribe(
      error => error,
      data => data
    );
    this.token.remove();
    localStorage.removeItem('myProfile');
    this.showLogout();
    this.auth.changeAuthStatus(false);
    this.router.navigateByUrl('/home');
    $(".dropdown-item").on("click", function () {
      $(".dropdown-menu").removeClass('show');
    });
  }

  handleResponse(data: any) {
    if (data.message == 'invalid credentials') {
      this.handleErrorLogin(data);
    }
    else {
      this.auth.changeAuthStatus(true);
      this.token.handle(data.accessToken);
      $("#login").modal("hide");
      this.showLogin = true;
      this.showSuccessLogin();
      this.loggedIn = true;
      if (this.id1 == 'loginJob' || this.id == 'login') {
        this.router.navigateByUrl('/job');
      }
      else {
        this.router.navigateByUrl('/account');
      }
      if (this.loggedIn) {
        if (localStorage.getItem('myProfile')) {
          this.user = localStorage.getItem('myProfile');
          this.user = JSON.parse(this.user);
        }

        this.loginService.getUser().subscribe((data: any) => {
          this.user = data;
          localStorage.setItem('myProfile', JSON.stringify(data));
        });
      }
    }

  }

  handleErrorReg(error: any) {
    this.showReg = true;
    this.errorRegister = error.message;
  }

  handleErrorLogin(error: { message: any; }) {
    this.showLogin = true;
    this.errorLogin = error.message;
  }

}
