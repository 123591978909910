import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { API_ENDPOINTS } from '../app.config';
import { Register } from '../models/register.model';

const CACHE_KEY = 'dp';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends ApiService {
  log: boolean = false;
  image: any;
  constructor(private http: HttpClient) {
    super()
  }


  userLogin(apply: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/login";
    const body = {
      username: apply.email,
      password: apply.password
    }

    return this.http.post(url, body);

  }

  logout() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/logout";
    const headers = this.getHeaders({});
    localStorage.removeItem('myProfile');
    localStorage.removeItem('enquiries');
    return this.http.post(url, {}, { headers: headers });



  }

  provoke() {
    this.log = true;
  }

  getUser(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getUserData";
    const headers = this.getHeaders({});
    return this.http.get<any>(url, { headers: headers }).pipe(
      map(data => data.Data.user)
    );

  }

  getUser2(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getUserData";
    const headers = this.getHeaders({});
    return this.http.get<any>(url, { headers: headers }).pipe(
      map(data => data)
    );

  }

  getUser1() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getUserData";
    const headers = this.getHeaders({});
    this.image = this.http.get<any>(url, { headers: headers })
      .pipe(
        map(data => data.Data)
      );

    this.image.subscibe((next: any) => {
      localStorage[CACHE_KEY] = JSON.stringify(next);
    });


  }

  userRegister(apply: any): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/register";
    const body = {
      name: apply.name,
      phone: apply.phone,
      email: apply.email,
      address: apply.address,
      state: apply.state.id,
      password: apply.password,
      password_confirmation: apply.confirm_password,
      referel_id: apply.refer
    }

    return this.http.post<any>(url, body);

  }

  forgotPassword(email: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/forgotPassword";
    const body = {
      email: email,
    }
    return this.http.post(url, body);
  }

  verifyOtp(email: any, otp: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/verifyOtpForgot";
    const body = {
      email: email,
      otp: otp
    }
    return this.http.post(url, body);
  }

  changePassword(user_id: any, token: any, password: any, password_confirmation: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/changePassword";
    const body = {
      user_id: user_id,
      token: token,
      password: password,
      password_confirmation: password_confirmation
    }
    return this.http.post(url, body);
  }
}
