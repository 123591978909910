import { Component, OnInit } from '@angular/core';
import { SoonService } from '../services/soon.service';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css']
})
export class ExamComponent implements OnInit {

  constructor(private soonService: SoonService) { }

  ngOnInit(): void {
    this.soonService.addMessage("Exams");
  }

}
