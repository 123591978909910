<section class="learnPage-section">
  <div class="container learn-container">
      <h1 class="main-heading">Learn through my zeed</h1>
      <div class="searchWrapper">
          <input type="text" placeholder="Search and learn using my zeed">
          <div class="icon">
              <img src="assets/images/search.png" alt="">
          </div>
      </div>
      <div class="row">
          <div class="col-md-6 col-lg-4">
              <div class="learnWrapper">
                  <a data-fancybox="gallery-5" href="https://www.youtube.com/embed/X7R-q9rsrtU">
                      <div class="imgWrapper">
                          <img src="assets/images/earn.jpg" alt="">
                      </div>
                  </a>
                  <p>Lorem Ipsum is simply dummy text of </p>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="learnWrapper">
                  <a data-fancybox="gallery-5" href="https://www.youtube.com/embed/X7R-q9rsrtU">
                      <div class="imgWrapper">
                          <img src="assets/images/earn.jpg" alt="">
                      </div>
                  </a>
                  <p>Lorem Ipsum is simply dummy text of </p>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="learnWrapper">
                  <a data-fancybox="gallery-5" href="https://www.youtube.com/embed/X7R-q9rsrtU">
                      <div class="imgWrapper">
                          <img src="assets/images/earn.jpg" alt="">
                      </div>
                  </a>
                  <p>Lorem Ipsum is simply dummy text of </p>
              </div>
          </div>
          <div class="col-md-6 col-lg-4">
              <div class="learnWrapper">
                  <a data-fancybox="gallery-5" href="https://www.youtube.com/embed/X7R-q9rsrtU">
                      <div class="imgWrapper">
                          <img src="assets/images/earn.jpg" alt="">
                      </div>
                  </a>
                  <p>Lorem Ipsum is simply dummy text of </p>
              </div>
          </div>
      </div>

      <div class="pagination-wrapper">
          <ul class="pagination ">
              <li class="page-item active" aria-current="page">
                  <span class="page-link">
                      1
                      <span class="sr-only">(current)</span>
                  </span>
              </li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
          </ul>
      </div>
  </div>
</section>
