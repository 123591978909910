import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private http: HttpClient) {

  }

  getBlog() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getBlogs";
    return this.http.get(url);
  }

  getBlogbyId(id: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getBlogDetail";
    return this.http.get(url + '/' + id);
  }


}
