import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss']
})
export class VideoSectionComponent implements OnInit {


  id: any;
  collegeVideos: any;
  studentVideos: any;
  collegeImage: any;
  collegeStudentImage: any;

  constructor(private route: ActivatedRoute, private mediaService: MediaService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });


    this.mediaService.getCollegeVideos(this.id).subscribe((data: any) => {
      this.collegeVideos = data.Data;
      this.collegeImage = this.collegeVideos.length;
    });

    this.mediaService.getStudentVideos(this.id).subscribe((data: any) => {
      this.studentVideos = data.Data;
      this.collegeStudentImage = this.studentVideos.length;

    });

  }

}
