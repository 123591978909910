import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoonService {

  messageData: any;

  constructor() {

  }


  addMessage(data: any) {
    this.messageData = data;
  }
}
