<section class="browseCategory-section">
  <div class="container">
      <div class="heading-wrapper">
          <h1 id="category" data-aos="fade-up"  data-aos-anchor-placement="top-bottom"   class="main-heading text-center">Browse By Category</h1>
          <img class="animation-1" src="assets/images/zeed-1.png" alt="">
      </div>
      <p data-aos="fade-up"  data-aos-anchor-placement="top-bottom"   class="heading-para">Choose your career path by selecting your stream.  Find the top colleges enlisted under each category and explore to plant your future.</p>


<div data-aos="fade-up"  data-aos-anchor-placement="top-bottom">
      <p-carousel [value]="category" styleClass="custom-carousel" [numVisible]="4" [numScroll]="4" [circular]="true" [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">

        <ng-template let-cat pTemplate="item">
          <div class="col-12 " >
            <a [routerLink]="['/findinstitutions' , cat.id ]">
                <div class="browseCategory">
                    <div class="iconWrapper">
                        <img src="{{cat.icon}}" alt="">
                    </div>
                    <h1>{{cat.name|titlecase}}</h1>
                    <!-- <p *ngIf="cat.college_count > 0">{{cat.college_count}} Colleges</p> -->
                </div>
            </a>
        </div>
        </ng-template>
    </p-carousel>
  </div>
  </div>
</section>
