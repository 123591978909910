import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private http: HttpClient) {

  }


  getAllCity(): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllMajorCities";
    return this.http.get<any>(url).pipe(
      map(data => data.Data)
    );

  }

  getAllCollegesCity(id: any): Observable<any> {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCollegesMajorCity";
    return this.http.get<any>(url + '/' + id).pipe(
      map(data => data.Data)
    );

  }
}
