import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})

export class MediaService {

  constructor(private http: HttpClient) {

  }

  getCollegeImages(id: string) {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/getCollegeImages";
    return this.http.get(url + '/' + id);
  }

  getStudentImages(id: string) {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/getStudentImages";
    return this.http.get(url + '/' + id);
  }

  getCollegeVideos(id: string) {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/getCollegeVideos";
    return this.http.get(url + '/' + id);
  }

  getStudentVideos(id: string) {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/getStudentVideos";
    return this.http.get(url + '/' + id);
  }

  getNews() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getNewsAndEvents";
    return this.http.get(url);

  }


}
