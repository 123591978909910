<section class="aboutPage-section">
  <div class="container about-container">
      <h1 class="main-heading">Know <span>Myzeed</span></h1>
      <div class="row">
          <div class="col-lg-6 order-1 order-lg-0">
              <div class="para">
              <p>MyZeed - Plant your future and we'll help it grow, organically. Thus, strengthening your career roots and guiding you to grow in the right direction and attain success in your future career endeavors.
                An extensive search engine for students, parents, and institutions to well- equip oneself with information related to higher education sectors in India and Overseas. MyZeed, has the repository of 20,000 plus colleges and 6000 courses, well categorized in different streams like Management, Engineering, Medical, Arts, and much more.
              </p>
              <h4>Our Mission</h4>
              <p>
                Selecting an institution for career growth is the turning point of one’s life. We aspire to be the one-stop, top education portal to cater every student’s career needs.</p>
              <h4>Our Vision</h4>

                <p>
                  MyZeed, aims to become the light in the dark for students seeking answers to queries related to their future in career.</p>

                  <h4>Students Zone.</h4>
                  <p>Based on your educational background and career interest, one can classify colleges based on the location, ranking, rating, fees, and cut off for different competitive exams. We help you water your growth to reach your destination in five simple steps,</p>
                  <div class="row">
                    <div class="col-lg-6">
                        <ul class="left">
                            <li>• Search for your options.</li>
                            <li>• Select the best Institute.</li>
                           <!--  <li>• Apply for the desired course and receive discounts.</li> -->
                            <li>• Confirm your admission.</li>
                            <li>• And, Earn while you learn.</li>
                        </ul>
                    </div>
                </div>
              </div>

            </div>
            <div class="col-lg-6 order-0 order-lg-1">
                <div class="imgWrapper">
                    <img src="assets/images/seed-img.gif" alt="">
                </div>
            </div>
          </div>
<div>
                    <h4 style="margin-top: 50px;">MyZeed For Institutions.</h4>
                    <p style="text-align: justify;">Are you looking for a non-conventional platform, focusing on delivering quality leads, enabling you to collect highly motivated candidates?
                      Are you looking for a highly specific and active database to cater your requirements? then you have arrived at the right place. Our algorithm on our website just does that. Follow the below mentioned steps to help us guide you. </p>

                      <div class="row">
                        <div class="col-lg-12">
                            <ul class="left">
                                <li>  •	Select the type of Institute.</li>
                                <li> •	Register.</li>
                                <li> •	Provide institution courses, fees, and infrastructure details along with photos.
                                  </li>
                                <li>•	Select the type of Institute.</li>
                                <li>•	Register.</li>
                                <li>•	Confirm.</li>
                            </ul>
                        </div>
                    </div>



                      <h4 style="margin-top: 50px;">Parents Zone.</h4>
                      <p style="text-align: justify;">Are you looking for an ideal institute for your wards? No worries. MyZeed provides a multichannel filter page, to help you sort data on the basis of college ranking, fees, and cutoff.  Having a highly proficient team of data miners, we present to you data in the most interactive way to answer your queries and thus assuring you peace of mind.</p>

</div>

  </div>
</section>
