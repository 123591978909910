import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})

export class JobsService {

  constructor(private http: HttpClient) {

  }

  getJobs() {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/getJobs";
    return this.http.get(url);
  }


  getJobsMobile() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getJobsMobile";
    return this.http.get(url);
  }
}
