<section class="collegeImage-section">
    <div class="container ">

       <!--  <div class="">
            <h1 class="secondaryHeading ">College Images</h1>
            <div class="galleria">
            <p-galleria [value]="images" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '930px'}" [numVisible]="5" [circular]="true"
            [autoPlay]="true" [transitionInterval]="3000" [showThumbnails]="false" [showIndicators]="true">
            <ng-template pTemplate="item" let-image>
                <img [src]="image.image_url" style="width: 100%;height: 450px; display: block;" />
            </ng-template>
            </p-galleria>
          </div>
        </div> -->
       <div *ngIf="collegeImage != 0" class="collegeImagewrapper">
            <h1 class="secondaryHeading ">College Images</h1>
            <!-- <a class="viewAll" href="">View All</a> -->
            <ul class="images">

                <li  *ngFor="let image of images"><a data-fancybox="gallery-1" [href]="image.image_url"><img [src]="image.image_url"></a></li>
            </ul>
        </div>

        <div *ngIf="collegeStudentImage != 0" class="collegeImagewrapper">
          <h1 class="secondaryHeading ">Student Images</h1>
          <!-- <a class="viewAll" href="">View All</a> -->
          <ul  class="images">

              <li  *ngFor="let image of studentImages"><a data-fancybox="gallery-2" [href]="image.image_url"><img [src]="image.image_url"></a></li>
          </ul>
      </div>

      <div style="text-align: center;" *ngIf="collegeImage == 0 && collegeStudentImage == 0">
      <img class="noImg" src="assets/images/nodata.jpg" alt="">

        <p>No Images found</p>
      </div>
    </div>
</section>


