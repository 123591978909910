
<app-scholarship-banner></app-scholarship-banner>

<div class="notificationWrapper top-xamWrapper">
  <div class="container">
      <ul class="scholarship-list">
          <li *ngFor="let scholarship of scholarships">
              <div class="date">
                  <h3 class="text-center">{{scholarship.posted_date | date: 'MMM'}}</h3>
                  <h2 class="text-center">{{scholarship.posted_date | date: 'd'}}</h2>
              </div>
              <div class="detail">
                <!-- <p-tag *ngIf="scholarship.day_status == 1" class="new" value="New"></p-tag> -->

                <h3>{{scholarship.title}}</h3>
                  <p>{{scholarship.description}}</p>
                      <div class="date-download">
                          <p><img src="assets/images/calendar.png" alt=""><span>{{scholarship.last_date | date:'dd/MM/YYYY' }}</span></p>
             <!--              <a class="readmore" href="{{scholarship.url}}" target="_blank" rel="noopener" >Know More</a> -->
                      </div>
                      <a data-toggle="modal" data-target="#applyNow" > <button type="button" (click)="passId(scholarship.id)"  class="main-btn scholar"><span class="textScholar">Apply Now</span></button></a>

              </div>
          </li>

      </ul>
  </div>
</div>

<!-- apply now modal -->
<div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
<p-progressSpinner class="spinner" [hidden]="show" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

          <div class="modal-body">
              <button type="button" (click)="onClose()" class="close"  aria-label="Close">
                  <img src="assets/images/close.png" alt="">
              </button>

              <h1 class="text-center">Apply Now</h1>
              <form  [formGroup]="applyForm">
              <div class="formWrapper">
                  <div class="form-group">
                      <img src="assets/images/user-2.png" alt="">
                      <input type="text" formControlName="name"  class="form-control" placeholder="Enter the Name" required>

                      <small class="text-danger" *ngIf="error?.name && submitted">{{error.name}}</small>



                  </div>
                  <div class="form-group">
                      <img src="assets/images/phone-1.png" alt="">
                      <input type="number" formControlName="phone"  class="form-control" placeholder="Phone Number" required>
                      <small class="text-danger" *ngIf="error?.phone && submitted">{{error.phone}}</small>

                  </div>
                  <div class="form-group">
                      <img src="assets/images/mail-1.png" alt="">
                      <input type="email" formControlName="email"  class="form-control" placeholder="Email Address" required>
         <small class="text-danger" *ngIf="error?.email && submitted">{{error.email}}</small>


                  </div>

                  <button class="main-btn" (click)="onSubmit()" type="submit" data-toggle="modal"
                   >Apply</button>


              </div>
              </form>
          </div>
      </div>
  </div>
</div>



<app-footer></app-footer>
<app-scroll-to-top></app-scroll-to-top>
