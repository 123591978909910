<section  class="xplore-everthing-section">
  <div class="container heading-container">
      <h1 class="main-heading text-center">Explore Everything</h1>
      <img class="animation-2" src="assets/images/zeed-1.png" alt="myzeed">
  </div>
  <div class="container">
      <div class="animate__animated animate__heartBeat animate__delay-3s xplor-block-wrapper">
          <div class="row">
              <div class="col-6">
                  <a routerLink="/domestic-institutions">
                      <div class="xplor-block">
                          <img src="assets/images/college.png" alt="">
                          <h2>Domestic Education</h2>

                      </div>
                  </a>
              </div>
              <div class="col-6">
                  <a routerLink="/international-institutions">
                      <div class="xplor-block">
                          <img src="assets/images/international.png" alt="">
                          <h2>International Education</h2>

                      </div>
                  </a>
              </div>
          </div>
      </div>
  </div>
</section>
