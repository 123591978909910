<section class="featured-college-section">
  <div class="container">
      <div class="heading-wrapper">
          <h1 id="featured" data-aos="fade-up"  class="main-heading text-center">Featured Institutions</h1>
          <img class="animation-1" src="assets/images/bean.png" alt="">
      </div>
      <p  data-aos="fade-up"   class="heading-para">If you are not sure where to explore and research the best institutions, you have arrived at the right place. We are here to make sure you find a institutions that meets exactly what you need. </p>



<div data-aos="fade-up">
        <p-carousel [value]="colleges" styleClass="custom-carousel" [numVisible]="3" [numScroll]="3" [autoplayInterval]="5000" [circular]="true" [responsiveOptions]="responsiveOptions">

          <ng-template let-college pTemplate="item ">
            <div class="m-2">
              <div class="college-wrapper">
                <a class="txt" [routerLink]="['/about-institutions' , college.id ]">
                  <div class="img-wrapper">
                      <img src="{{college.cover_pic}}" alt="">
                  </div>

                  <div class="star-rate">
                      <img src="assets/images/star.png" alt="">
                      <span>{{college.rating}}</span>
                  </div>
                  <div class="details">
                      <div class="colege-name">
                          <div class="iconWrapper">
                              <img src="{{college.logo}}" alt="">
                          </div>
                          <div *ngIf="college.state" class="name-location">
                              <h4>{{college.name}}</h4>
                              <h3><img src="assets/images/location.png" alt=""><span>{{college.place}}, {{college.state}}</span></h3>

                          </div>
                          <div *ngIf="!college.state" class="name-location">
                            <h4>{{college.name}}</h4>
                            <h3><img src="assets/images/location.png" alt=""><span>{{college.place}}</span></h3>

                        </div>
                      </div>
                      <ul class="feeList">
                          <li>
                              <h6>₹ {{college.min_fee}}</h6>
                              <p> Minimum Fees</p>

                          </li>
                          <li>
                              <h6>₹ {{college.max_fee}}</h6>
                              <p> Maximum Fees</p>


                          </li>
                      </ul>
                      <!-- <ul class="detail-list">
                          <li><a [routerLink]="['/about-college' , college.id ]">Facilities</a></li>
                          <li><a [routerLink]="['/about-college' , college.id ]">Reviews</a></li>
                          <li><a [routerLink]="['/about-college' , college.id ]">Courses</a></li>
                      </ul> -->
                      <a (click)="view(college)" [routerLink]="['/about-institutions' , college.id ]"><button  class="apply-now"><img src="assets/images/green-tik.png" alt=""><span>Apply
                        Now</span></button></a>
                  </div>
            </a>

              </div>
          </div>
          </ng-template>
      </p-carousel>
    </div>

      <div class="view-all">
          <a routerLink="/institutions">View All</a>
      </div>
  </div>
</section>
