import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { API_ENDPOINTS } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  constructor(private http: HttpClient) {
  }
  getCollegebyId(id: string) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getCollegeAboutDetails";
    return this.http.get(url + '/' + id);
  }
}
