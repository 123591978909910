import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-college-category',
  templateUrl: './college-category.component.html',
  styleUrls: ['./college-category.component.scss']
})
export class CollegeCategoryComponent implements OnInit {

  id: any;
  colleges: any;
  categories: any;
  category: any;
  loading!: boolean;
  name: any;
  show: boolean = false;
  categories1: any;

  constructor(private route: ActivatedRoute, private categoryService: CategoryService) {
    route.params.subscribe(val => {
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
      });

      this.categoryService.getAllCategory().subscribe((data: any) => {
        this.categories = data.Data;
        this.categories1 = data.Data;
      });


      this.categoryService.getAllCollegesCat(this.id).subscribe((data: any) => {
        this.loading = true;
        this.colleges = data.Data;
        this.loading = false;
        this.category = null;
        this.category = this.colleges[0].category;

      });
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.categoryService.getAllCategory().subscribe((data: any) => {
      this.categories = data.Data;
      this.categories1 = data.Data;
    });


    this.categoryService.getAllCollegesCat(this.id).subscribe((data: any) => {
      this.loading = true;
      this.colleges = data.Data;
      this.loading = false;
      this.category = null;
      this.category = this.colleges[0].category;

    });

  }

  change() {
    this.name = "";
    this.category = "";
    this.show = false;
    this.loading = true;
  }

  search() {
    if (this.name == "") {
      this.categories = this.categories1;
      this.show = false;
    }
    else {
      this.show = true;
      this.categories = this.categories1;
      this.categories = this.categories.filter((res: { name: string; }) => {
        return res.name.toLocaleLowerCase().match(this.name.toLocaleLowerCase());
      })
    }
  }

  changeCollege() {
    this.category = "";
  }

}
