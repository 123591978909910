import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';     //accordion and accordion tab
import { ToastrModule } from 'ngx-toastr';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableModule } from 'primeng/table'; // Table
import { DataViewModule } from 'primeng/dataview';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PasswordModule } from 'primeng/password';
import { TagModule } from 'primeng/tag';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './home/banner/banner.component';
import { ExploreComponent } from './home/explore/explore.component';
import { IndiaFastestComponent } from './home/india-fastest/india-fastest.component';
import { LearnEarnComponent } from './home/learn-earn/learn-earn.component';
import { FindJobComponent } from './home/find-job/find-job.component';
import { AchievementsComponent } from './home/achievements/achievements.component';
import { FeaturedCollegeComponent } from './home/featured-college/featured-college.component';
import { TopCourcesComponent } from './home/top-cources/top-cources.component';
import { BrowseCategoryComponent } from './home/browse-category/browse-category.component';
import { TopExamComponent } from './home/top-exam/top-exam.component';
import { AdvertisementComponent } from './home/advertisement/advertisement.component';
import { WhoWeAreComponent } from './home/who-we-are/who-we-are.component';
import { BrowseCityComponent } from './home/browse-city/browse-city.component';
import { TestimonialComponent } from './home/testimonial/testimonial.component';
import { NewsUpdatesComponent } from './home/news-updates/news-updates.component';
import { RequestAppointmentComponent } from './home/request-appointment/request-appointment.component';
import { EducationComponent } from './college/education/education.component';
import { InnerBannerComponent } from './college/inner-banner/inner-banner.component';
import { InnerBannerCourseComponent } from './course/inner-banner-course/inner-banner-course.component';
import { CourseCollegeComponent } from './course/course-college/course-college.component';
import { SidebarComponent } from './course/sidebar/sidebar.component';
import { AccountSidebarComponent } from './account/account-sidebar/account-sidebar.component';
import { AccountBannerComponent } from './account/account-banner/account-banner.component';
import { ReferBannerComponent } from './refer/refer-banner/refer-banner.component';
import { ReferSidebarComponent } from './refer/refer-sidebar/refer-sidebar.component';
import { AppointmentBannerComponent } from './appointment/appointment-banner/appointment-banner.component';
import { PrivacyPolicyComponent } from './appointment/privacy-policy/privacy-policy.component';
import { RequestComponent } from './appointment/request/request.component';
import { JobBannerComponent } from './job/job-banner/job-banner.component';
import { JobSearchComponent } from './job/job-search/job-search.component';
import { LatestJobComponent } from './job/latest-job/latest-job.component';
import { ExamBannerComponent } from './exam/exam-banner/exam-banner.component';
import { TopExamsComponent } from './exam/top-exams/top-exams.component';
import { AboutusBannerComponent } from './footer/aboutus/aboutus-banner/aboutus-banner.component';
import { AboutpageComponent } from './footer/aboutus/aboutpage/aboutpage.component';
import { AboutWhoweAreComponent } from './footer/aboutus/about-whowe-are/about-whowe-are.component';
import { AboutAchievementsComponent } from './footer/aboutus/about-achievements/about-achievements.component';
import { ContactBannerComponent } from './footer/contact/contact-banner/contact-banner.component';
import { ContactpageComponent } from './footer/contact/contactpage/contactpage.component';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LearnBannerComponent } from './home/learn-earn/learn/learn-banner/learn-banner.component';
import { LearnPageComponent } from './home/learn-earn/learn/learn-page/learn-page.component';
import { NewsBannerComponent } from './home/news-updates/news/news-banner/news-banner.component';
import { NewsPageComponent } from './home/news-updates/news/news-page/news-page.component';
import { TestimonialsBannerComponent } from './home/testimonial/testimonials/testimonials-banner/testimonials-banner.component';
import { TestimonialsPageComponent } from './home/testimonial/testimonials/testimonials-page/testimonials-page.component';
import { PrivacyBannerComponent } from './footer/privacy/privacy-banner/privacy-banner.component';
import { PrivacyPageComponent } from './footer/privacy/privacy-page/privacy-page.component';
import { CareerBannerComponent } from './footer/career/career-banner/career-banner.component';
import { CareerPageComponent } from './footer/career/career-page/career-page.component';
import { FaqBannerComponent } from './footer/faq/faq-banner/faq-banner.component';
import { FaqPageComponent } from './footer/faq/faq-page/faq-page.component';
import { TermsBannerComponent } from './footer/terms-condition/terms-banner/terms-banner.component';
import { TermsPageComponent } from './footer/terms-condition/terms-page/terms-page.component';
import { EarnBannerComponent } from './home/learn-earn/earn/earn-banner/earn-banner.component';
import { EarnPageComponent } from './home/learn-earn/earn/earn-page/earn-page.component';
import { FindCollegeCityBannerComponent } from './home/browse-city/find-college-city/find-college-city-banner/find-college-city-banner.component';
import { FindCollegeCityAdmissionComponent } from './home/browse-city/find-college-city/find-college-city-admission/find-college-city-admission.component';
import { AboutCollegeComponent } from './college/about-college/about-college.component';
import { AboutCollegeBannerComponent } from './college/about-college/about-college-banner/about-college-banner.component';
import { CollegeHeaderComponent } from './college/about-college/college-header/college-header.component';
import { CollegeDetailsComponent } from './college/about-college/college-details/college-details.component';
import { CollegeAdmissionComponent } from './college/about-college/admission/college-admission/college-admission.component';
import { AdmissionComponent } from './college/about-college/admission/admission.component';
import { AdmissionHeaderComponent } from './college/about-college/admission/admission-header/admission-header.component';
import { CollegeImagesComponent } from './college/about-college/college-images/college-images.component';
import { ImageHeaderComponent } from './college/about-college/college-images/image-header/image-header.component';
import { ImageSectionComponent } from './college/about-college/college-images/image-section/image-section.component';
import { CollegeVideosComponent } from './college/about-college/college-videos/college-videos.component';
import { VideoHeaderComponent } from './college/about-college/college-videos/video-header/video-header.component';
import { VideoSectionComponent } from './college/about-college/college-videos/video-section/video-section.component';
import { MainHeaderComponent } from './college/about-college/main-header/main-header.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogsHeaderComponent } from './blogs/blogs-header/blogs-header.component';
import { BlogsContentComponent } from './blogs/blogs-content/blogs-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchPipe } from './home/banner/search.pipe';
import { ListboxModule } from 'primeng/listbox';
import { DomesticComponent } from './college/domestic/domestic.component';
import { InternationalComponent } from './college/international/international.component';
import { DomesticBannerComponent } from './college/domestic/domestic-banner/domestic-banner.component';
import { InternationalBannerComponent } from './college/international/international-banner/international-banner.component';
import { BrowseCourseComponent } from './home/top-cources/browse-course/browse-course.component';
import { EducationAdComponent } from './college/education/education-ad/education-ad.component';
import { DomesticAdComponent } from './college/domestic/domestic-ad/domestic-ad.component';
import { InternationalAdComponent } from './college/international/international-ad/international-ad.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ScholarshipComponent } from './scholarship/scholarship.component';
import { ScholarshipBannerComponent } from './scholarship/scholarship-banner/scholarship-banner.component';
import { BlogDetailComponent } from './blogs/blog-detail/blog-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { OfflineComponent } from './offline/offline.component';
import { LoginComponent } from './login/login.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    ExploreComponent,
    IndiaFastestComponent,
    LearnEarnComponent,
    FindJobComponent,
    AchievementsComponent,
    FeaturedCollegeComponent,
    TopCourcesComponent,
    BrowseCategoryComponent,
    TopExamComponent,
    AdvertisementComponent,
    WhoWeAreComponent,
    BrowseCityComponent,
    TestimonialComponent,
    NewsUpdatesComponent,
    RequestAppointmentComponent,
    EducationComponent,
    InnerBannerComponent,
    InnerBannerCourseComponent,
    CourseCollegeComponent,
    SidebarComponent,
    AccountSidebarComponent,
    AccountBannerComponent,
    ReferBannerComponent,
    ReferSidebarComponent,
    AppointmentBannerComponent,
    PrivacyPolicyComponent,
    RequestComponent,
    JobBannerComponent,
    JobSearchComponent,
    LatestJobComponent,
    ExamBannerComponent,
    TopExamsComponent,
    AboutusBannerComponent,
    AboutpageComponent,
    AboutWhoweAreComponent,
    AboutAchievementsComponent,
    ContactBannerComponent,
    ContactpageComponent,
    LearnBannerComponent,
    LearnPageComponent,
    NewsBannerComponent,
    NewsPageComponent,
    TestimonialsBannerComponent,
    TestimonialsPageComponent,
    PrivacyBannerComponent,
    PrivacyPageComponent,
    CareerBannerComponent,
    CareerPageComponent,
    FaqBannerComponent,
    FaqPageComponent,
    TermsBannerComponent,
    TermsPageComponent,
    EarnBannerComponent,
    EarnPageComponent,
    FindCollegeCityBannerComponent,
    FindCollegeCityAdmissionComponent,
    AboutCollegeComponent,
    AboutCollegeBannerComponent,
    CollegeHeaderComponent,
    CollegeDetailsComponent,
    CollegeAdmissionComponent,
    AdmissionComponent,
    AdmissionHeaderComponent,
    CollegeImagesComponent,
    ImageHeaderComponent,
    ImageSectionComponent,
    CollegeVideosComponent,
    VideoHeaderComponent,
    VideoSectionComponent,
    MainHeaderComponent,
    BlogsComponent,
    BlogsHeaderComponent,
    BlogsContentComponent,
    SearchPipe,
    DomesticComponent,
    InternationalComponent,
    DomesticBannerComponent,
    InternationalBannerComponent,
    BrowseCourseComponent,
    EducationAdComponent,
    DomesticAdComponent,
    InternationalAdComponent,
    ScholarshipComponent,
    ScholarshipBannerComponent,
    BlogDetailComponent,
    SplashScreenComponent,
    ScrollToTopComponent,
    OfflineComponent,
    LoginComponent,
    AutofocusDirective,
  ],
  imports: [
    BrowserModule,
    DropdownModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ScrollingModule,
    TableModule,
    ToastModule,
    RadioButtonModule,
    TabViewModule,
    MessagesModule,
    MessageModule,
    PasswordModule,
    AccordionModule,
    InputMaskModule,
    CarouselModule,
    DialogModule,
    MultiSelectModule,
    TagModule,
    GalleriaModule,
    ProgressSpinnerModule,
    ButtonModule,
    CheckboxModule,
    FieldsetModule,
    InputNumberModule,
    DataViewModule,
    TooltipModule,
    ReactiveFormsModule,
    ListboxModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MessageService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    /*  {
       provide: HTTP_INTERCEPTORS,
       useClass: ApiInterceptor,
       multi: true
     } */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
