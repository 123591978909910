<section class="testimonialPage-section">
  <div class="container">
      <div class="testimonial">
          <div class="imgWrapper">
              <img src="assets/images/testimonial-1.png" alt="">
          </div>
          <div class="coment">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived not only five </p>

              <h4 class="name">Emily Jacob</h4>
              <h5 class="designation">Teacher</h5>
          </div>
      </div>
      <div class="testimonial">
          <div class="imgWrapper">
              <img src="assets/images/testimonial-1.png" alt="">
          </div>
          <div class="coment">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived not only five </p>

              <h4 class="name">Emily Jacob</h4>
              <h5 class="designation">Teacher</h5>
          </div>
      </div>
      <div class="testimonial">
          <div class="imgWrapper">
              <img src="assets/images/testimonial-1.png" alt="">
          </div>
          <div class="coment">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived not only five </p>

              <h4 class="name">Emily Jacob</h4>
              <h5 class="designation">Teacher</h5>
          </div>
      </div>
  </div>
</section>
