<section class="achievements-section aboutAchievements-section">

  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <h1 class="main-heading text-left">Our Achievements</h1>
              <p>Together, We Achieve the most</p>
          </div>
          <div class="col-md-6">
              <ul id="counter">
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/1.png" alt="">
                      </div>

                      <div class="counter">
                          <div class="counterWrapper">
                              <!-- <p data-count="100" class="counterNumber">0</p><span>+</span> -->
                              <p>5000</p><span>+</span>

                          </div>
                          <span>Courses We have</span>
                      </div>

                  </li>
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/2.png" alt="">
                      </div>
                      <div class="counter">
                          <div class="counterWrapper">
                        <!--       <p data-count="50" class="counterNumber">0</p><span>+</span> -->
                              <p>1000</p><span>+</span>

                          </div>
                          <span>Colleges</span>
                      </div>

                  </li>
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/3.png" alt="">
                      </div>
                      <div class="counter">
                          <div class="counterWrapper">
                              <!-- <span data-count="100" class="counterNumber">0</span><span>+</span> -->
                              <p>20</p><span>+</span>

                          </div>
                          <span>Countries We have</span>
                      </div>

                  </li>
                  <li>
                      <div class="iconWrapper">
                          <img src="assets/images/4.png" alt="">
                      </div>
                      <div class="counter">
                          <div class="counterWrapper">
                             <!--  <p data-count="10" class="counterNumber">0</p><span>+</span> -->
                              <p>5</p><span>+</span>
                          </div>
                          <span>Years of Experience</span>
                      </div>

                  </li>
              </ul>
          </div>
      </div>
  </div>
</section>
