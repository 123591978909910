<section class="news-update-section">
  <div class="container">
      <div class="heading-wrapper">
          <h1 data-aos="fade-up"  class="main-heading text-center">News & Updates</h1>
          <img src="assets/images/bean.png" alt="">
      </div>
      <div data-aos="fade-up" >
      <p-carousel [value]="news" styleClass="custom-carousel" [numVisible]="3" [numScroll]="3" [autoplayInterval]="5000" [circular]="true" [responsiveOptions]="responsiveOptions">

        <ng-template let-new pTemplate="item ">
          <div class="m-2">
            <div class="college-wrapper">
              <a class="txt" [routerLink]="['/news-updates']">
                <div class="img-wrapper">
                    <!-- <img src="{{college.cover_pic}}" alt=""> -->
                    <img [src]="new.image" alt="">

                </div>


                <div class="details">
                    <div class="colege-name">


                        <div  class="name-location">
                          <h1>{{new.title}}</h1>



                      </div>
                    </div>
                    <p>{{new.description}}
                  </p>

                  <p class="date">{{new.date | date: 'MMM dd, yyyy'}}
                  </p>
                    <!-- <ul class="detail-list">
                        <li><a [routerLink]="['/about-college' , college.id ]">Facilities</a></li>
                        <li><a [routerLink]="['/about-college' , college.id ]">Reviews</a></li>
                        <li><a [routerLink]="['/about-college' , college.id ]">Courses</a></li>
                    </ul> -->

                </div>
          </a>

            </div>
        </div>
        </ng-template>
    </p-carousel>
  </div>


      <!-- <div class="row">
          <div class="col-lg-6">
              <a routerLink="/news-updates">
                  <div class="newsWrapper">
                      <div class="imgWrapper">
                          <img src="assets/images/news-1.png" alt="">
                      </div>
                      <div class="details">
                          <h2>Sep 21, 2020</h2>
                          <h1>TNUSRB SI PMT/ PET Admit Card 2020 </h1>
                          <p>Tamil Nadu Uniformed Service Recruitment
                              Board (TNUSRB) has released TNUSRB SI PMT/ PET
                          </p>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col-lg-6">
              <a routerLink="/news-updates">
                  <div class="newsWrapper">
                      <div class="imgWrapper">
                          <img src="assets/images/news-1.png" alt="">
                      </div>
                      <div class="details">
                          <h2>Sep 21, 2020</h2>
                          <h1>TNUSRB SI PMT/ PET Admit Card 2020 </h1>
                          <p>Tamil Nadu Uniformed Service Recruitment
                              Board (TNUSRB) has released TNUSRB SI PMT/ PET
                          </p>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col-lg-6">
              <a routerLink="/news-updates">
                  <div class="newsWrapper">
                      <div class="imgWrapper">
                          <img src="assets/images/news-1.png" alt="">
                      </div>
                      <div class="details">
                          <h2>Sep 21, 2020</h2>
                          <h1>TNUSRB SI PMT/ PET Admit Card 2020 </h1>
                          <p>Tamil Nadu Uniformed Service Recruitment
                              Board (TNUSRB) has released TNUSRB SI PMT/ PET
                          </p>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col-lg-6">
              <a routerLink="/news-updates">
                  <div class="newsWrapper">
                      <div class="imgWrapper">
                          <img src="assets/images/news-1.png" alt="">
                      </div>
                      <div class="details">
                          <h2>Sep 21, 2020</h2>
                          <h1>TNUSRB SI PMT/ PET Admit Card 2020 </h1>
                          <p>Tamil Nadu Uniformed Service Recruitment
                              Board (TNUSRB) has released TNUSRB SI PMT/ PET
                          </p>
                      </div>
                  </div>
              </a>
          </div>
      </div> -->
  </div>
</section>
