<section class="privacy-policy-section">
  <div class="container" style="text-align: justify;">
      <h1 class="main-heading">Terms of Use and Privacy Policy</h1>
      <p><b>
        Welcome to MY ZEED.COM, a flagship portal operated by MY ZEED.COM Private Limited and / or its subsidiary (IES) and / or affiliate (s) (collectively referred to as the ‘Company’)</b></p>
        <p>The Terms and Conditions for EU region is here</p>
        <h6>1.ACCEPTANCE OF TERMS OF USE</h6>
       <p> These terms of use (Terms) constitute a legally binding agreement between User or you (as defined below) and the Company regarding your use of the MY ZEED.COM
        “User” or “You” means any person who access or avail this site of the Company for the purpose of searching the education related matters and materials.
        By accessing the Site or Service and/or by clicking “I agree”, you agree to be bound by these Terms. You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and are capable of entering, performing and adhering to these Terms and that you agree to be bound by the following terms and conditions. While individuals under the age of 18 may utilize the Service of the site, they shall do so only with the involvement and guidance of their parents and/ or legal guardians. You agree to register prior to uploading any content and /or comment and any other use or services of this site and provide your details including but not limited to complete name, age, email address, residential address, contact number.
        The Company reserves the right, at its discretion, to change, modify, add, or remove portions of these Terms at any time by posting the amended Terms. Please check these Terms periodically for changes. Your continued use of the MY ZEED.COM after the posting of changes constitutes your binding acceptance of such changes. In addition, when using any particular services, you may be subject to any posted guidelines or rules applicable to such services. All such guidelines or rules are hereby incorporated by reference into the Terms.
        If you do not agree with the Terms, please do not use MY ZEED.COM.</p>
        <h6>2. MY ZEED.COM, REGISTRATION AND ACCESS TO USE</h6>
        <p>The MY ZEED.COM provides its members with access to wide spectrum of Educational Institutions all over the World.</p>
        <p>•Registration: To register for the MY ZEED.COM you may be required to open an account by completing the registration process (i.e. by providing us with current, complete and accurate information as prompted by the applicable registration form). You also will choose a password and a user name. You are entirely responsible for maintaining the confidentiality of your password and account. In particular, as a parent or legal guardian, you acknowledge and assume sole responsibility to ensure that content which is meant for mature audiences. (i.e. above the age of  majority) is not accessed by children. Hence, you may not share your log in credentials with your children. You expressly agree to absolve the Company of any responsibility/ liability in this regard.</p>
        <p>•Subscription: Your subscription to the MY ZEED.COM in a particular geographical territory shall be valid for that territory only and shall not automatically entitle you to access your account from a different geographical territory, unless specifically permitted by the Company. The content downloaded during subscription period is only allowed to be accessed from the MY ZEED.COM and is accessible only till subscription is active and in good standing.</p>
      <p>•Access to use: To access the MY ZEED.COM, you will be asked to enter your individual use name and password, as chosen by you during your registration. Therefore, the Company does not permit any of the following :</p>
      <p>•Any other person sharing your account and Password;</p>
      <p>•Any part of the Site being cached in proxy services and accessed by individuals who have not registered with the Company as users of the Site; or</p>
      <p> •Access through a single account and Password being made available to multiple users on a network.</p>
    <p>If the company reasonably believes that an account and password is being used/ misused in any manner, the Company shall reserve the right to cancel access rights immediately without notice, and block access to all users from that IP address. Furthermore, user shall be entirely responsible for any and all activities that occur under your of security. The Company will not be liable for any loss that you may incur as a result of someone else using your password or account, however, you could be held liable for losses incurred by the Company or another party due to someone else using your account or password.</p>
      <p>•Availability: The availability of content through MY ZEED.COM Services will change from time to time. The quality and clarity of the materials streaming may vary from computer to computer, and device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/ or speed of your Internet connection. You are responsible for all internet access charges. Please check with your Internet provider for information on possible Internet data usage charges.
       <p> •Geographical Limitation:
        You can collect materials and informations through the MY ZEED.COM only in geographic location where the Company offers the MY ZEED.COM. The content that may be available will vary by geographic location. The Company may use technologies to verify your geographic location.</p>
      <p>•MY ZEED.COM streaming software:
        The MY ZEED.COM streaming software is developed and designed to enable streaming of content from the MY ZEED.COM through the MY ZEED.COM ready devices. This software may vary by device and medium, and functionalities may also differ between devices. This software is licensed to you pursuant to these Terms and solely for the purpose of using the MY ZEED.COM and for no other purpose whatsoever. The Company does not warrant the performance of the software, including its continuing compatibility with the MY ZEED.COM. Any unauthorized use of the software is strictly prohibited and MY ZEED.COM reserves the right to not provide the software (including updates) to you at any time and to discontinue the ability to access the MY ZEED.COM through such software at any time, without prior or any notice. When you use a MY ZEED.COM ready device, you expressly represent that you have the express authority to use such device and be liable for any transaction conducted through such device on the site.</p>
        <h6>3.BILLING (If Applicable)</h6>
        <p>By Signing up for the MY ZEED.COM, you are expressly agreeing that the Company is authorized to charge you subscription fee as per your selected plan, any other fees for additional services you may purchase, and any applicable taxes in connection with your use of the MY ZEED.COM through the credit card or other payment method accepted by the Company (“Payment Method”) that you provided during registration. If you want to use a different Payment Method that the one you signed up for during registration, or if there is a change in your credit card validity or expiration date, you may edit your Payment Method information by clicking on your account. If your Payment Method expires and you do not edit your Payment Method information or cancel your account, you authorize us to continue billing, and you will remain responsible for any uncollected amounts.
        As used in these Terms, “billing” shall indicate either a charge or debt, as applicable, against your Payment Method. The subscription fee will be billed at the beginning of your subscription and on each subsequent renewal (as per plan chosen by you) thereafter unless and until you cancel your subscription or the account or service is otherwise suspended or discontinued pursuant to these Terms. To see the commencement date for your next renewal period, go to the billing information section on your account page.
        In order to sustain with the MY ZEED.COM, it is important that you honor the payment obligations to which you have agreed. Accordingly, the Company reserves the right to pursue any amounts you fail to pay in connection with the MY ZEED.COM. You will remain liable to the Company for all such amounts and all costs incurred by the Company in connection with the collection of these amounts, including, without limitation, collection agency fees, reasonable Attorneys fees, and arbitration or court costs.
        You also understand and acknowledge that the Company only facilitates the third party payment gateway for processing of payment. This facility is managed by the third party payment gateway provider and you are required to follow all the terms and conditions of such third party payment gateway provider. You are responsible for the accuracy and authenticity of the information provided by you, including the bank account number/ credit card details and the like. You agree and acknowledge that the Company shall not be liable and in no way be held responsible for any losses whatsoever, whether direct, indirect, incidental or consequential, including without limitation any losses due to delay in processing of payment instruction or any credit card fraud.
        You can file any complaint related to payment transfer at Site and the same forwarded to the concerned third party payment gateway provider for redressal.</p>

        <h6>INTELLECTUAL PROPERTY RIGHTS AND LIMITED LICENSE</h6>
      <p>•Limited License: Subject to your compliance with the Terms herein, the Company hereby grants you a personal, limited, non- exclusive, non- transferable, freely revocable license to use the MY ZEED.COM for the personal and non-commercial use of the contents only, Except for the  forgoing limited license, no right, title or interest shall be transferred to you. Content on the Site and /or MY ZEED.COM is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. We reserve all rights not expressly granted in and to the Site and / or MY ZEED.COM and the content. You agree to not engage in the use, copying, or distribution of any of the Content other than expressly permitted herein. Any unauthorized use of the Contents or MY ZEED.COM  will result in termination of the limited license granted by the Company and cancellation of your membership. Use of Site for any other purpose may result in severe civil and criminal penalties. The Company does not promote, foster or condone the copying of Content, or any other infringing activity. Violators will be prosecuted to the maximum extent possible.</p>
      <p>•The content: The content on the MY ZEED.COM includes the details of educational institutions to which the Company has tie ups, details of courses offered, broachers, approximate fee structures, pictures of the institutions, etc are purely the properties of the Company.</p>

       <h6> YOUR OBLIGATIONS</h6>
      <p>You hereby agree and assure the Company that the Site shall be used for lawful purposes only and that you will not violate laws, regulations, ordinance or other such requirements of any applicable Central, State or local government or any other international laws. You further concur that you shall not:</p>
    <p> •Circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in MY ZEED.COM.</p>
  <p> •either directly or through the use of any device, software, internet site, web-based service, or other means copy, download, stream capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the Content unless expressly permitted by the Company in writing;</p>
<p>•either directly or through the use of any device, software, internet site, web-based service, or other means remove, alter, bypass, avoid, interfere with, or circumvent any copy right, trademark, or other proprietary notices marked on the Content or any digital rights management mechanism, device, or other content protection or access control measure associated with the Content including geo-filtering mechanism.</p>
<p>•use of the MY ZEED.COM in any manner that could damage, disable, overburden, or impair and not to undertake any action which is harmful or potentially harmful to any the Company’s server, or the network (s), computer systems / resources connected to any the Company server, or interfere with any other party’s use and enjoyment of the MY ZEED.COM;</p>
<p>•obtain or attempt to obtain any materials or information through any means not intentionally made available through the Site/ Service;</p>
<p>•perform any activity which is likely to cause such harm;</p>
<p>•carry out any “denial of service” (DoS, DDoS) or any other harmful attacks on application or internet service or;</p>
<p>•use the Site or MY ZEED.COM for illegal purposes;</p>
<p>•disrupt, place unreasonable burdens or excessive loads on, interfere with or attempt to make any unauthorized access to any the Company website of any the Company’s customer;</p>
<p>•forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Site or Gaana Service;</p>
<p> •attempt to gain unauthorized access to MY ZEED.COM, other accounts and computer systems through hacking, password mining or any other means. You shall not obtain or attempt to obtain any materials or information through any means not intentionally made available through the site or MY ZEED.COM;</p>
<p>•incorporate the Content into, or stream or retransmit the Content via, any hardware or software application or make it available via frames or in –line links unless expressly permitted by the Company in writing;</p>
<p>•create, recreate, distribute or advertise an index of any significant portion of the Content unless authorized by the Company;</p>
<p>•use or launch any “robots”, “ spiders”, “offline readers” etc.or any other automated system, that access the site and / or MY ZEED.COM in a manner that sends numerous automated requests to the Site’s servers in a given period of time, which a human cannot reasonably send in the same period by using conventional web browsing application or tool (s) for similar purposes.</p>
<p>In addition, you are strictly prohibited from creating derivative works or materials that otherwise are derived from or based, on the Content in any way, including montages, mash-ups and similar videos, wallpaper, desktop themes, greeting cards, and merchandise, unless it is expressly permitted by the Company in writing. This prohibition applies even if you intend to give away the derivative materials free of charge.</p>
<p>The Site may permit you to post user submissions including but not limited to reviews of Content available through the MY ZEED.COM, comments on such Content, etc (User Submissions). You understand that these User Submissions, once posted by you, are visible to all members since it is a public forum.</p>
       <h6> 7. PROHIBITED ACTIVITIES </h6>

        <p>You agree not to host, display, upload, modify, publish, transmit, update or share any information or User Submissions which</p>
        <p>•belongs to another  person and to which you do not have any right to;</p>
      <p>•is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically, objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</p>
    <p>•harm minors in any way;</p>
  <p> •infringes any patent, trademark, copyright or other proprietary rights;</p>
<p>  •violates any law for the time being in force;</p>
<p>    •deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
<p>    •impersonate another person;</p>
<p>    •contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionally of any computer resources;</p>
<p>   •threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation;</p>
<p>    •contain misleading information regarding the origin of the Content;</p>
<p>    •or otherwise contains objectionable content.</p>
<p>    Any content uploaded by you shall be subject to relevant laws and may disables, or and may be subject to investigation under appropriate laws. Furthermore, if you are found to be in non- compliance with the laws and regulations, these terms, or the privacy policy of the Site, we may terminate your account/ block your access to the Site and we reserve the right to remove any non- compliant content uploaded by you.</p>
<p>    If you are found to be in non-compliance with the laws and regulations, these terms, or the privacy policy of the Site, the Company shall have the right to immediately terminate/ block your access and usage of the Site and the Company shall have the right to immediately remove any non-complaint Content and or comment, uploaded by you and shall further have the right to take recourse to such remedies as would be available to the Company under the applicable laws.</p>
<p>    You understand and agree that the Company may, but is not obligated to, review the User Submissions and may delete or remove it (without notice) in its sole and absolute discretion, for any reason or without assigning any reason.</p>
<p>    More specifically, when you review /rate any Content available on the MY ZEED.COM (as per functionally made available on the Site), you give the Company express rights and consent to display your rating/ review in relation to the relevant Content ratings to be shared in a public forum, do not use these features.</p>
<p>    These features may change without notice to you and the degrees of associated information sharing and functionally may also change without notice.</p>
<p>    The company is free to use any comments, information, ideas, concepts, reviews, or techniques or any other material contained in any communication you may send o us (“User Feedback”), including responses to questionnaires or through posting to the MY ZEED.COM / the Site and User Submissions, without further compensation, acknowledgment or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving the MY ZEED.COM. By posting/ submitting any User Feedback/ User Submission on the Site, you grant the Company a perpetual, worldwide, non-exclusive, royalty- free irrevocable, sub-licensable license and right in such User Feedback / User Submission to the Company, including the right to display, use, reproduce or modify the User Feeback/ User Submission in any media, software or technology of any kind now existing or developed in the future. Operators of public search engines have the permission to use functionalities like spiders to copy materials from the Site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials. We reserve the right to revoke these exceptions either generally or in specific cases, in our sole discretion. You agree not to collect or harvest any personally identifiable information, including account names, form the Site, nor to use the communication systems provided by the Site for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Site with respect to its User submissions.</p>
<p>    You shall be financially responsible for your use of the MY ZEED.COM as well as for use of your account by others, including without limitation minors living with you. You undertake to supervise and be responsible for all usage of minors of the MY ZEED.COM and access of the Site under your name or account and absolve the Company from any liability on this account. You also warrant that all information supplied by you or members of your family for using the MY ZEED.COM and accessing the Site, including without limitation your name, email address, street address, telephone number, mobile number, credit card number is correct and accurate. Failure to provide accurate information may subject you to civil and criminal penalties. You shall be responsible for obtaining and maintaining any equipment or ancillary service needed to connect to, access the Site or otherwise use the MY ZEED.COM, including, without limitation, modems, hardware, software, and long distance or local telephone service. You shall be responsible for ensuring that such equipment or ancillary services are compatible with the MY ZEED.COM.</p>
<p>    You agree that the Company may directly or through third party service provides send information to you about the various services offered by the Company from time to time.</p>

<h6>LINKS TO OTHER SITES</h6>
      <p>  The site may contain the links or pointers to other websites but you should not infer or assume that the Company operates, controls, or is otherwise connected with these other websites. When you click on a link within the Site, the Company may not warn you that you have left the Site and are subject to the terms and conditions (including privacy policies) of another website. Please be careful to read the terms of use and privacy policy of any other website before you provide any confidential information or engage in any transactions. You should not rely on these Terms to govern your use of another website.</p>
      <p>  The Company is not responsible for the content or practices of any other website even if it links to the Site and even if the website is operated by a company affiliated or otherwise connected with the Company. You acknowledge and agree that the Company is not responsible or liable to you for any content or other materials hosted and served from any website other than the Site.</p>
        <h6>ADVERSTING MATERIAL</h6>
      <p> Part of the Site or the MY ZEED.COM may contain advertising information or promotion material or other material submitted to the Company by third parties. Responsibility for ensuring that material submitted for inclusion on the Site complies with applicable international and national law is exclusively on the party providing the information/ material. Your correspondence or business dealings with, or participation in promotions of advertisers including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. Before relying on any advertising material, you should independently verify its relevance of your purpose, and should obtain appropriate professional advice. The Company shall not be responsible nor liable for any loss or claim that you may have against an advertiser or any consequential damages arising on account of your relying on the contents of the advertisement.</p>

        <h6>COLLECTION AND USE OF PERSONAL INFORMATION</h6>
       <p> For information about the Company’s policies and practices regarding the collection and use of your personally identifiable information, please read the Privacy Policy as available on the Site. The Privacy Policy is incorporated by reference and made part of the terms. Thus, by agreeing to these terms, you agree that your presence on the site and use of the MY ZEED.COM are governed by the company’s Privacy Policy in effect at the time of your use. The Company reserves the right to disclose any information that is required to be shared, disclosed or made available to any governmental, administrative, regulatory or judicial authority under any law or regulation applicable to the Company. The Company can further disclose your name, street address, city , state, zip code, country, phone number, email as it is its sole discretion believes necessary or appropriate in connection with an investigation of fraud, intellectual property infringement, piracy, or other unlawful activity.</p>

      <h6>  11. DISCLAIMER OF WARANTIES AND LIABILITY </h6>
        <p>You understand and agree that the Company provides the MY ZEED.COM on an ‘as –is’ ‘with all faults’ and ‘as available’ basis. You agree that use of the Site or the MY ZEED.COM is at your risk. All warranties including without limitation, the implied warranties of merchantability, fitness for a particular purpose, for the title and non-infringement are disclaimed and excluded.</p>
      <p>No representations, warranties or guarantees whatsoever are made by the Company as to the (a) accuracy, adequacy, reliability, completeness, suitability for applicability of the information to a particular situation; (b) that the service will be uninterrupted, timely, secure, or error -free ; (c) the quality of any services, contain, information or other material on the website will meet your expectations or requirements ; (d) any errors in the site will be corrected; (e) warranties against infringement of any third party intellectual property or property rights ;  or (f) other warranties relating to performance, non –performance, or other acts or omissions of the Company, its offices, directors, employees, affiliates , agents, licensors, or suppliers.</p>
    <p>The Company does not warrant that any of the software used and or licensed in connection with the MY ZEED.COM Service will be compatible with other third party software or devices nor does it warrant that operation of the MY ZEED.COM and the associated software will not damage or disrupt others software or hardware.</p>
  <p> The Company its, affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, and suppliers (including distributors and content licensors) shall not be liable, at any time for any, direct, indirect, punitive, incidental, special, consequential, damages arising out of or in any way connected with the use of Site or the MY ZEED.COM, whether based in contract, tort, strict liability, or other theory, even if the Company have been advised of the possibility of damages.</p>
<p> In the event any exclusion contained herein be held to be invalid for any reason and the Company or any of its affiliate entities, officers, directors or employees become liable for loss or damage, then, any such liability of the Company or any of its affiliate entities, officers, directors of employees shall be limited to not exceeding subscription charges paid by you in the month preceding the date of your claim for the particular subscription in question chose by you.</p>
       <h6> 12. INDEMNIFICATION</h6>
      <p> You agree to indemnify, defend and hold harmless, the Company, its affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, and suppliers (including distributors and content licensors and against any losses, claims, damages, liabilities, including legal fees and expenses, arising out of:</p>
    <p> •Any claim due to or arising out of your violation of these Terms, including but not limited to a claim arising out of a breach of your representations or warranties made hereunder;</p>
  <p>•Your use or misuse of or access to the Site or the MY ZEED.COM;</p>
<p> •Your violation of any law, regulation or third party right, including without limitation any copyright, property, or privacy right; or</p>
<p> •any claim that you have caused damage to a third party.</p>
<p> The Company reserves the right, at its own expense, to employ separate counsel and assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and you agree to cooperate with the Company’s defense of these claims.</p>
       <h6> 13. TERMINATION OF ACCOUNT, SUSPENSION OR DISCONTINUATION OF MY ZEED.COM </h6>
      <p> The Company reserves the right to change, suspend, or discontinue temporarily or permanently, some or all of the MY ZEED.COM (including the Content and the devices through which the MY ZEED.COM are accessed), with respect to any or all users, at any time without notice. You acknowledge that the Company may do so in its sole discretion. You also agree that the Company will not be liable to you for any modification, suspension, or discontinuance of the MY ZEED.COM, although if you are a MY ZEED.COM paid subscriber and the Company suspends or discontinues the MY ZEED.COM, the Company may, in its sole discretion, provide you with a credit, refund, discount or other form of consideration (for example, the Company may credit additional days of service to you account). However, if the Company terminates your account or suspends or discontinues your access to the MY ZEED.COM due to your violation of these Terms, then you will not be eligible for any such credit, refund, discount or other consideration.</p>
       <h6> 1.4 GENERAL TERMS</h6>
      <p>•Grievance Redressal</p>
    <p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or beach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature of grievance.myzeed.com.
  <p>•Notice of Copyright Infringement:</p>
<p> Our policy is to comply with all Intellectual Property Laws and to act expeditiously upon receiving any notice of claimed infringement. If you believe that any work has been reproduced on this website in a manner that constitutes copyright infringement, please provide a notice of copyright infringement containing all of the following information:
<p>•A physical or electronic signature of a person authorized to act on behalf of the copyright owner for the purpose of the complaint.</p>
<p> •Identification of the copyright work claimed to have been infringed.</p>
<p>•Identification of the material on our website that is claimed to be infringing or to be the subject of infringing activity.</p>
<p> •The address, telephone number or e-mail address of the complaining party.</p>
<p> •A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law.</p>
<p> •A statement, under penalty of perjury, that the information in the notice of copyright infringement is accurate, and that the complaining party is authorized to act on behalf of the owner of the right that is allegedly infringed.</p>
<p> •Relationship</p>
<p>  None of the provisions of the Terms shall be deemed to constitute a partnership or agency between you and the Company and you shall have no authority to bind the Company in any manner, whatsoever. This agreement is solely for you and the Company’s benefit and not for the benefit of any other person, except for permitted successors and</p>
<p> •Assignment</p>
<p> You may not transfer to anyone else, either temporarily or permanently, any rights to use to MY ZEED.COM or any part of the MY ZEED.COM. Any attempt by you to do so is void. The Company may assign, transfer, delegate and/ or grant at or any part of its rights, privileges and properties hereunder to any person or entity.</p>
<p> •Force Majeure</p>
<p>Neither Party shall have any liability for any interruption or delay, to access the Site due to Force Majeure Event. For the purposes of this clause, ‘Force Majeure Event’ means any event or circumstance or combination of events and circumstances which is reasonably beyond the control of the party affected thereby and which causes or results in default or delay in performance by such  affected party of any of its obligations under this agreement and includes an act of God, war, hostilities, civil commotion, strikes, lockouts and other industrial disputes.</p>
<p>•Applicable Law</p>
<p> These Terms are governed by and construed in accordance with, the laws of India without giving effect to principles of conflict of law.</p>
<p>•Limited Time To Bring Your Claim</p>
<p> You and the Company agree that any cause of action arising out of or related to use of the Site Service must commence within one (1) year after the cause of action accrues otherwise, such cause of action will be permanently barred.</p>
<p> •Survival</p>
<p>Rights and obligations under the Terms which by their nature should survive will remain in full effect after termination or expiration of the subscription.</p>
<p> •Non Wavier</p>
<p> Any express waive or failure to exercise promptly any right under this agreement will not create a continuing waiver or any expectation of non- enforcement.</p>
<p> •Entire Agreement</p>
<p>These Terms constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</p>
  </div>
</section>
