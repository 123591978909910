import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})

export class ExamService {

  constructor(private http: HttpClient) {

  }

  getExams() {

    const url = API_ENDPOINTS.baseUrl.url + "api/user/getExams";
    return this.http.get(url);
  }

  getScholarships() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getScholarships";
    return this.http.get(url);
  }
}
