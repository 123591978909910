<section class="college-details">
    <div class="container">
        <h1 class="secondaryHeading">About {{colleges?.college_name}} , {{colleges?.place}}</h1>
        <p>{{colleges?.about}}</p>
       <!--  <table class="table-1">
            <tbody>
                <tr>
                    <td>Courses </td>
                    <td>Eligibility Criteria</td>
                </tr>
                <tr *ngFor="let course of courses" >
                    <td>{{course.course_name}}</td>
                    <td> {{course.eligibility}}</td>
                </tr>

            </tbody>
        </table> -->


        <div class="card">
          <p-table #dt2 [value]="courses"
              [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
              [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} courses"
              [globalFilterFields]="['course_name']">
              <ng-template pTemplate="caption">
                  <div class="p-d-flex ">
                      <span class="p-input-icon-right table-search">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" class="place" (input)="dt2.filterGlobal($event.target.value, 'contains')" placeholder="Search Course" />
                      </span>
                  </div>
              </ng-template>
              <ng-template pTemplate="header">
                  <tr>
                      <th>Courses</th>
                      <th>Eligibility Criteria</th>
                  </tr>

              </ng-template>
              <ng-template pTemplate="body" let-course>
                <tr>
                  <td>{{course.course_name}}</td>
                  <td> {{course.eligibility}}</td>
              </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td colspan="5">Oops! No Course found.</td>
                  </tr>
              </ng-template>
          </p-table>
      </div>







        <h1 *ngIf="facilities != ''" class="secondaryHeading facilitiesHeading">Facilities</h1>
        <ul *ngIf="facilities != ''" class="facilitiesList">
            <li *ngFor="let facility of facilities">
                <div class="icon">
                    <img src="{{facility.icon}}" alt="">
                </div>
                <p>{{facility.title}}</p>
            </li>
        </ul>
        <h1 class="secondaryHeading contactHeading">Contact Details and Location</h1>
        <div class="table-wrapper">
            <div class="table-row">
                <h5>Address :</h5>
                <h6 *ngIf="colleges?.state && colleges?.country"><span>{{colleges?.place}}, {{colleges?.state}} - {{colleges?.country}}</span></h6>
                <h6 *ngIf="!colleges?.country"><span>{{colleges?.place}}, {{colleges?.state}}</span></h6>
                <h6 *ngIf="!colleges?.state"><span>{{colleges?.place}}- {{colleges?.country}}</span></h6>
                <h6 *ngIf="!colleges?.state && !colleges?.country"><span>{{colleges?.place}}</span></h6>
            </div>
           <!--  <div class="table-row">
              <h5>Contact :</h5>
              <h6><span>{{colleges?.number}}</span></h6>
            </div> -->
        </div>
        <div class="view-all">
            <a href="" class="applyNow" data-toggle="modal" data-target="#applyNow">Apply Now</a>
        </div>
    </div>
</section>


<!-- apply now modal -->
<div class="modal fade" id="applyNow" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
<p-progressSpinner class="spinner" [hidden]="show" [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

          <div class="modal-body">
              <button type="button" (click)="onClose()" class="close"  aria-label="Close">
                  <img src="assets/images/close.png" alt="">
              </button>

              <h1 class="text-center">Apply Now</h1>
              <form  [formGroup]="applyForm">
              <div class="formWrapper">
                  <div class="form-group">
                      <img src="assets/images/user-2.png" alt="">
                      <input type="text" formControlName="name"  class="form-control" placeholder="Enter the Name" required>

                      <small class="text-danger" *ngIf="error?.name && submitted">{{error.name}}</small>



                  </div>
                  <div class="form-group">
                      <img src="assets/images/phone-1.png" alt="">
                      <input type="number" formControlName="phone"  class="form-control" placeholder="Phone Number" required>
                      <small class="text-danger" *ngIf="error?.phone && submitted">{{error.phone}}</small>

                  </div>
                  <div class="form-group">
                      <img src="assets/images/mail-1.png" alt="">
                      <input type="email" formControlName="email"  class="form-control" placeholder="Email Address" required>
         <small class="text-danger" *ngIf="error?.email && submitted">{{error.email}}</small>


                  </div>
                  <div class="form-group">

                      <p-dropdown
                      formControlName="stream"
                      [options]="newCategory"
                      placeholder="Select a Category"
                      optionLabel="category_name" [filter]="true" filterBy="category_name"
                      [style]="{ width: '70%' }"
              [autofocusFilter]="false"

                      required

                    ></p-dropdown>
                    <br>

         <small class="text-danger" *ngIf="error?.stream && submitted">{{error.stream}}</small>


                  </div>


                  <div class="form-group">

                  <p-dropdown
                  formControlName="program"
                  [options]="courseApply"
                  placeholder="Select a Course"
                  optionLabel="course_name" [filter]="true" filterBy="course_name"
                  [style]="{ width: '70%' }"
              [autofocusFilter]="false"

                  required

                ></p-dropdown>
                    <br>
         <small class="text-danger" *ngIf="error?.program && submitted">{{error.program}}</small>


                  </div>
                  <button class="main-btn" (click)="onSubmit()" type="submit" data-toggle="modal"
                   >Apply</button>


              </div>
              </form>
          </div>
      </div>
  </div>
</div>
