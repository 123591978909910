import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-international-banner',
  templateUrl: './international-banner.component.html',
  styleUrls: ['./international-banner.component.scss']
})
export class InternationalBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
