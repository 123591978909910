import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/services/media.service';

@Component({
  selector: 'app-news-updates',
  templateUrl: './news-updates.component.html',
  styleUrls: ['./news-updates.component.scss']
})
export class NewsUpdatesComponent implements OnInit {

  news: any;
  responsiveOptions: any;
  constructor(private mediaService: MediaService) {

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '576px',
        numVisible: 2,
        numScroll: 2
      }
    ];
  }

  ngOnInit(): void {

    if (localStorage.getItem('newsHome')) {
      this.news = localStorage.getItem('newsHome');
      this.news = JSON.parse(this.news);
    }


    this.mediaService.getNews().subscribe((data: any) => {
      this.news = data.Data;
      localStorage.setItem('newsHome', JSON.stringify(data.Data));

    });
  }

}
