
<app-account-banner></app-account-banner>
<div class="accountPage">
  <div class="container accoun-container">
      <app-account-sidebar></app-account-sidebar>
      <div class="main-content">
          <h1>Account</h1>
          <div class="sideBar-menu">
              <span></span>
                  <span></span>
                  <span ></span>
          </div>
          <button  type="submit" (click)="edit()" class="main-btn save-btn">Save</button>
          <form  [formGroup]="editForm">

          <div class="acc-box">
              <div class="acc-list">
                  <div class="label-row">
                      <div class="imgWrapper">
                          <img src="assets/images/user-2.png" alt="">
                      </div>

                      <span>Name</span>
                  </div>
                  <div class="form-group">

                  <input type="text" [value]="editUser?.name" class="form-control" id="name" formControlName="name" required>
                  </div>
              </div>
              <div class="acc-list">
                  <div class="label-row">
                      <div class="imgWrapper">
                          <img src="assets/images/adrs.png" alt="">
                      </div>

                      <span>Address</span>
                  </div>
                  <input [value]="editUser?.address" type="text" class="form-control" id="address" placeholder="Enter your Address" formControlName="address">
              </div>
              <div class="acc-list">
                  <div class="label-row">
                      <div class="imgWrapper">
                          <img src="assets/images/adrs.png" alt="">
                      </div>

                      <span>E-mail</span>
                  </div>
                  <input disabled [value]="editUser?.email" class="email" type="mail">
              </div>
              <div class="acc-list">
                  <div class="label-row">
                      <div class="imgWrapper">
                          <img src="assets/images/adrs.png" alt="">
                      </div>

                      <span>Phone</span>
                  </div>
                  <input [value]="editUser?.mobile" type="text" class="form-control" id="mobile" formControlName="mobile">
              </div>
              <div class="acc-list">
                  <div class="label-row">
                      <div class="imgWrapper">
                          <img src="assets/images/adrs.png" alt="">
                      </div>

                      <span>City</span>
                  </div>
                  <input [value]="editUser?.city" type="text" class="form-control" id="city" placeholder="Enter your City" formControlName="city">
              </div>
             <!--  <div class="acc-list">
                  <div class="label-row">
                      <div class="imgWrapper">
                          <img src="assets/images/adrs.png" alt="">
                      </div>

                      <span>State</span>
                  </div>
                  <p-dropdown

                  class="dropdown"
                  [options]="states"
                  name="selectedState"
                  [(ngModel)]="applicant.id"
                  placeholder="Select a State"
                  optionLabel="name" [filter]="true" filterBy="name"
                  [style]="{ width: '272px' }"
                  resetFilterOnHide="true"
                  id="state"
                  formControlName="state"


                ></p-dropdown>
              </div> -->

             <!--  <div class="acc-list">
                <div class="label-row">
                    <div class="imgWrapper">
                        <img src="assets/images/adrs.png" alt="">
                    </div>

                    <span>Country</span>
                </div>
                <input [value]="editUser?.country" type="text" class="form-control" id="country" formControlName="country">
            </div> -->



          </div>
          </form>
      </div>
  </div>

</div>


<app-footer></app-footer>
