import { Component, OnInit } from '@angular/core';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-top-exams',
  templateUrl: './top-exams.component.html',
  styleUrls: ['./top-exams.component.scss']
})
export class TopExamsComponent implements OnInit {

  exams: any;
  constructor(private examService: ExamService) { }

  ngOnInit(): void {

    if (localStorage.getItem('exams')) {
      this.exams = localStorage.getItem('exams');
      this.exams = JSON.parse(this.exams);
    }

    this.examService.getExams().subscribe((data: any) => {
      this.exams = data.Data;
      localStorage.setItem('exams', JSON.stringify(data.Data));
    });
  }
}
