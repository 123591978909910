import { Component, OnInit } from '@angular/core';
import { SoonService } from '../services/soon.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {

  constructor(private soonService: SoonService) { }

  ngOnInit(): void {
    this.soonService.addMessage("Jobs");

  }

}
