import { Component, OnInit } from '@angular/core';
import { AboutService } from 'src/app/services/about.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-college-header',
  templateUrl: './college-header.component.html',
  styleUrls: ['./college-header.component.scss']
})
export class CollegeHeaderComponent implements OnInit {
  colleges: any;
  mode: any;
  id: any;


  constructor( private aboutService: AboutService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.aboutService.getCollegebyId(this.id).subscribe((data: any) => {
      this.colleges = data.data;
    });
  }




}
