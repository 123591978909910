import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '../models/category.model';
import { API_ENDPOINTS } from '../app.config';


@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {

  }

  getCategory() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/dashboard";
    return this.http.get(url);
  }

  getAllCategory() {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCategories";
    return this.http.get(url);

  }

  getAllCollegesCat(id: any) {
    const url = API_ENDPOINTS.baseUrl.url + "api/user/getAllCollegesCategory";
    return this.http.get(url + '/' + id);

  }
}
