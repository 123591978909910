<section class="college-detaile-header">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/institutions">Colleges</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{colleges?.college_name}}</li>
            </ol>
        </nav>
        <div class="header-box">
            <div class="colege-name">
                <div class="iconWrapper">
                    <img src="{{colleges?.logo}}" alt="">
                </div>
                <div class="name-location">
                    <h4>{{colleges?.college_name}}</h4>
                    <h3 *ngIf="colleges?.state && colleges?.country"><span class="loc">{{colleges?.place}}, {{colleges?.state}} - {{colleges?.country}}</span></h3>
                    <h3 *ngIf="!colleges?.country"><span class="loc">{{colleges?.place}}, {{colleges?.state}}</span></h3>
                    <h3 *ngIf="!colleges?.state"><span class="loc">{{colleges?.place}}- {{colleges?.country}}</span></h3>
                    <h3 *ngIf="!colleges?.state && !colleges?.country"><span class="loc">{{colleges?.place}}</span></h3>
                    <div class="approved-download">
                        <div class="left">
                            <p class="approved"><b>Approved By:</b> {{colleges?.affliations}} </p>

                        </div>
                        <div *ngIf=" colleges?.article_link " class="right">
                            <a href="{{colleges?.article_link}}" target="_blank" rel="noopener" download><img src="assets/images/download.png" alt=""><span>Download Article</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="star-rate">
                <img src="assets/images/star.png" alt="">
                <span>{{colleges?.rating}}</span>
            </div>
        </div>

    </div>
</section>


