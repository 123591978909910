import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class ApiService {
  constructor() { }

  public getHeaders(params: any): HttpHeaders {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('MyzeedToken')}`,
    });
    return headers;
  }

  public getHeadersMedia(params: any): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('MyzeedToken')}`,
    });
    return headers;
  }
  /*
  public post(apiName: string, body?: any): Observable<any> {
    const headers = this.getHeaders({});
    return this.http.post(apiName, body, { headers: headers }).pipe(
      map((res: any) => {
        if (res.data) {
          return res.data;
        } else {
          return {};
        }
      }),
    );
  }

  public postAsPromise(apiName: string, body?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.post(apiName, body)
        .toPromise()
        .then(
          (response) => resolve(response),
          (error: HttpErrorResponse) => reject(error.error),
        )
        .catch((error) => reject(error.error));
    });
  }

  public get(apiName: string, responseType?: string): Observable<any> {
    const headers = this.getHeaders({});
    return this.http.get(apiName, { headers: headers }).pipe(
      map((res: any) => {
        if (responseType === 'blob') {
          return res;
        } else if (res) {
          return res.data || {};
        } else {
          return {};
        }
      }),
    );
  }
  */
}
